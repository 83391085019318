import React from "react";
import "../Dashboard.css";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PendingIcon from "@mui/icons-material/Pending";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import useSupabase from "../utils/useSupabase";
import { TableName } from "../constant";

const VendorDashboard = () => {
  const supabase = useSupabase();
  const [orders, setOrders] = useState([]);
  const [popular, setPopular] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [pending, setPending] = useState(0);
  const [delivered, setDelivered] = useState(0);

  const vendorid = localStorage.getItem("ids");

  useEffect(() => {
    fetchOrdersForVendor();
    fetchPopular();
  }, []);

  const fetchOrdersForVendor = async () => {
    try {
      let { data } = await supabase.rpc("list_order", {
        pagenumber: null,
        pagesize: 10,
        status: null,
        vendorid: vendorid,
      });

      setOrders(data);
      setOrderCount(data[0].totalcount);
      let { data: pendingdata } = await supabase.rpc("list_order", {
        pagenumber: null,
        pagesize: 1,
        status: ["Pending"],
        vendorid: vendorid,
      });
      if (pendingdata.length !== 0) {
        setPending(pendingdata[0].totalcount);
      }

      let { data: delivereddata } = await supabase.rpc("list_order", {
        pagenumber: null,
        pagesize: 1,
        status: ["Delivered"],
        vendorid: vendorid,
      });
      if (delivereddata.length !== 0) {
        setDelivered(delivereddata[0].totalcount);
      }
    } catch (error) {
      console.error("Error fetching orders for vendor:", error.message);
    }
  };

  const fetchPopular = async () => {
    try {
      const { data } = await supabase
        .from(TableName.PRODUCTADD)
        .select("*")
        .order("isPopular", { ascending: false })
        .eq("vendorId", vendorid)
        .eq("isActive", "TRUE")
        .limit(5);

      setPopular(data);
    } catch (error) {
      console.error("Error fetching popular products:", error.message);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <Card sx={{ width: 300, height: 150 }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "40px", color: "#851841", margin: 0 }}>
                  {orderCount}
                </p>
                <LocalMallIcon style={{ color: "#851841" }} />
              </div>
              <div style={{ marginTop: "30px" }}>
                <p style={{ margin: 0, fontSize: "20px" }}>
                  <b>Total Orders</b>
                </p>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ width: 300, height: 150 }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "40px", color: "#851841", margin: 0 }}>
                  {delivered}
                </p>
                <LocalShippingIcon style={{ color: "#851841" }} />
              </div>
              <div style={{ marginTop: "30px" }}>
                <p style={{ margin: 0, fontSize: "20px" }}>
                  <b>Delivered Orders</b>
                </p>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ width: 300, height: 150 }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "40px", color: "#851841", margin: 0 }}>
                  {pending}
                </p>
                <PendingIcon style={{ color: "#851841" }} />
              </div>
              <div style={{ marginTop: "30px" }}>
                <p style={{ margin: 0, fontSize: "20px" }}>
                  <b>Pending Orders</b>
                </p>
              </div>
            </div>
          </Card>
        </Grid>

        <>
          <Grid item xs={6}>
            <Card>
              <div style={{ padding: "20px", minHeight: "450px" }}>
                <b>
                  <p style={{ color: "#808080", fontSize: "20px" }}>
                    Top 5 Selling Products
                  </p>
                </b>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#808080",
                  }}
                >
                  <b>
                    {" "}
                    <p
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      Products
                    </p>
                  </b>
                  <b>
                    <p
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Categories
                    </p>
                  </b>
                  <b>
                    <p
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      Ratings
                    </p>
                  </b>
                </div>
                {popular.map((popularproduct, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      color: "#808080",
                    }}
                  >
                    <img
                      alt=""
                      src={JSON.parse(popularproduct.productImage)[0]}
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "10px",
                        marginTop: "20px",
                      }}
                    />
                    <p style={{ width: "40%", color: "#808080" }}>
                      {popularproduct.productName}{" "}
                      <p
                        style={{
                          color: "#808080",
                        }}
                      >
                        {popularproduct.vendorName}
                      </p>
                      <p> {popularproduct.isPopular} Purchase</p>
                    </p>
                    <p
                      style={{
                        width: "40%",
                        display: "flex",
                        justifyContent: "start",
                        marginLeft: "30px",
                      }}
                    >
                      <b> {popularproduct.categoryCode}</b>
                    </p>
                    <p
                      style={{
                        width: "40%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {popularproduct.totalRating &&
                      popularproduct.ratingCount ? (
                        <div
                          style={{
                            flex: "1",
                            textAlign: "end",
                            display: "flex",
                            justifyContent: "end",
                            width: "20%",
                          }}
                        >
                          <p className="flex gap-1 bg-tertiary px-2 py-0.5 text-sm rounded-[50px] text-white">
                            {Number(
                              popularproduct.totalRating /
                                popularproduct.ratingCount
                            ).toFixed(1)}
                          </p>
                          <p className=" text-gray-500 ">
                            ({popularproduct.ratingCount})
                          </p>
                        </div>
                      ) : (
                        <p className="pt-8">No ratings yet</p>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card>
              <div style={{ padding: "20px" }}>
                <b>
                  <p style={{ color: "#808080", fontSize: "20px" }}>
                    Last 10 Orders
                  </p>
                </b>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#808080",
                  }}
                >
                  <b>
                    <p>Products</p>
                  </b>
                  <b>
                    <p
                      style={{
                        width: "60%",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "40px",
                      }}
                    >
                      Status
                    </p>
                  </b>
                  <b>
                    <p>Total amount</p>
                  </b>
                </div>

                {orders &&
                  orders.map((order, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#808080",
                      }}
                    >
                      <img
                        alt=""
                        src={JSON.parse(order.orderdetails.productImage)[0]}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "10px",
                          marginTop: "20px",
                        }}
                      />

                      <p style={{ width: "25%", color: "#808080" }}>
                        {order.orderdetails && order.orderdetails.productName}
                      </p>
                      <p
                        style={{
                          width: "40%",
                          color: "#808080",
                          marginLeft: "40px",
                        }}
                      >
                        <b>{order.orderstatus}</b>
                      </p>

                      <p>{order.orderamount}</p>
                    </div>
                  ))}
              </div>
            </Card>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default VendorDashboard;
