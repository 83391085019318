const url = "https://live-mt-server.wati.io/306250/api/v1/sendTemplateMessages";
const accesstoken =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZmUyZDgxNi03Njg3LTRkZGMtODViNi0xZjRmNGExODY2MmUiLCJ1bmlxdWVfbmFtZSI6ImNlb0B1c2dsb2JhbHNvbHV0aW9ucy5jb20iLCJuYW1laWQiOiJjZW9AdXNnbG9iYWxzb2x1dGlvbnMuY29tIiwiZW1haWwiOiJjZW9AdXNnbG9iYWxzb2x1dGlvbnMuY29tIiwiYXV0aF90aW1lIjoiMDMvMDEvMjAyNCAxMTowMjoxNyIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDYyNTAiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.zNjnmYsWi4W9Qy9hEoIVrZFGDY6HFvOG0m_hJcXvON0";
export async function sendTemplateMessages(req, res) {
  const options = {
    method: "POST",
    headers: {
      "content-type": "text/json",
      Authorization: accesstoken,
    },
    body: JSON.stringify({
      broadcast_name: "Untitled_020320241324",
      template_name: req.template_name,
      receivers: req.receivers,
    }),
  };

  fetch(url, options)
    .then((res) => res.json())
    .catch((err) => console.error("error:" + err));
}
