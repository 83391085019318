import React from "react";
import Layout from "./Layout";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "./constant";
import { useContext } from "react";
import { Cartcontext } from "./Products/Context";
const PrivateRoute = ({ element: Component }) => {
  const GlobalState = useContext(Cartcontext);
  const getLoggedInUser = GlobalState.getLoggedInUser;
  const isLoggedIn = getLoggedInUser();
  return isLoggedIn ? (
    <Layout>
      <Component />
    </Layout>
  ) : (
    <Navigate replace to={AppRoutes.HOME} />
  );
};

export default PrivateRoute;
