import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CustomTextField from "../../CustomTextField";
import { Link } from "react-router-dom";
import { AppRoutes, TableName } from "../../constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSupabase from "../../utils/useSupabase";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { Cartcontext } from "../../Products/Context";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "../Discount/EditPage.css";
import VendorSubHeader from "../../VendorSubHeader";
const EditDiscount = () => {
  const [getCategory, setGetCategory] = useState([]);
  const [getSubCategory, setGetSubCategory] = useState([]);
  const [getBrand, setGetBrand] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const params = useParams();
  const supabase = useSupabase();
  const navigate = useNavigate();
  const GlobalState = useContext(Cartcontext);

  const showToast = GlobalState.showToast;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^\d]+$/, "Name cannot contain numbers"),
    code: Yup.string().required("Code is required"),
    type: Yup.string().required("Discount Type is required"),
    amount: Yup.string().required("Discount Percentage is required"),
    start: Yup.date()
      .required("Start Date is required")
      .test(
        "isStartDateValid",
        "Start Date cannot be in the past",
        (value) => !isDateExpired(value, formik.values.end)
      ),
    end: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("start"), "End Date must be after Start Date")
      .test(
        "isEndDateValid",
        "End Date cannot be in the past",
        (value) => !isDateExpired(formik.values.start, value)
      ),
  });
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    formik.setFieldValue("start", date);
  };
  const isDateExpired = (startDate, endDate) => {
    const currentDate = new Date();
    const startOfCurrentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0,
      0
    );
    return startDate < startOfCurrentDate || endDate < startOfCurrentDate;
  };

  // Update selected end date when changed
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    formik.setFieldValue("end", date);
  };
  useEffect(
    () => {
      const discountData = async () => {
        const discountId = params.id;
        const { data, error } = await supabase
          .from(TableName.DISCOUNT)
          .select()
          .eq("id", discountId);

        if (error) {
          console.error("Error fetching discount data:", error.message);
        } else if (data && data.length > 0) {
          console.log("Fetched Discount Data:", data[0]);
          const discount = data[0];
          formik.setValues({
            name: discount.name,
            code: discount.code,
            type: discount.discount_type,
            amount: discount.amount,
            start: discount.start_date,
            end: discount.end_date,
            category: discount.category,
            sub_category: discount.subcategory,
            brand: discount.brand,
            isActive: discount.isActive,
          });

          // Check if start date or end date is in the past
          if (isDateExpired(discount.start_date, discount.end_date)) {
            showToast("error", "Start or end date cannot be in the past");
          }
        }
      };

      if (params.id) {
        discountData();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      type: "",
      amount: "",
      start: "",
      end: "",
      category: "",
      sub_category: "",
      brand: "",
      isActive: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setTouched({
        name: true,
        code: true,
        type: true,
        amount: true,
        start: true,
        end: true,
      });

      if (formik.isValid) {
        const formattedStartDate = dayjs(values.start).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(values.end).format("YYYY-MM-DD");
        const vendorId = localStorage.getItem("ids") || 0;
        if (params.id) {
          const { data, error } = await supabase
            .from(TableName.DISCOUNT)
            .update([
              {
                name: values.name,
                code: values.code,
                discount_type: values.type,
                amount: values.amount,
                start_date: formattedStartDate,
                end_date: formattedEndDate,
                category: values.category,
                subcategory: values.sub_category,
                brand: values.brand,
                isActive: values.isActive,
                vendor_id: vendorId,
              },
            ])
            .eq("id", params.id);
          if (!error) {
            console.log("Discount data update", data);

            navigate(AppRoutes.DISCOUNT);
            showToast("update", "Discount");
          }
        } else {
          try {
            const { data, error } = await supabase
              .from(TableName.DISCOUNT)
              .insert([
                {
                  name: values.name,
                  code: values.code,
                  discount_type: values.type,
                  amount: values.amount,
                  start_date: formattedStartDate,
                  end_date: formattedEndDate,
                  category: values.category,
                  subcategory: values.sub_category,
                  brand: values.brand,
                  isActive: values.isActive,
                  vendor_id: vendorId,
                },
              ]);

            if (error) {
              console.error("Error inserting data:", error);
            } else {
              console.log("Data inserted successfully:", data);
              navigate(AppRoutes.DISCOUNT);
              showToast("success", "Discount");
            }
          } catch (error) {
            console.error("Error inserting data:", error.message);
          }
        }
      }
    },
  });
  const getBrandData = async () => {
    const { data } = await supabase
      .from(TableName.BRAND)
      .select()
      .eq("isActive", true);
    setGetBrand(data);
  };
  const getCategoryData = async () => {
    const { data } = await supabase
      .from(TableName.CATEGORY)
      .select()
      .eq("isActive", true);
    setGetCategory(data);
  };

  const getSubCategoryData = async () => {
    const { data } = await supabase
      .from(TableName.SUBCATEGORY)
      .select()
      .eq("isActive", true);
    setGetSubCategory(data);
    console.log(data);
  };
  useEffect(
    () => {
      getBrandData();
      getCategoryData();
      getSubCategoryData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div>
      <VendorSubHeader
        title={params.id ? "Edit a Discount" : "Add a new Discount"}
        subtitle="Discount"
        back="Discount"
        linkProps={{
          to: "/discount",
        }}
      />
      <div style={{ padding: "27px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Discount Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide discount basic details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="name"
                      label="Name*"
                      onChange={(e) => {
                        if (!/\d/.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      value={formik.values.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                          mb: "12px",
                        }}
                      >
                        {formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="code"
                      label="Code*"
                      onChange={formik.handleChange}
                      value={formik.values.code}
                      error={formik.touched.code && Boolean(formik.errors.code)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.code && formik.errors.code && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.code}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Discount Category
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Choose the category and subcategory most suitable for the
                    discount
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel sx={{ mt: "-5px" }}>
                        Select Category
                      </InputLabel>
                      <Select
                        name="category"
                        label="Select Category"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        sx={{
                          width: "80%",
                          height: "40px",
                        }}
                      >
                        {getCategory.map((item) => (
                          <MenuItem key={item.id} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel sx={{ mt: "-5px" }}>
                        Select SubCategory
                      </InputLabel>
                      <Select
                        name="sub_category"
                        value={formik.values.sub_category}
                        onChange={formik.handleChange}
                        label="Select SubCategory"
                        disabled={!formik.values.category}
                        sx={{
                          width: "80%",
                          height: "40px",
                        }}
                      >
                        {getSubCategory
                          .filter(
                            (item) => item.category === formik.values.category
                          )
                          .map((item) => (
                            <MenuItem key={item.id} value={item.code}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel sx={{ mt: "-5px" }}>Select Brand</InputLabel>
                      <Select
                        name="brand"
                        label="Select Brand"
                        value={formik.values.brand}
                        onChange={formik.handleChange}
                        sx={{
                          width: "80%",
                          height: "40px",
                        }}
                      >
                        {getBrand.map((item) => (
                          <MenuItem key={item.id} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Discount Type
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Choose the type and amount of your discount
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{ mt: "-5px" }}
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                      >
                        Discount Type*
                      </InputLabel>
                      <Select
                        name="type"
                        label="Discount Type*"
                        onChange={formik.handleChange}
                        value={formik.values.type}
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                        onBlur={formik.handleBlur}
                        sx={{
                          width: "80%",
                          height: "40px",
                        }}
                      >
                        <MenuItem value={"percentage"}>Percentage</MenuItem>
                      </Select>
                      {formik.touched.type && formik.errors.type && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-1px",
                          }}
                        >
                          {formik.errors.type}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="amount"
                      label="Discount Percentage*"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          (formik.values.type === "flat" && value <= 999) ||
                          (formik.values.type === "percentage" && value <= 100)
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      value={formik.values.amount}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.amount && formik.errors.amount && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.amount}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Discount Date
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Select your start date to end date on your discount
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      size="small"
                    >
                      <DatePicker
                        name="start"
                        className="customDatePicker"
                        error={
                          formik.touched.start && Boolean(formik.errors.start)
                        }
                        //fullWidth
                        label="Select Start Date *"
                        value={
                          formik.values.start
                            ? dayjs(formik.values.start)
                            : null
                        }
                        onBlur={formik.handleBlur}
                        onChange={handleStartDateChange}
                        minDate={dayjs()}
                        slotProps={{
                          textField: {
                            error:
                              formik.touched.start &&
                              Boolean(formik.errors.start),
                            helperText: true,
                          },
                        }}
                        sx={{ "& .MuiInputLabel-root": { mt: "-5px" } }}
                      />
                    </LocalizationProvider>
                    {formik.touched.start && formik.errors.start ? (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-8px",
                          mb: "15px",
                        }}
                      >
                        {formik.errors.start}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      size="small"
                    >
                      <DatePicker
                        name="end"
                        className="customDatePicker"
                        error={formik.touched.end && Boolean(formik.errors.end)}
                        fullWidth
                        label="Select End Date *"
                        value={
                          formik.values.end ? dayjs(formik.values.end) : null
                        }
                        onBlur={formik.handleBlur}
                        onChange={handleEndDateChange} // Call handleEndDateChange instead of directly setting formik field value
                        minDate={
                          selectedStartDate ? selectedStartDate : dayjs()
                        } // Set minDate to selected start date or current date
                        slotProps={{
                          textField: {
                            error:
                              formik.touched.end && Boolean(formik.errors.end),
                            helperText: true,
                          },
                        }}
                        sx={{ "& .MuiInputLabel-root": { mt: "-5px" } }}
                      />
                    </LocalizationProvider>

                    {formik.touched.end && formik.errors.end ? (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-8px",
                          //  mb: "12px",
                        }}
                      >
                        {formik.errors.end}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Discount Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your discount status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRoutes.DISCOUNT}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default EditDiscount;
