import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSupabase from "../utils/useSupabase";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  Grid,
} from "@mui/material";
import CustomTextField from "../CustomTextField";
import { AppRoutes, TableName } from "../constant";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Cartcontext } from "../Products/Context";
import VendorSubHeader from "../VendorSubHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductAdd = () => {
  const [getCategory, setGetCategory] = useState([]);
  const [getSubCategory, setGetSubCategory] = useState([]);
  const [getBrand, setGetBrand] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const supabase = useSupabase();
  const navigate = useNavigate();
  const GlobalState = useContext(Cartcontext);
  const showToast = GlobalState.showToast;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category: Yup.string().required("Category is required"),
    subcategory: Yup.string().required("SubCategory is required"),
    brand: Yup.string().required("Brand is required"),
    description: Yup.string().required("Description is required"),
  });
  useEffect(
    () => {
      const productData = async () => {
        const productId = params.id;
        const { data, error } = await supabase
          .from(TableName.PRODUCTADD)
          .select()
          .eq("productId", productId);

        if (error) {
          console.error("Error fetching Product data:", error.message);
        } else if (data && data.length > 0) {
          console.log("Fetched product Data:", data[0]);
          const product = data[0];
          formik.setValues({
            name: product.productName,
            category: product.categoryCode,
            subcategory: product.subcategoryCode,
            brand: product.brandCode,
            description: product.productDescription,
            isActive: product.isActive,
            isFeatured: product.isFeatured,
            tags: product.tags
              ? product.tags.map((tag) => ({
                  listItem: tag,
                  listDescription: tag,
                }))
              : [],
          });
        }
      };

      if (params.id) {
        productData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      subcategory: "",
      brand: "",
      tags: [],
      description: "",
      isActive: false,
      isFeatured: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setTouched({
        name: true,
        category: true,
        subcategory: true,
        brand: true,
        description: true,
        tags: true,
      });

      if (formik.isValid) {
        if (params.id) {
          setIsLoading(true);
          const { data, error } = await supabase
            .from(TableName.PRODUCTADD)
            .update([
              {
                productName: values.name,
                categoryCode: values.category,
                subcategoryCode: values.subcategory,
                brandCode: values.brand,
                productDescription: values.description,
                isActive: values.isActive,
                isFeatured: values.isFeatured,
                vendorId: localStorage.getItem("ids"),

                tags: values.tags.map((item) => item.listItem),
              },
            ])
            .eq("productId", params.id)
            .select("*");
          if (!error) {
            console.log("Product data update", data);
            setData(data[0]?.productId);
            console.log("data", data[0]?.productId);
            // navigate(AppRoutes.PRODUCTADDLIST);
            showToast("update", "Product");
          }
          setIsLoading(false);
        } else {
          try {
            const vendorId = localStorage.getItem("ids");
            const { data: vendorData, error: vendorError } = await supabase
              .from(TableName.VENDOR)
              .select("FirmName,Pin")
              .eq("id", vendorId)
              .single();

            if (vendorError) {
              console.error("Error fetching vendor data:", vendorError.message);
              return;
            } else {
              console.log(vendorData, "aryan");
            }
            setIsLoading(true);
            const { data, error } = await supabase
              .from(TableName.PRODUCTADD)
              .insert([
                {
                  productName: values.name,
                  categoryCode: values.category,
                  subcategoryCode: values.subcategory,
                  brandCode: values.brand,
                  productDescription: values.description,
                  isActive: values.isActive,
                  isFeatured: values.isFeatured,
                  vendorId: localStorage.getItem("ids"),
                  tags: values.tags.map((item) => item.listItem),
                  isPopular: 0,
                  vendorName: vendorData.FirmName,
                  vendorPin: vendorData.Pin,
                },
              ])
              .select("*");

            if (error) {
              console.error("Error inserting data:", error);
            } else {
              console.log("Data inserted successfully:", data);
              console.log("data", data[0]?.productId);
              setData(data[0]?.productId);
              // navigate(AppRoutes.PRODUCTADDLIST);
              showToast("success", "Product");
            }
            setIsLoading(false);
          } catch (error) {
            console.error("Error inserting data:", error.message);
          }
        }
      }
    },
  });
  const getBrandData = async () => {
    const { data } = await supabase
      .from(TableName.BRAND)
      .select()
      .eq("isActive", true);
    setGetBrand(data);
  };
  const getCategoryData = async () => {
    const { data } = await supabase
      .from(TableName.CATEGORY)
      .select()
      .eq("isActive", true);
    setGetCategory(data);
  };

  const getSubCategoryData = async () => {
    const { data } = await supabase
      .from(TableName.SUBCATEGORY)
      .select()
      .eq("isActive", true);
    setGetSubCategory(data);
  };
  const getfetchList = async () => {
    const { data } = await supabase
      .from(TableName.LIST)

      .select("listDescription")

      .select("listDescription,listItem")

      .eq("listName", "Tag")
      .eq("IsActive", "Yes");

    setSelectedTags(data);
  };

  useEffect(
    () => {
      getfetchList();
      getBrandData();
      getCategoryData();
      getSubCategoryData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add product variant"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to add variant for this product ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              navigate(AppRoutes.PRODUCTADDLIST);
            }}
            variant="outlined"
            color="error"
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleClose();
              navigate(`${AppRoutes.PRODUCTVARIANTLIST}/${data}`);
            }}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <VendorSubHeader
        title={params.id ? "Edit Your Product" : "Add new Product"}
        subtitle="Product"
        back="Product"
        linkProps={{
          to: "/productAddList",
        }}
      />

      <div style={{ padding: "27px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
              borderRadius: "20px",
              p: 4,
            }}
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid lg={4} xs={4} sm={4} md={4}>
              <Typography
                className="head"
                variant="h6"
                sx={{
                  fontWeight: "400",
                  mb: 0.5,
                }}
              >
                Product Basic Details
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    color: "#a3a3b8",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  Please provide productt basic details
                </Typography>
              </Typography>
            </Grid>
            <Grid lg={8} xs={8} sm={8} md={8}>
              <CustomTextField
                name="name"
                className="text"
                label="Product Name*"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                size="small"
                id="outlined-size-small"
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              {formik.touched.name && formik.errors.name && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    mt: "-15px",
                    mb: "12px",
                  }}
                >
                  {formik.errors.name}
                </Typography>
              )}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid lg={8} xs={8} sm={8} md={8}>
                <Stack direction="column">
                  <TextField
                    sx={{ width: "80%" }}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    name="description"
                    label="Product Description*"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    onBlur={(event) => {
                      formik.handleBlur(event);
                      formik.setFieldTouched("description", true);
                    }}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <Typography
                      sx={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        width: "80%",
                        marginBottom: "1",
                        marginTop: "0px",
                        marginRight: "-5px",
                        height: "40px",
                      }}
                    >
                      {formik.errors.description}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <br />

          <Grid
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
              borderRadius: "20px",
              p: 4,
            }}
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid lg={4} xs={4} sm={4} md={4}>
              {" "}
              <Typography
                className="head"
                variant="h6"
                sx={{
                  fontWeight: "400",
                  mb: 0.5,
                }}
              >
                Category
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    color: "#a3a3b8",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  Choose the category and subcategory most suitable for the
                  product
                </Typography>
              </Typography>
            </Grid>
            <Grid lg={8} xs={8} sm={8} md={8}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel
                  sx={{ mt: "-5px" }}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                >
                  Select Category*
                </InputLabel>
                <Select
                  name="category"
                  label="Select Category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  onBlur={formik.handleBlur}
                  sx={{
                    width: "80%",
                    marginBottom: "1",
                    marginTop: "0px",
                    marginRight: "-5px",
                    height: "40px",
                  }}
                >
                  {getCategory.map((item) => (
                    <MenuItem key={item.id} value={item.code}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.category && formik.errors.category && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      mt: "-1px",
                    }}
                  >
                    {formik.errors.category}
                  </Typography>
                )}
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel
                  sx={{ mt: "-5px" }}
                  error={
                    formik.touched.subcategory &&
                    Boolean(formik.errors.subcategory)
                  }
                >
                  Select SubCategory*
                </InputLabel>
                <Select
                  name="subcategory"
                  value={formik.values.subcategory}
                  onChange={formik.handleChange}
                  label="Select SubCategory"
                  disabled={!formik.values.category}
                  error={
                    formik.touched.subcategory &&
                    Boolean(formik.errors.subcategory)
                  }
                  onBlur={formik.handleBlur}
                  sx={{
                    width: "80%",
                    marginBottom: "1",
                    marginTop: "0px",
                    marginRight: "-5px",
                    height: "40px",
                  }}
                >
                  {getSubCategory
                    .filter((item) => item.category === formik.values.category)
                    .map((item) => (
                      <MenuItem key={item.id} value={item.code}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.subcategory && formik.errors.subcategory && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      mt: "-1px",
                    }}
                  >
                    {formik.errors.subcategory}
                  </Typography>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel
                  sx={{ mt: "-5px" }}
                  error={formik.touched.brand && Boolean(formik.errors.brand)}
                >
                  Select Brand*
                </InputLabel>
                <Select
                  name="brand"
                  label="Select Brand"
                  value={formik.values.brand}
                  onChange={formik.handleChange}
                  error={formik.touched.brand && Boolean(formik.errors.brand)}
                  onBlur={formik.handleBlur}
                  sx={{
                    width: "80%",
                    marginBottom: "1",
                    marginTop: "0px",
                    marginRight: "-5px",
                    height: "40px",
                  }}
                >
                  {getBrand.map((item) => (
                    <MenuItem key={item.id} value={item.code}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.brand && formik.errors.brand && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      mt: "-1px",
                    }}
                  >
                    {formik.errors.brand}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
              borderRadius: "20px",
              p: 4,
            }}
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid lg={4} xs={4} sm={4} md={4}>
              <Typography
                className="head"
                variant="h6"
                sx={{
                  fontWeight: "400",
                  mb: 0.5,
                }}
              >
                Additional Details
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    color: "#a3a3b8",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  Choose tags associated with the product
                </Typography>
              </Typography>
            </Grid>
            <Grid lg={8} xs={8} sm={8} md={8}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={selectedTags}
                getOptionLabel={(options) => options.listDescription}
                value={formik.values.tags || []}
                onChange={(event, newValue) => {
                  formik.setFieldValue("tags", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Tags"
                    placeholder="Select Tags"
                    size="small"
                  />
                )}
              />
              <br />
              <Grid lg={8} xs={8} sm={8} md={8}>
                <FormControl fullWidth>
                  <Stack direction="row">
                    <Typography> Featured</Typography>
                    <Switch
                      name="isFeatured"
                      checked={formik.values.isFeatured}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  </Stack>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <br />

          <Grid
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
              borderRadius: "20px",
              p: 4,
            }}
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid lg={4} xs={4} sm={4} md={4}>
              <Typography
                className="head"
                variant="h6"
                sx={{
                  fontWeight: "400",
                  mb: 0.5,
                }}
              >
                Product Status
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    color: "#a3a3b8",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  Please provide your product status
                </Typography>
              </Typography>
            </Grid>
            <Grid lg={8} xs={8} sm={8} md={8}>
              <FormControl fullWidth>
                <Stack direction="row">
                  <Typography>Active</Typography>
                  <Switch
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                </Stack>
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRoutes.PRODUCTADDLIST}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Submit
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{
                      color: "#ffffff",

                      marginLeft: 12,
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default ProductAdd;
