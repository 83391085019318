import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useSupabase from "../../utils/useSupabase";
import { AppRoutes } from "../../constant";
import SelectedFilter from ".././SelectedFilter";
import CommonFilter from ".././CommonFilter";
import { TableName } from "../../constant";
import Header from ".././Header";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Tooltip from "@mui/material/Tooltip";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
const Transaction = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [Transaction, setTransaction] = useState([]);
  const [open, setopen] = useState(false);
  const [transactionData, settransactionData] = useState([]);
  const supabase = useSupabase();
  const navigate = useNavigate();
  useEffect(
    () => {
      fetchTransactionData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  async function fetchTransactionData(newPage, searchKey) {
    try {
      let query = supabase
        .from(TableName.TRANSACTION)
        .select("*", { count: "exact" })
        .order("transactionId", { ascending: false });
      if (searchKey.trim() !== "") {
        query = query.eq("transactionId", `${searchKey}`);
      }
      query = query.order("transactionId");
      if (filter.length > 0) {
        const filterCodes = filter.map((item) => item.code);
        query = query.in("type", filterCodes);
      }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);

      setTransaction(data || []);
      setTotalItems(count || 0);

      if (error) {
        console.error("Error fetching categories:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }
  console.log("s", Transaction);

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm === "") {
      setPage(0);
    }
    setTimeout(() => {
      fetchTransactionData(0, event.target.value);
    }, 500);
  };
  const handleChangePage = (event, newPage) => {
    fetchTransactionData(newPage, searchTerm);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };

  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };
  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Header
          title="Transaction"
          subtitle="Transaction"
          subtitle1="List"
          showAddButton={false}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ p: "7px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by Transaction Id"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <CommonFilter
            id="type"
            title="Type"
            options={[
              { code: "purchase", value: "Purchase" },
              { code: "refund", value: "Refund" },
            ]}
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
        </div>

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Transaction Id
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Order Id
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    User UID
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Payment Method
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Transaction.map((transaction, id) => (
                  <TableRow key={id}>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {transaction.transactionId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {transaction.orderId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {transaction.userUID}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {transaction.paymentMethod}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {transaction.type}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {transaction.status}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      <Tooltip title="Transaction Details" placement="top">
                        <IconButton
                          onClick={() => {
                            setopen(true);
                            navigate(AppRoutes.TransactionDetials, {
                              state: transaction,
                            });
                            settransactionData(transaction);
                          }}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
      <Dialog open={open}>
        <DialogTitle>Transaction Details</DialogTitle>
        <DialogContent>
          {transactionData && (
            <>
              <Typography variant="body1">
                <strong>Transaction ID:</strong> {transactionData.transactionId}
              </Typography>
              <Typography variant="body1">
                <strong>Date:</strong> {transactionData.created_at}
              </Typography>
              <Typography variant="body1">
                <strong>Amount:</strong> {transactionData.amount}
              </Typography>
              <Typography variant="body1">
                <strong>Description:</strong> {transactionData.description}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setopen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Transaction;
