import React, { useContext } from "react";
import "./Profile.css";
// import { Box, Divider, Drawer, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
// import SyncAltIcon from "@mui/icons-material/SyncAlt";
// import PaymentIcon from "@mui/icons-material/Payment";
// import useSupabase from "../utils/useSupabase";
// import { Cartcontext } from "../Products/Context";
const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          height: "35vh",
          // width: "14%",
          paddingTop: "4.1%",
          paddingRight: "13px",
          boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          display: "flex",
          alignContent: "end",
        },
      },
    },
  },
});

function Profile({ showProfile, email, role, setShowProfile }) {
  // const supabase = useSupabase();
  // const GlobalState = useContext(Cartcontext);
  // function handleMouseEnter() {
  //   setShowProfile(true);
  // }
  // function handleMouseLeave() {
  //   setShowProfile(false);
  // }
  // const adminEmail = localStorage.getItem("adminEmail");
  // const vendorEmail = localStorage.getItem("vendorEmail");
  // const userType = sessionStorage.getItem("userType");
  //const userMail = userType === "true" ? adminEmail : vendorEmail;
  return (
    <ThemeProvider theme={theme}>
      {/* <Drawer
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        open={showProfile}
        anchor="right"
        className="profile"
        sx={{ overflow: "hidden" }}
      >
        <Box onMouseLeave={handleMouseLeave}>
          <div className="box">
            <div style={{ marginTop: "12px" }}>
              <Typography className="role">{role}</Typography>
              <Typography
                className="email"
                sx={{
                  fontSize: "1rem",
                  paddingBottom: "12px",
                }}
              >
                {userMail}
              </Typography>
            </div>
            <Divider></Divider>
            <div className="List" style={{ textDecoration: "none" }}>
              <div style={{ display: "flex", padding: "7px" }} className="set">
                <PermIdentityIcon
                  sx={{
                    marginRight: "13px",
                    color: "rgb(108, 115, 127)",
                    paddingLeft: "9px",
                  }}
                />
                <Typography className="txts">Profile</Typography>
              </div>
              <div style={{ display: "flex", padding: "7px" }} className="set">
                <SyncAltIcon
                  sx={{
                    marginRight: "13px",
                    color: "rgb(108, 115, 127)",
                    paddingLeft: "9px",
                  }}
                />
                <Typography className="txts">Settings</Typography>
              </div>
              <div
                style={{ display: "flex", padding: "4px 0px 12px 7px" }}
                className="set"
              >
                <PaymentIcon
                  sx={{
                    marginRight: "13px",
                    color: "rgb(108, 115, 127)",
                    paddingLeft: "9px",
                  }}
                />
                <Typography className="txts">Billing</Typography>
              </div>
            </div>
            <Divider></Divider>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                padding: "9px",
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    borderRadius: "5px",
                    bgcolor: "#f1f3f4",
                  },
                }}
                onClick={async () => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("supabaseToken");
                  localStorage.removeItem("vendorEmail");
                  localStorage.removeItem("ids");
                  localStorage.removeItem("adminAccessToken");
                  localStorage.removeItem("supabaseToken");
                  localStorage.removeItem("adminEmail");
                  GlobalState.setUser(null);
                  await supabase.auth.signOut();
                }}
              >
                Logout
              </Typography>
            </div>
          </div>
        </Box>
      </Drawer> */}
    </ThemeProvider>
  );
}

export default Profile;
