import { Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./SignIn";
import VendorRegistrationForm from "./component/VendorRegistration";
import { AppRoutes } from "./constant";
import Layout from "./Layout";
import Dashboard from "./Dashboard";
import Category from "./component/Category";
import PrivateRoute from "./PrivateRoute";
import { useContext } from "react";
import Subcategory from "./component/Subcategory";
import Banner from "./Banner/Banner";
import Discount from "./component/Discount/Discount";
import BrandName from "./Brand/BrandName";
import { Cartcontext } from "./Products/Context";
import Vendor from "./component/Vendor";
import EditDiscount from "./component/Discount/EditDiscount";
import List from "./component/List";
import AddCategory from "./component/AddCategory";
import AddSubcategory from "./component/AddSubcategory";
import AddBrand from "./component/AddBrand";
import ProductAdd from "./component/ProductAdd";
import ProductAddList from "./component/ProductAddList";
import InventoryList from "./component/InventoryList";
import AddInventory from "./component/AddInventory";
import Order from "./component/Order/Order";
import AddProductVariant from "./component/AddProductVariant";
import ProductVariantList from "./component/ProductVariantList";
import Orderline from "./component/Order/Orderline";
import VendorDashboard from "./component/VendorDashboard";
import Emailverfiy from "./Emailverfiy";
import Resetpassword from "./Auth/Resetpassword";
import ForgotPassword from "./Auth/Forgotpassword";
import ReturnOrder from "./ReturnOrder";
import Transaction from "./component/Transaction/Transaction";
import TransactionDetailsPage from "./component/Transaction/TransactionDetailsPage";
import Refund from "./component/Refund/Refund";

function App() {
  const GlobalState = useContext(Cartcontext);
  const getLoggedInUser = GlobalState.getLoggedInUser;
  const isLoggedIn = getLoggedInUser();
  const userType = sessionStorage.getItem("userType");
  const routes =
    isLoggedIn && userType !== null ? (
      <Routes>
        <Route
          path={AppRoutes.HOME}
          element={
            <Layout sx={{ backgroundColor: "lightgray" }}>
              {userType === "true" ? <Dashboard /> : <VendorDashboard />}
            </Layout>
          }
        />
        <Route
          path={AppRoutes.VENDOR}
          element={<PrivateRoute element={VendorRegistrationForm} />}
        />
        <Route
          path={AppRoutes.UPDATEPROFILE}
          element={<PrivateRoute element={VendorRegistrationForm} />}
        />
        <Route
          path={AppRoutes.VENDOR_WITHOUT_ID}
          element={<PrivateRoute element={VendorRegistrationForm} />}
        />

        <Route
          path={AppRoutes.DASHBOARD}
          element={<PrivateRoute element={Dashboard} />}
        />
        <Route
          path={AppRoutes.VENDORDASHBOARD}
          element={<PrivateRoute element={VendorDashboard} />}
        />
        <Route
          path={AppRoutes.CATEGORY}
          element={<PrivateRoute element={Category} />}
        />
        <Route
          path={AppRoutes.ADDCATEGORY_WITHOUT_ID}
          element={<PrivateRoute element={AddCategory} />}
        />
        <Route
          path={AppRoutes.ADDCATEGORY}
          element={<PrivateRoute element={AddCategory} />}
        />
        <Route
          path={AppRoutes.SUBCATEGORY}
          element={<PrivateRoute element={Subcategory} />}
        />
        <Route
          path={AppRoutes.ADDSUBCATEGORY_WITHOUT_ID}
          element={<PrivateRoute element={AddSubcategory} />}
        />
        <Route
          path={AppRoutes.ADDSUBCATEGORY}
          element={<PrivateRoute element={AddSubcategory} />}
        />
        <Route
          path={AppRoutes.BANNER}
          element={<PrivateRoute element={Banner} />}
        />
        <Route
          path={AppRoutes.BRANDNAME}
          element={<PrivateRoute element={BrandName} />}
        />
        <Route
          path={AppRoutes.ADDBRAND_WITHOUT_ID}
          element={<PrivateRoute element={AddBrand} />}
        />
        <Route
          path={AppRoutes.ADDBRAND}
          element={<PrivateRoute element={AddBrand} />}
        />
        <Route
          path={AppRoutes.DISCOUNT}
          element={<PrivateRoute element={Discount} />}
        />
        <Route
          path={AppRoutes.EDITPAGE}
          element={<PrivateRoute element={EditDiscount} />}
        />
        <Route
          path={AppRoutes.DISCOUNTEDIT}
          element={<PrivateRoute element={EditDiscount} />}
        />
        <Route
          path={AppRoutes.VENDORLIST}
          element={<PrivateRoute element={Vendor} />}
        />

        <Route
          path={AppRoutes.LIST}
          element={<PrivateRoute element={List} />}
        />
        <Route
          path={AppRoutes.PRODUCTADDLIST}
          element={<PrivateRoute element={ProductAddList} />}
        />
        <Route
          path={AppRoutes.PRODUCTADD_WITHOUT_ID}
          element={<PrivateRoute element={ProductAdd} />}
        />
        <Route
          path={AppRoutes.PRODUCTADD}
          element={<PrivateRoute element={ProductAdd} />}
        />
        <Route
          path={`${AppRoutes.PRODUCTVARIANTLIST}/:productId`}
          element={<PrivateRoute element={ProductVariantList} />}
        />
        <Route
          path={`${AppRoutes.ADDPRODUCTVARIANT_WITHOUT_ID}/:productId`}
          element={<PrivateRoute element={AddProductVariant} />}
        />
        <Route
          path={`${AppRoutes.ADDPRODUCTVARIANT}/:productId/:variantId`}
          element={<PrivateRoute element={AddProductVariant} />}
        />
        <Route
          path={`${AppRoutes.INVENTORYLIST}/:productId/:variantId`}
          element={<PrivateRoute element={InventoryList} />}
        />
        <Route
          path={`${AppRoutes.ADDINVENTORY_WITHOUT_ID}/:productId/:variantId`}
          element={<PrivateRoute element={AddInventory} />}
        />
        <Route
          path={`${AppRoutes.ADDINVENTORY}/:productId/:variantId/:inventoryId`}
          element={<PrivateRoute element={AddInventory} />}
        />
        <Route
          path={AppRoutes.ORDER}
          element={<PrivateRoute element={Order} />}
        />
        <Route
          path={AppRoutes.ORDERLINE}
          element={<PrivateRoute element={Orderline} />}
        />
        <Route
          path={AppRoutes.RETURNORDERLIST}
          element={<PrivateRoute element={ReturnOrder} />}
        />
        <Route path={AppRoutes.Emailverfiy} element={<Emailverfiy />}></Route>
        <Route
          path={AppRoutes.Resetpassword}
          element={<Resetpassword />}
        ></Route>
        <Route
          path={AppRoutes.Forgotpassword}
          element={<ForgotPassword />}
        ></Route>
        <Route
          path={AppRoutes.Transaction}
          element={<PrivateRoute element={Transaction} />}
        ></Route>
        <Route
          path={AppRoutes.TransactionDetials}
          element={<PrivateRoute element={TransactionDetailsPage} />}
        ></Route>
        <Route
          path={AppRoutes.Refund}
          element={<PrivateRoute element={Refund} />}
        ></Route>
      </Routes>
    ) : (
      <Routes>
        <Route path={AppRoutes.HOME} element={<SignIn />} />
        <Route path={AppRoutes.Emailverfiy} element={<Emailverfiy />}></Route>
        <Route
          path={AppRoutes.Resetpassword}
          element={<Resetpassword />}
        ></Route>
        <Route
          path={AppRoutes.Forgotpassword}
          element={<ForgotPassword />}
        ></Route>
      </Routes>
    );
  <></>;
  return routes;
}

export default App;
