import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  FormControl,
  Switch,
  Stack,
  InputLabel,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import { AppRoutes, StorageBucket, TableName } from "../constant";
import { Link } from "react-router-dom";

import CustomTextField from "../CustomTextField";
import ImageUpload from "./ImageUpload";
import useSupabase from "../utils/useSupabase";
import { useContext } from "react";
import { Cartcontext } from "../Products/Context";
import { useEffect } from "react";
import SubHeader from "./SubHeader";
import { removeImage, uploadImage } from "../utils/helperFunction";
import CircularProgress from "@mui/material/CircularProgress";

const AddCategory = () => {
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const supabase = useSupabase();
  const GlobalState = useContext(Cartcontext);

  const showToast = GlobalState.showToast;
  const validationSchema = yup.object({
    categoryCode: yup
      .string()
      .required("Category Code is required")
      .max(20, "Maximum 20 characters")
      .matches(/^\S*$/, "Spaces are not allowed")
      .test(
        "code-exists",
        "Category code already exists",
        async function (value) {
          const categoryId = params.id;
          if (categoryId) {
            return true;
          }

          try {
            const { data: existingData, error: existingError } = await supabase
              .from(TableName.CATEGORY)
              .select("id")
              .eq("code", value);

            if (existingError) {
              console.error(
                "Error checking existing code:",
                existingError.message
              );
              return false;
            }

            return !(existingData && existingData.length > 0);
          } catch (error) {
            console.error("Error:", error.message);
            return false;
          }
        }
      ),

    categoryName: yup
      .string()
      .required("Category Name is required")
      .max(20, "Maximum 20 characters")
      .matches(/^[^\d]+$/, "Numbers are not allowed")
      .test(
        "name-exists",
        "Category name already exists",
        async function (value) {
          const categoryId = params.id;
          if (categoryId) {
            return true;
          }

          try {
            const { data: existingData, error: existingError } = await supabase
              .from(TableName.CATEGORY)
              .select("id")
              .eq("name", value);

            if (existingError) {
              console.error(
                "Error checking existing name:",
                existingError.message
              );
              return false;
            }

            return !(existingData && existingData.length > 0);
          } catch (error) {
            console.error("Error:", error.message);
            return false;
          }
        }
      ),
  });

  useEffect(
    () => {
      const fetchCategoryData = async () => {
        const categoryId = params.id;
        const { data, error } = await supabase
          .from(TableName.CATEGORY)
          .select()
          .eq("id", categoryId);

        if (error) {
          console.error("Error fetching category data:", error.message);
        } else if (data && data.length > 0) {
          const category = data[0];
          formik.setValues({
            categoryCode: category.code,
            categoryName: category.name,
            commission: category.commission,
            isActive: category.isActive,
          });
          if (category.imageUrl) {
            setImagesPreview([{ imageUrl: category.imageUrl }]);
          }
          setOriginalImage(category.imageUrl);
        }
      };

      if (params.id) {
        fetchCategoryData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );

  const formik = useFormik({
    initialValues: {
      categoryCode: "",
      categoryName: "",
      commission: "",
      isActive: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      setisLoading(true);
      const commissionValue =
        values.commission === "" ? "0" : values.commission;
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.CATEGORY, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.CATEGORY,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.CATEGORY)
          .update([
            {
              name: values.categoryName,
              code: values.categoryCode,
              isActive: values.isActive,
              commission: commissionValue,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("category data update", data);
          setisLoading(false);
          navigate(AppRoutes.CATEGORY);
          showToast("update", "Category");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.CATEGORY,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.CATEGORY)
            .insert([
              {
                name: values.categoryName,
                code: values.categoryCode,
                isActive: values.isActive,
                commission: commissionValue,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }
          setisLoading(false);
          navigate(AppRoutes.CATEGORY);
          showToast("success", "Category");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
  };
  return (
    <div>
      <SubHeader
        title={params.id ? "Edit a Category " : "Add a new Category"}
        subtitle="Category"
        back="Category"
        linkProps={{
          to: "/category",
        }}
      />
      <div style={{ padding: "27px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Category Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide category details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <ImageUpload
                      isMultiImages={false}
                      handleImageUpload={handleImageUpload}
                      handleCancelImage={handleCancelImage}
                      uploadedImages={imagesPreview}
                      title="Click to upload category Image"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Category
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="categoryCode"
                      value={formik.values.categoryCode}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^[a-zA-Z0-9]*$/.test(value) &&
                          value.length <= 10
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.categoryCode &&
                        Boolean(formik.errors.categoryCode)
                      }
                      inputProps={{ maxLength: 20 }}
                      disabled={!!params.id}
                    />
                    {formik.touched.categoryCode &&
                      formik.errors.categoryCode && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-10px",
                          }}
                        >
                          {formik.errors.categoryCode}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Category Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="categoryName"
                      value={formik.values.categoryName}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const { value } = e.target;
                        const capitalizedValue =
                          value.charAt(0).toUpperCase() + value.slice(1);
                        if (!/\d/.test(capitalizedValue)) {
                          formik.handleChange({
                            target: {
                              name: "categoryName",
                              value: capitalizedValue,
                            },
                          });
                        }
                      }}
                      error={
                        formik.touched.categoryName &&
                        Boolean(formik.errors.categoryName)
                      }
                      inputProps={{ maxLength: 20 }}
                    />
                    {formik.touched.categoryName &&
                      formik.errors.categoryName && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-10px",
                          }}
                        >
                          {formik.errors.categoryName}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Commission Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide childbazaar Commission details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Commission Percentage
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="commission"
                      value={formik.values.commission}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        let { value } = e.target;
                        value = value.replace(/\D/, "");
                        const numericValue = parseInt(value);
                        if (numericValue <= 100 || value === "") {
                          formik.setFieldValue("commission", value);
                        }
                      }}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Category Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your category status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRoutes.CATEGORY}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="primary" className="load" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
