import React from "react";
import { createContext } from "react";
import { useState, useEffect } from "react";
import useSupabase from "../utils/useSupabase";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../constant";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
export const Cartcontext = createContext();

export const Context = (props) => {
  const supabase = useSupabase();
  const [userRole, setUserRole] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [toastCancel, setToastCancel] = useState("false");
  const url = "https://vendor.childbazaar.in/";
  const location = useLocation();
  const getLoggedInUser = () => {
    return user !== null;
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(
    () => {
      const currentUrl = location.pathname;
      const urlArray = currentUrl.split("/");
      localStorage.setItem("supabaseToken", supabase.supabaseKey);
      const storedToken = localStorage.getItem("accessToken");
      const storedToken1 = localStorage.getItem("adminAccessToken");
      if (storedToken || storedToken1) {
        const decodedToken = jwtDecode(
          storedToken ? storedToken : storedToken1
        );
        if (decodedToken) {
          setUser(decodedToken.email);
        }
      } else {
        // If no token found, user is logged out, so navigate to login page
        if (
          !(
            window.location.href === url + AppRoutes.Forgotpassword ||
            urlArray[1] === "resetpassword"
          )
        ) {
          navigate(AppRoutes.HOME);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  useEffect(
    () => {
      const currentUrl = location.pathname;
      const urlArray = currentUrl.split("/");
      supabase.auth.onAuthStateChange(async (event, session) => {
        console.log(`Supabase auth event: ${event}`);
        if (event === "SIGNED_OUT") {
          localStorage.removeItem("ids");
          setUser(null);
          if (
            !(
              window.location.href === url + AppRoutes.Forgotpassword ||
              urlArray[1] === "resetpassword"
            )
          ) {
            navigate(AppRoutes.HOME);
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const Toaststatus = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const showToast = (type, message) => {
    switch (type) {
      case "success":
        toast.success(`${message} Added Successfully.`, Toaststatus);
        break;
      case "cancel":
        toast.error(`${message} Canceled Successfully.`, Toaststatus);
        break;
      case "delete":
        toast.success(`${message} Deleted Successfully.`, Toaststatus);
        break;
      case "update":
        toast.success(`${message} Updated Successfully.`, Toaststatus);
        break;
      case "uploaded":
        toast.success(`${message} Uploaded Successfully.`, Toaststatus);
        break;
      case "signIn":
        toast.error("Invalid Username or Password", {
          autoClose: 1000,
          theme: "colored",
        });
        break;
      default:
    }
  };

  const info = {
    getLoggedInUser,
    isModalOpen,
    handleModalClose,
    setUser,
    userRole,
    setUserRole,
    user,
    openSnack,
    handleClose,
    showToast,
    toastCancel,
    setToastCancel,
  };
  return (
    <Cartcontext.Provider value={info}>{props.children}</Cartcontext.Provider>
  );
};

export default Context;
