import React from "react";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { Grid, IconButton, Typography, Link } from "@mui/material";
import { AppRoutes } from "./constant";
import { useNavigate } from "react-router-dom";

const VendorSubHeader = (props) => {
  const navigate = useNavigate();
  const { linkProps } = props;

  const handleBack = () => {
    if (linkProps && linkProps.to) {
      navigate(linkProps.to); // Navigate to the provided route
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      padding={3}
      paddingLeft={5}
    >
      <Grid item>
        <Link
          {...linkProps}
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleBack} // Call handleBack function on click
        >
          <IconButton>
            <ArrowBackSharpIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="span"
            color="textPrimary"
            fontWeight="inherit"
          >
            {props.back}
          </Typography>
        </Link>
      </Grid>
      <Grid item>
        <Typography variant="h4" fontWeight="bold">
          {props.title}
        </Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Link
          to={AppRoutes.DASHBOARD}
          underline="hover"
          style={{
            fontSize: "12px",
            color: "rgb(17, 25, 39)",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(AppRoutes.VENDORDASHBOARD);
          }}
        >
          Dashboard
        </Link>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontSize: "20px", paddingLeft: "2px", paddingRight: "2px" }}
        >
          &bull; {/* Larger dot character */}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: " rgb(108, 115, 127)",
            marginTop: "1px",
          }}
        >
          {props.subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VendorSubHeader;
