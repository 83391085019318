import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogContentText,
} from "@mui/material";
import "./Style/CommonDelete.css";
import useSupabase from "./utils/useSupabase";
import { toast } from "react-toastify";

function CommonDelete({
  isDeleteOpen,
  setIsDeleteOpen,
  full,
  table,
  Ids,
  deleteId,
  getFunction,
  page,
  rowsPerPage,
  columnName,
}) {
  const supabase = useSupabase();
  const Toaststatus = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successMessage = () => {
    toast.success(`${table} deleted successfully.`, Toaststatus);
  };

  const handleRemove = async (index) => {
    const actualIndex = page * rowsPerPage + index;
    if (actualIndex < 0 || actualIndex >= full.length) {
      console.error("Invalid index:", actualIndex);
      return;
    }
    const selectedItem = full[actualIndex];

    console.log("first", actualIndex);
    console.log("selectitem", selectedItem);
    if (!selectedItem || !selectedItem.id) {
      console.error("Invalid item or missing 'id' property:", selectedItem);
      return;
    }
    const { error } = await supabase
      .from(table)
      .delete()
      .eq("id", selectedItem.id);
    if (error) {
      console.error("Error deleting data:", error);
    } else {
      successMessage();
      console.log("Data deleted successfully");
      getFunction();
    }
  };

  const handleDelete = async (Ids) => {
    const { error } = await supabase.from(table).delete().eq(columnName, Ids);
    if (error) {
      console.error("Error deleting data:", error);
    } else {
      showMessage("delete", `${table}`);
      console.log("Data deleted successfully");
      getFunction();
    }
  };

  const handleclose = () => {
    setIsDeleteOpen(false);
  };

  const showMessage = (type, message) => {
    switch (type) {
      case "delete":
        toast.success(`${message}  delete Successfully.`, Toaststatus);
        break;

      default:
    }
  };
  return (
    <>
      <Dialog
        className="dialogop"
        open={isDeleteOpen}
        maxWidth="md"
        aria-label="dialog-title"
        aria-describedby="dialog-description"
        BackdropProps={{
          sx: { backgroundColor: "rgba(0,0,0,0.2)" },
        }}
        PaperProps={{
          sx: { boxShadow: "none", padding: "1rem 1rem" },
        }}
      >
        <DialogTitle id="dialog-title">
          <Typography className="confirm">Delete Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            Are you sure you want to delete this {table}?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          <Button
            className="del-cancelbtn"
            onClick={() => {
              handleclose();
            }}
          >
            cancel
          </Button>
          <Button
            className="del-deletebtn"
            onClick={() => {
              if (Ids !== undefined) {
                handleRemove(Ids);
              } else if (deleteId !== undefined) {
                handleDelete(deleteId);
              }
              handleclose();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CommonDelete;
