import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AppRoutes, StorageBucket, TableName } from "../constant";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CustomTextField from "../CustomTextField";
import ImageUpload from "./ImageUpload";
import { Link } from "react-router-dom";
import useSupabase from "../utils/useSupabase";
import { removeImage, uploadImage, getListData } from "../utils/helperFunction";
import { useFormik } from "formik";
import * as yup from "yup";
import { Cartcontext } from "../Products/Context";
import { useContext } from "react";
import VendorSubHeader from "../VendorSubHeader";
const AddProductVariant = () => {
  const params = useParams();
  const navigate = useNavigate();
  const supabase = useSupabase();
  const inputRef = useRef(null);
  const { productId } = useParams();
  const { variantId } = useParams();
  const GlobalState = useContext(Cartcontext);

  const showToast = GlobalState.showToast;
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [colorList, setColorList] = useState([]);
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    color: yup.string().required("Color is required"),
    images: yup.string().required("Please select a image"),
  });
  useEffect(
    () => {
      const fetchVariantData = async () => {
        if (variantId) {
          try {
            const { data, error } = await supabase
              .from(TableName.PRODUCTVARIANT)
              .select()
              .eq("variantId", variantId);

            if (error) {
              console.error("Error fetching variant data:", error.message);
            } else if (data && data.length > 0) {
              const variant = data[0];

              formik.setValues({
                name: variant.variantName,
                color: variant.variantColor,
                isActive: variant.isActive,
                images: variant.variantImage,
              });

              if (variant.variantImage) {
                setImagesPreview([{ variantImage: variant.variantImage }]);
                setUploadedImages(JSON.parse(variant.variantImage));
              }

              setOriginalImage(variant.variantImage);
            }
          } catch (error) {
            console.error("Error fetching variant data:", error.message);
          }
        }
      };

      fetchVariantData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantId, supabase]
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      color: "",
      isActive: false,
      images: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      console.log("ggvgyv");
      console.log("Aryan", values);
      if (!values.images.length) {
        formik.setFieldError("images", "Please select an image");
        return;
      }

      if (variantId) {
        let publicUrl = originalImage;
        // if (isNewImageUp) {
        //   await removeImage(StorageBucket.PRODUCTVARIANT, originalImage);

        //   publicUrl = await uploadImage(
        //     StorageBucket.PRODUCTVARIANT,
        //     imagesPreview[0].imageFile
        //   );
        // }
        if (Array.isArray(uploadedImages) && uploadedImages.length > 0) {
          const existingUrls = uploadedImages.filter(
            (image) => typeof image === "string"
          );
          const newImages = uploadedImages.filter(
            (image) => typeof image !== "string"
          );

          const newUrls = await Promise.all(
            newImages.map(async (image) => {
              return await uploadImage(
                StorageBucket.PRODUCTVARIANT,
                image,
                true
              );
            })
          );

          publicUrl = [...existingUrls, ...newUrls];
          console.log("Uploaded Image URLs:", publicUrl);
        }

        const { data: remDuplicateColor } = await supabase
          .from(TableName.PRODUCTVARIANT)
          .select("*")
          .neq("variantId", variantId)
          .eq("variantColor", values.color)
          .eq("productId", productId);

        console.log("datass", remDuplicateColor);
        if (remDuplicateColor.length !== 0) {
          alert(`This Color ${values.color} already exists`);
        } else {
          const { data, error } = await supabase
            .from(TableName.PRODUCTVARIANT)
            .update([
              {
                productId: productId,
                variantName: values.name,
                variantColor: values.color,
                isActive: values.isActive,
                variantImage: publicUrl,
              },
            ])
            .eq("variantId", variantId);
          if (!error) {
            console.log("variant data update", data);

            navigate(`${AppRoutes.PRODUCTVARIANTLIST}/${productId}`);
            showToast("update", "Variant");
          }
        }
      } else {
        try {
          let publicUrl = [];
          if (Array.isArray(uploadedImages) && uploadedImages.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.PRODUCTVARIANT,
              uploadedImages,
              true
            );
            console.log("Uploaded Image URLs:", publicUrl);
          }

          const { data, error } = await supabase
            .from(TableName.PRODUCTVARIANT)
            .select("*")
            .eq("variantColor", values.color)
            .eq("productId", productId);

          if (data.length !== 0) {
            alert(`This Color ${values.color} already exists`);
          } else {
            const { error: insertError } = await supabase
              .from(TableName.PRODUCTVARIANT)
              .insert([
                {
                  productId: productId,
                  variantName: values.name,
                  variantColor: values.color,
                  isActive: values.isActive,
                  variantImage: publicUrl,
                },
              ]);

            if (insertError) {
              console.error("Error inserting data:", insertError.message);
              return;
            }
            const { data: productData, error: productError } = await supabase
              .from(TableName.PRODUCT)
              .select("productImage")
              .eq("productId", productId)
              .single();

            if (productError) {
              console.error(
                "Error fetching product data:",
                productError.message
              );
              return;
            }
            const existingImages = productData.productImage || "[]";
            const parsedExistingImages = JSON.parse(existingImages);
            const updatedImages = JSON.stringify([
              ...parsedExistingImages,
              ...publicUrl,
            ]);
            const { error: updateError } = await supabase
              .from(TableName.PRODUCT)
              .update({ productImage: updatedImages })
              .eq("productId", productId);

            if (updateError) {
              console.error(
                "Error updating product data:",
                updateError.message
              );
              return;
            }
            navigate(`${AppRoutes.PRODUCTVARIANTLIST}/${productId}`);
            showToast("success", "Variant");
          }
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  useEffect(() => {
    const fetchColorData = async () => {
      const colors = await getListData("Color");
      if (colors && colors?.length > 0) {
        setColorList(colors);
      }
    };

    fetchColorData();
  }, []);
  // const handleImageUpload = async (event) => {
  //   const imageFiles = event.target.files;

  //   setUploadedImages([...uploadedImages, ...imageFiles]);

  //   formik.setFieldValue("images", [...formik.values.images, ...imageFiles]);
  //   setIsNewImageUp(true);

  //   if (inputRef.current) {
  //     inputRef.current.value = "";
  //   }
  // };
  const handleImageUpload = async (event) => {
    const imageFiles = event.target.files;

    const updatedImages = [...uploadedImages, ...imageFiles];
    setUploadedImages(updatedImages);

    formik.setFieldValue("images", updatedImages);
    setIsNewImageUp(true);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleCancelImage = async (index, bucketName, imgUrl) => {
    if (params.id) {
      await removeImage(bucketName, imgUrl);
    }
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
    if (updatedImages.length === 0) {
      formik.setFieldError("images", "Please select an image");
    }
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  return (
    <div>
      <VendorSubHeader
        title={
          params.variantId
            ? "Edit a Product Variant "
            : "Add a new Product Variant"
        }
        subtitle="Product Variant"
        back="Product Variant"
        linkProps={{
          to: `${AppRoutes.PRODUCTVARIANTLIST}/${productId}`,
        }}
      />
      <div style={{ padding: "27px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Product Variant Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Product Variant details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="name"
                      label="Name*"
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-12px",
                        }}
                      >
                        {formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ pt: "10px" }}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{ mt: "-5px" }}
                        error={
                          formik.touched.color && Boolean(formik.errors.color)
                        }
                      >
                        Color*
                      </InputLabel>
                      <Select
                        name="color"
                        label="Color*"
                        onChange={formik.handleChange}
                        value={formik.values.color}
                        error={
                          formik.touched.color && Boolean(formik.errors.color)
                        }
                        onBlur={formik.handleBlur}
                        sx={{
                          width: "80%",
                          height: "40px",
                        }}
                      >
                        {colorList.map((color) => (
                          <MenuItem key={color.listItem} value={color.listItem}>
                            {color.listDescription}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.color && formik.errors.color && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "2px",
                          }}
                        >
                          {formik.errors.color}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Product Image
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide product image
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <ImageUpload
                      handleImageUpload={handleImageUpload}
                      handleCancelImage={handleCancelImage}
                      isMultiImages={true}
                      inputRef={inputRef}
                    />

                    {uploadedImages.length === 0 && (
                      <Typography sx={{ fontSize: "1.3rem" }}>
                        Click to upload an image or photos
                      </Typography>
                    )}
                    {formik.touched.images && formik.errors.images && (
                      <Typography
                        sx={{ color: "#d32f2f", fontSize: "0.75rem", ml: 12 }}
                      >
                        {formik.errors.images}
                      </Typography>
                    )}
                    <div className="photos">
                      {Array.isArray(uploadedImages) &&
                        uploadedImages.map((image, index) => (
                          <div key={index} className="image-card">
                            <img
                              src={
                                image.name ? URL.createObjectURL(image) : image
                              }
                              alt=""
                              className="uploaded-image"
                              style={{
                                width: 150,
                                height: 130,
                                borderRadius: 22,
                                flexWrap: "wrap",
                              }}
                            />

                            <CancelIcon
                              className="cancel-button"
                              onClick={() =>
                                handleCancelImage(
                                  index,
                                  StorageBucket.PRODUCTVARIANT,
                                  image
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Variant Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your variant status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={`${AppRoutes.PRODUCTVARIANTLIST}/${productId}`}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddProductVariant;
