import React, { useState } from "react";
import {
  Modal,
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";

const ChangePassword = (props) => {
  const [passwordError, setPasswordError] = useState("");
  const [message, setMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleModalClose = () => {
    props.handleModalClose();
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setMessage("");
  };

  const handleSavePassword = () => {
    if (!newPassword || !confirmPassword) {
      setPasswordError("Please enter a valid password and confirm it.");
      return;
    }

    if (newPassword === confirmPassword) {
      props.updatePassword(newPassword, props.email);
      setMessage("Password updated successfully!");
      handleModalClose();
    } else {
      setPasswordError("New password and confirm password do not match.");
    }
  };

  return (
    <>
      <Modal open={props.open}>
        <Container component="main" maxWidth="xs">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="20vh"
            p={3}
            bgcolor="white"
            borderRadius={5}
            mt={10}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  Change Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {passwordError && (
                  <p style={{ color: "red" }}>{passwordError}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSavePassword}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12}>
                {message && <p style={{ color: "green" }}>{message}</p>}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default ChangePassword;
