import React, { useState } from "react";
import CustomTextField from "../CustomTextField";
import validator from "validator";
import axios from "axios";
import { useFormik } from "formik";
import validationSchema from "./ValidationSchema";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import useSupabase from "../utils/useSupabase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useContext } from "react";
import { Cartcontext } from "../Products/Context";
import { useNavigate } from "react-router-dom";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Box,
  InputLabel,
  Button,
  Grid,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link, useParams } from "react-router-dom";
import {
  AppRoutes,
  TableName,
  emailcontent,
  watiTemplateName,
  websiteDetials,
} from "../constant";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sendTemplateMessages } from "../Services/wati";
import { emailtemplate } from "../constant";
import { sendetemplatemail } from "../Services/smtp";

const VendorRegistrationForm = () => {
  const GlobalState = useContext(Cartcontext);
  const showToast = GlobalState.showToast;
  const [countryCode, setCountryCode] = useState("91");
  const [isDisabled, setIsDisabled] = useState(false);
  const handleChange = (value) => {
    setCountryCode(value);
  };

  useEffect(() => {
    const storedIds = localStorage.getItem("ids");
    if (storedIds) {
      setIsDisabled(true);
    }
  }, []);

  const sendemail = async (data) => {
    const edata = {
      message: "Onboarding Vendor",
      email: data.toemail,
      content: { template: data.template, emailVariables: data.emailVariables },
    };

    const email = await fetch(`${process.env.REACT_APP_CB_URL}api/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(edata),
    });
  };
  const sendestatusmail = async (data) => {
    const edata = {
      message: `Your Account is ${isActive ? "Active" : "InActive"} `,
      email: data.toemail,
      content: { template: data.template, emailVariables: data.emailVariables },
    };

    sendetemplatemail(edata);
  };
  const [formData] = useState({
    firmName: "",
    firmType: "",
    country: "",
    gstNumber: "",
    panNumber: "",
    companyStatus: "",
    address: "",
    city: "",
    state: "",
    pin: "",
    stdcode: "",
    nameofperson: "",
    fax: "",
    contactperson: "",
    website: "",
    mobile: "",
    email: "",
    msme: "",
    description: "",
    supply: "",
    commission: "",
    countryCode: "",
    holdername: "",
    accountnumber: "",
    reaccountnumber: "",
    ifsccode: "",
  });
  const formik = useFormik({
    initialValues: formData,
    firmName: "",
    firmType: "",
    country: "",
    gstNumber: "",
    panNumber: "",
    companyStatus: "",
    address: "",
    city: "",
    state: "",
    pin: "",
    stdcode: "",
    nameofperson: "",
    fax: "",
    contactperson: "",
    website: "",
    mobile: "",
    email: "",
    msme: "",
    description: "",
    supply: "",
    commission: "",
    countryCode: "",
    holdername: "",
    accountnumber: "",
    reaccountnumber: "",
    ifsccode: "",

    validationSchema,
    onSubmit: (values) => {
      setisLoading(true);
    },
  });
  const navigate = useNavigate();
  const [places, setPlaces] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isVendorIdPresent, setIsVendorIdPresent] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const handleActiveChange = () => {
    setIsActive(!isActive);
  };
  const supabase = useSupabase();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    Object.keys(formik.values).forEach((key) => {
      formik.setFieldTouched(key, true);
    });
    if (!formik.values.firmName?.trim()) {
      return;
    }
    if (!formik.values.firmType?.trim()) {
      return;
    }
    if (!formik.values.panNumber?.trim()) {
      return;
    }
    if (!formik.values.description?.trim()) {
      return;
    }
    if (!formik.values.mobile?.trim()) {
      return;
    }
    if (!formik.values.website?.trim()) {
      return;
    }
    if (!formik.values.nameofperson?.trim()) {
      return;
    }
    if (!formik.values.pin?.trim()) {
      return;
    }
    if (!formik.values.city?.trim()) {
      return;
    }
    if (!formik.values.state?.trim()) {
      return;
    }
    if (!formik.values.country?.trim()) {
      return;
    }
    if (!formik.values.address?.trim()) {
      return;
    }
    if (!formik.values.holdername?.trim()) {
      return;
    }
    if (!formik.values.accountnumber?.trim()) {
      return;
    }
    if (!formik.values.reaccountnumber?.trim()) {
      return;
    }
    if (formik.errors.reaccountnumber) {
      // If there's an error in reaccountnumber, don't submit the form
      return;
    }
    if (!formik.values.ifsccode?.trim()) {
      return;
    }
    if (!validator.isEmail(formik.values.email)) {
      return;
    }
    const commissionValue =
      formik.values.commission === "" ? "0" : formik.values.commission;
    if (localStorage.getItem("ids") || params.id) {
      const { error } = await supabase
        .from(TableName.VENDOR)
        .update({
          FirmName: formik.values.firmName,
          TypeOfFrim: formik.values.firmType,
          GSTNo: formik.values.gstNumber,
          PanNo: formik.values.panNumber,
          Description: formik.values.description,
          MobileNo: formik.values.mobile,
          Email: formik.values.email,
          Website: formik.values.website,
          Fax: formik.values.fax,
          STDCodewithPhoneNo: formik.values.stdcode,
          ContactPerson: formik.values.nameofperson,
          Address: formik.values.address,
          City: formik.values.city,
          State: formik.values.state,
          Country: formik.values.country,
          Pin: formik.values.pin,
          isActive: isActive,
          commission: commissionValue,
          countryCode: countryCode,
          accountHolder: formik.values.holdername,
          accountNumber: formik.values.accountnumber,
          ifscCode: formik.values.ifsccode,
          isProfileComplete: true,
        })
        .eq("id", localStorage.getItem("ids") || params.id);
      if (!error) {
        setisLoading(false);
        if (!isDisabled) {
          showToast("update", "Vendor");
          if (isActive !== isActive1) {
            const emailmessage = {
              toemail: formik.values.email,
              template: emailtemplate.statusemail,
              emailVariables: {
                name: formik.values.firmName,
                statuscolor: isActive
                  ? emailcontent.activestatuscolor
                  : emailcontent.inactivestatuscolor,
                statusmessage: isActive
                  ? emailcontent.activestatusmessage
                  : emailcontent.inactivestatusmessage,
                statusheader: isActive
                  ? emailcontent.activestatusheader
                  : emailcontent.inactivestatusheader,
                buttonlable: isActive
                  ? emailcontent.activestatusbuttonlable
                  : emailcontent.inactivestatusbuttonlable,
                buttonlink: isActive
                  ? emailcontent.activestatusbuttonlink
                  : emailcontent.inactivestatusbuttonlink,
              },
            };
            sendestatusmail(emailmessage);
          }
          navigate(AppRoutes.VENDORLIST);
        } else {
          showModal();
          showToast("update", "Profile");
        }
      } else {
        showToast("error", "vendor");
      }
    } else {
      const { data: emailCheckData } = await supabase
        .from(TableName.VENDOR)
        .select()
        .eq("Email", formik.values.email);

      if (emailCheckData.length > 0) {
        setOpenDialog(true);
        return;
      }
      const { data: signUpData, error: signUpError } =
        await supabase.auth.signUp({
          email: formik.values.email,
          password: formik.values.mobile,

          options: {
            emailRedirectTo: `${process.env.REACT_APP_CB_ADMIN_URL}confirm`,
            data: {
              password: formik.values.mobile,
            },
          },
        });
      // console.log(
      //   "Aryan",
      //   "signUpData",
      //   signUpData,
      //   "signUpError",
      //   signUpError
      // );
      const { error: profileError } = await supabase
        .from(TableName.USERPROFILE)
        .insert({
          userUID: signUpData.user?.id,
          email: formik.values.email,
          userType: "Vendor",
        });
      const { error } = await supabase.from(TableName.VENDOR).insert([
        {
          FirmName: formik.values.firmName,
          TypeOfFrim: formik.values.firmType,
          GSTNo: formik.values.gstNumber,
          PanNo: formik.values.panNumber,
          Description: formik.values.description,
          MobileNo: formik.values.mobile,
          Email: formik.values.email,
          Website: formik.values.website,
          Fax: formik.values.fax,
          STDCodewithPhoneNo: formik.values.stdcode,
          ContactPerson: formik.values.nameofperson,
          Address: formik.values.address,
          City: formik.values.city,
          State: formik.values.state,
          Country: formik.values.country,
          Pin: formik.values.pin,
          defaultPass: true,
          isActive: isActive,
          userType: "Vendor",
          commission: commissionValue,
          countryCode: countryCode,
          accountHolder: formik.values.holdername,
          accountNumber: formik.values.accountnumber,
          ifscCode: formik.values.ifsccode,
        },
      ]);
      const wmessage = {
        broadcast_name: watiTemplateName.onboard_vendor.broadcast_name,
        template_name: watiTemplateName.onboard_vendor.template_name,
        receivers: [
          {
            whatsappNumber:
              String(formik.values.country) + String(formik.values.mobile),
            customParams: [
              {
                name: "vendor_name",
                value: formik.values.firmName,
              },
              {
                name: "user_id",
                value: formik.values.email,
              },
              {
                name: "password",
                value: formik.values.mobile,
              },
              {
                name: "website_name",
                value: websiteDetials.website_name,
              },
              {
                name: "contact_email",
                value: websiteDetials.contact_email,
              },

              {
                name: "1",
                value: websiteDetials.login_url,
              },
            ],
          },
        ],
      };
      sendTemplateMessages(wmessage);
      const emailmessage = {
        toemail: formik.values.email,
        template: emailtemplate.invitemail,
        emailVariables: {
          supportemail: websiteDetials.contact_email,
          Password: formik.values.mobile,
          username: formik.values.email,
          name: formik.values.firmName,
        },
      };
      sendemail(emailmessage);
      if (!error) {
        showToast("success", "Vendor");
        setisLoading(false);
        navigate(AppRoutes.VENDORLIST);
      } else {
        showToast("error", "vendor");
      }
    }
  };

  const params = useParams();
  useEffect(
    () => {
      const fetchVendorData = async () => {
        const vendorId = localStorage.getItem("ids") || params.id;
        const { data, error } = await supabase
          .from(TableName.VENDOR)
          .select()
          .eq("id", vendorId);

        if (error) {
          console.error("Error fetching vendor data:", error.message);
        } else {
          const vendor = data[0];
          await fetchPinCodeDetails(vendor.Pin);
          setIsActive(vendor.isActive);
          setIsActive1(vendor.isActive);
          formik.setValues({
            ...formik.values,
            firmName: vendor.FirmName,
            firmType: vendor.TypeOfFrim,
            gstNumber: vendor.GSTNo,
            panNumber: vendor.PanNo,
            description: vendor.Description,
            mobile: vendor.MobileNo,
            email: vendor.Email,
            website: vendor.Website,
            fax: vendor.Fax,
            stdcode: vendor.STDCodewithPhoneNo,
            nameofperson: vendor.ContactPerson,
            address: vendor.Address,
            city: vendor.City,
            state: vendor.State,
            country: vendor.Country,
            pin: vendor.Pin,
            commission: vendor.commission,
            holdername: vendor.accountHolder,
            accountnumber: vendor.accountNumber,
            reaccountnumber: vendor.accountNumber,
            ifsccode: vendor.ifscCode,
          });
        }
      };

      if (params.id) {
        fetchVendorData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );

  const fetchPinCodeDetails = async (pinCode) => {
    await axios
      .get(`https://api.postalpincode.in/pincode/${pinCode}`)
      .then((response) => {
        const data = response.data;
        if (
          data &&
          data.length &&
          data[0].PostOffice &&
          data[0].PostOffice.length
        ) {
          const postOffice = data[0].PostOffice[0];
          formik.setValues({
            ...formik.values,
            city: postOffice.Name || "",
            country: postOffice.Country || "",
            state: postOffice.State || "",
            pin: pinCode,
          });
          const placesForPinCode = data[0].PostOffice.map(
            (postOffice) => postOffice.Name
          );
          setPlaces(placesForPinCode);
        } else {
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };
  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const vendorId = localStorage.getItem("ids");
        setIsVendorIdPresent(!!vendorId);
        const { data, error } = await supabase
          .from("vendor")
          .select("isProfileComplete")
          .eq("id", vendorId)
          .single();

        if (error) {
          console.error("Error fetching vendor data:", error.message);
          return;
        }
        if (data) {
          setIsProfileComplete(data.isProfileComplete);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error.message);
      }
    };

    fetchVendorData();
  }, []);
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Container className="ProductContainer" sx={{ mt: -1, mb: 2 }}>
          <>
            {!isDisabled && (
              <>
                <div>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={AppRoutes.VENDORLIST}
                  >
                    <IconButton sx={{ ml: "-10px" }}>
                      <ArrowBackSharpIcon />
                    </IconButton>
                    <span
                      style={{
                        color: "rgb(17, 25, 39)",
                        fontWeight: "inherit",
                      }}
                    >
                      Vendor List
                    </span>
                  </Link>
                </div>
                <Grid container maxWidth="lg">
                  <Grid item lg={11.2} xs={12} sm={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", mb: 0.5 }}
                    >
                      {params.id
                        ? "Edit a Vendor "
                        : "  Register a new Vendor "}
                    </Typography>
                  </Grid>
                </Grid>
                <div className="links">
                  <Link
                    to="/dashboard"
                    className="crumbs"
                    underline="hover"
                    style={{
                      fontSize: "12px",
                      color: " rgb(17, 25, 39)",
                      fontWeight: "600",
                    }}
                  >
                    Dashboard
                  </Link>
                  <div className="separator"></div>
                  <Typography className="crumbs">Vendor</Typography>
                  <div className="separator"></div>
                  <Typography className="crumbs">List</Typography>
                </div>
              </>
            )}
            {isDisabled && (
              <>
                <Grid container maxWidth="lg">
                  <Grid item lg={11.2} xs={12} sm={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", mb: 0.5 }}
                    >
                      Your Profile
                    </Typography>
                  </Grid>
                </Grid>
                <div className="links">
                  Profile
                  <div className="separator"></div>
                  <Typography className="crumbs">Your Profile</Typography>
                </div>
              </>
            )}
          </>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <Grid
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                p: 4,
              }}
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid lg={4} xs={4} sm={4} md={4}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{
                    fontWeight: "400",
                    mb: 0.5,
                    wordWrap: "break-word",
                  }}
                >
                  Basic Details
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "#a3a3b8",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Please provide your basic details
                  </Typography>
                </Typography>
              </Grid>
              <Grid lg={8} xs={8} sm={8} md={8}>
                <InputLabel>
                  <Typography
                    sx={{
                      mt: "10",
                      color: "rgb(108, 115, 127);",
                      fontWeight: "500",
                    }}
                  >
                    Firm/Company
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="firmName"
                  value={formik.values.firmName}
                  onChange={(e) => {
                    const { value } = e.target;
                    const capitalizedValue =
                      value.charAt(0).toUpperCase() + value.slice(1);
                    if (capitalizedValue.length <= 50) {
                      formik.handleChange({
                        target: {
                          name: "firmName",
                          value: capitalizedValue,
                        },
                      });
                    }
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isDisabled}
                  error={
                    formik.touched.firmName && Boolean(formik.errors.firmName)
                  }
                />
                {formik.touched.firmName && formik.errors.firmName && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.firmName}
                  </span>
                )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Type of Firm
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="firmType"
                  value={formik.values.firmType}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firmType && Boolean(formik.errors.firmType)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.firmType && formik.errors.firmType && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.firmType}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    GST No.
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="gstNumber"
                  value={formik.values.gstNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 15) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.gstNumber && Boolean(formik.errors.gstNumber)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.gstNumber && formik.errors.gstNumber && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.gstNumber}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Pan No
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="panNumber"
                  value={formik.values.panNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 10) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.panNumber && Boolean(formik.errors.panNumber)
                  }
                  maxLength={6}
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.panNumber && formik.errors.panNumber && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.panNumber}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{
                      color: "rgb(108, 115, 127);",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                  >
                    Breif Description of Business of your Company
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <Stack direction=" column ">
                  <TextField
                    sx={{ width: "80%" }}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    name="description"
                    value={formik.values.description}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 5000) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    disabled={isProfileComplete && isVendorIdPresent}
                  />
                  <br />
                  {formik.touched.description && formik.errors.description && (
                    <span style={{ color: "#d32f2f" }}>
                      {formik.errors.description}
                    </span>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <br />
            <br />

            <Grid
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                p: 4,
              }}
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid lg={4} xs={4} sm={4} md={4}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{ fontWeight: "100", mb: 0.5 }}
                >
                  Contact Details
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "#a3a3b8",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Please provide your contact details
                  </Typography>
                </Typography>
              </Grid>
              <Grid lg={8} xs={8} sm={8} md={8}>
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Mobile
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flexBasis: "15%" }}>
                    <PhoneInput
                      country={"in"}
                      disabled={isDisabled}
                      inputProps={{
                        required: true,
                        readOnly: true,
                      }}
                      inputStyle={{
                        height: "40px",
                        width: "104px",
                        borderRadius: "10px 0px 0px 10px",
                      }}
                      value={countryCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ flexBasis: "79%" }}>
                    <CustomTextField
                      name="mobile"
                      style={{ borderRadius: "0px 5px 5px 0px" }}
                      value={formik.values.mobile}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                      onKeyPress={(e) => {
                        const allowedKeys = /[0-9\b]/;
                        if (!allowedKeys.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={formik.handleBlur}
                      disabled={isDisabled}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                    />
                  </div>
                </div>

                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flexBasis: "15%" }}>
                    <PhoneInput
                      country={"in"}
                      inputProps={{
                        required: true,
                        readOnly: true,
                      }}
                      inputStyle={{
                        height: "42px",
                        width: "90px",
                        borderRadius: "10px 0px 0px 10px",
                        borderLeftStyle: "none",
                        marginRight: "50px",
                      }}
                      value={countryCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ flexBasis: "85%" }}>
                    <CustomTextField
                      name="mobile"
                      // InputProps={{
                      //   startAdornment: (
                      //     // <InputAdornment position="start">+91 |</InputAdornment>

                      //   ),
                      // }}
                      value={formik.values.mobile}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                      onKeyPress={(e) => {
                        const allowedKeys = /[0-9\b]/;
                        if (!allowedKeys.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                    />
                  </div>
                </div> */}

                {formik.touched.mobile && formik.errors.mobile && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.mobile}
                  </span>
                )}
                {formik.touched.mobile &&
                  formik.values.mobile &&
                  formik.values.mobile.length < 10 && (
                    <span style={{ color: "#d32f2f" }}>
                      Mobile number must be at least 10 digits long
                    </span>
                  )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    E-mail
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isDisabled}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.email}
                  </span>
                )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Website
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="website"
                  value={formik.values.website}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.website && formik.errors.website && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.website}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Fax
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="fax"
                  value={formik.values.fax}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fax && Boolean(formik.errors.fax)}
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.fax && formik.errors.fax && (
                  <span style={{ color: "#d32f2f" }}>{formik.errors.fax}</span>
                )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    STD Code with Phone No.
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="stdcode"
                  value={formik.values.stdcode}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.stdcode && Boolean(formik.errors.stdcode)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.stdcode && formik.errors.stdcode && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.stdcode}
                  </span>
                )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Name of Contact Person
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="nameofperson"
                  value={formik.values.nameofperson}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 20) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.nameofperson &&
                    Boolean(formik.errors.nameofperson)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.nameofperson && formik.errors.nameofperson && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.nameofperson}
                  </span>
                )}
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                p: 4,
              }}
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid lg={4} xs={4} sm={4} md={4}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{ fontWeight: "100", mb: 0.5 }}
                >
                  Bank Details
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "#a3a3b8",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Please provide your bank details
                  </Typography>
                </Typography>
              </Grid>
              <Grid lg={8} xs={8} sm={8} md={8}>
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Account Holder Name
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="holdername"
                  value={formik.values.holdername}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newValue = value.replace(/[0-9]/g, "");
                    formik.handleChange({
                      target: {
                        name: "holdername",
                        value: newValue,
                      },
                    });
                  }}
                  error={
                    formik.touched.holdername &&
                    Boolean(formik.errors.holdername)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.holdername && formik.errors.holdername && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.holdername}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Bank Account Number
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="accountnumber"
                  value={formik.values.accountnumber}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let { value } = e.target;
                    value = value.slice(0, 18);
                    // Remove all non-alphanumeric characters
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
                    formik.handleChange({
                      target: {
                        name: "accountnumber",
                        value: sanitizedValue,
                      },
                    });
                  }}
                  error={
                    formik.touched.accountnumber &&
                    Boolean(formik.errors.accountnumber)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.accountnumber &&
                  formik.errors.accountnumber && (
                    <span style={{ color: "#d32f2f" }}>
                      {formik.errors.accountnumber}
                    </span>
                  )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Re-enter Bank Account Number
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="reaccountnumber"
                  value={formik.values.reaccountnumber}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let { value } = e.target;
                    value = value.slice(0, 18);
                    // Remove all non-alphanumeric characters
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
                    formik.handleChange({
                      target: {
                        name: "reaccountnumber",
                        value: sanitizedValue,
                      },
                    });
                  }}
                  error={
                    formik.touched.reaccountnumber &&
                    Boolean(formik.errors.reaccountnumber)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.reaccountnumber &&
                  formik.errors.reaccountnumber && (
                    <span style={{ color: "#d32f2f" }}>
                      {formik.errors.reaccountnumber}
                    </span>
                  )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    IFSC Code
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="ifsccode"
                  value={formik.values.ifsccode}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let { value } = e.target;
                    // Remove all non-alphanumeric characters
                    value = value.replace(/[^a-zA-Z0-9]/g, "");
                    const sanitizedValue = value.slice(0, 11);
                    formik.handleChange({
                      target: {
                        name: "ifsccode",
                        value: sanitizedValue,
                      },
                    });
                  }}
                  error={
                    formik.touched.ifsccode && Boolean(formik.errors.ifsccode)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />

                {formik.touched.ifsccode && formik.errors.ifsccode && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.ifsccode}
                  </span>
                )}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                p: 4,
              }}
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid lg={4} xs={4} sm={4} md={4}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{ fontWeight: "100", mb: 0.5, padding: "20px" }}
                >
                  Address Details
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "#a3a3b8",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Please provide your address details
                  </Typography>
                </Typography>
              </Grid>
              <Grid lg={8} xs={8} sm={8} md={8}>
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Pin
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="pin"
                  value={formik.values.pin}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^[0-9]*$/.test(value) && value.length <= 6) {
                      formik.handleChange(e);
                      if (e.target.value.length === 6) {
                        fetchPinCodeDetails(e.target.value);
                      }
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pin && Boolean(formik.errors.pin)}
                  maxLength={6}
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.pin && formik.errors.pin && (
                  <span style={{ color: "#d32f2f" }}>{formik.errors.pin}</span>
                )}

                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    City
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    disabled={isProfileComplete && isVendorIdPresent}
                    sx={{
                      width: "80%",
                      marginBottom: "1",
                      marginTop: "0px",
                      marginRight: "-5px",
                      height: "40px",
                    }}
                  >
                    {places.map((place) => (
                      <MenuItem key={place} value={place}>
                        {place}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.city && formik.errors.city && (
                    <Typography
                      color="#d32f2f"
                      style={{
                        fontSize: "1rem",
                        marginTop: "6px",
                        marginLeft: "4px",
                      }}
                    >
                      {formik.errors.city}
                    </Typography>
                  )}
                </FormControl>
                <br />
                <br />
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    State
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="state"
                  value={formik.values.state}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!/\d/.test(value) && value.length <= 20) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.state && formik.errors.state && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.state}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Country
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="country"
                  value={formik.values.country}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!/\d/.test(value) && value.length <= 20) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.country && formik.errors.country && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.country}
                  </span>
                )}
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Address
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="address"
                  value={formik.values.address}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  disabled={isProfileComplete && isVendorIdPresent}
                />
                {formik.touched.address && formik.errors.address && (
                  <span style={{ color: "#d32f2f" }}>
                    {formik.errors.address}
                  </span>
                )}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                p: 4,
              }}
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid lg={4} xs={4} sm={4} md={4}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{ fontWeight: "100", mb: 0.5 }}
                >
                  Commission Details
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "#a3a3b8",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Please provide childbazaar Commission details
                  </Typography>
                </Typography>
              </Grid>
              <Grid lg={8} xs={8} sm={8} md={8}>
                <InputLabel>
                  <Typography
                    sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                  >
                    Commission Percentage
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="commission"
                  value={formik.values.commission}
                  onBlur={formik.handleBlur}
                  disabled={isDisabled}
                  onChange={(e) => {
                    let { value } = e.target;
                    value = value.replace(/\D/, "");
                    const numericValue = parseInt(value);
                    if (numericValue <= 100 || value === "") {
                      formik.setFieldValue("commission", value);
                    }
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "0.5px 0.5px 5px 0.5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                p: 4,
              }}
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid lg={4} xs={4} sm={4} md={4}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{
                    fontWeight: "400",
                    mb: 0.5,
                  }}
                >
                  Account Status
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      color: "#a3a3b8",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    Please provide your account status
                  </Typography>
                </Typography>
              </Grid>
              <Grid lg={8} xs={8} sm={8} md={8}>
                <FormControl fullWidth>
                  <Stack direction="row">
                    <Typography sx={{ ml: "10px", mt: "7px" }}>
                      Active
                    </Typography>
                    <Switch
                      checked={isActive}
                      onChange={handleActiveChange}
                      color="primary"
                      disabled={isDisabled}
                    />
                  </Stack>
                </FormControl>
              </Grid>
            </Grid>

            <Box mt={3} display="flex" flexDirection="row-reverse">
              <Stack direction="row" spacing={4}>
                <Link to={AppRoutes.VENDORLIST}>
                  {!isDisabled && <Button variant="contained">Cancel</Button>}
                </Link>
                {!(isProfileComplete && isVendorIdPresent) && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="primary" className="load" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                )}
              </Stack>
              <Dialog
                open={openDialog}
                aria-labelledby="email-exists-dialog-title"
              >
                <DialogTitle id="email-exists-dialog-title">
                  Email Already Exists
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    The email address you provided is already in use. Please use
                    a different email address.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="addBtn"
                    onClick={() => setOpenDialog(false)}
                    color="primary"
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={isModalOpen}>
                <DialogTitle>Details Submitted</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Details have been submitted. Please contact the
                    administrator for further updates.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={hideModal}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </form>
        </Container>
      </div>
    </>
  );
};

export default VendorRegistrationForm;
