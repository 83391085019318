import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AppRoutes } from "../constant";
import { useNavigate } from "react-router-dom";
import "../Style/Category.css";
const Header = (props) => {
  const navigate = useNavigate();
  const { linkProps, showAddButton } = props;
  return (
    <div style={{ padding: "17px", marginTop: "-10px" }}>
      <Grid container maxWidth="lg">
        <Grid item lg={11.2} xs={12} sm={6} md={6}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", mb: 0.5, ml: "-8px" }}
          >
            {props.title}
          </Typography>
        </Grid>
        {showAddButton && ( // Conditionally render the add button
          <Grid item lg={0.8} xs={12} sm={6} md={6} textAlign="right">
            <Link {...linkProps} style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
      <div className="links">
        <Link
          to="/dashboard"
          className="crumbs"
          underline="hover"
          style={{
            fontSize: "12px",
            color: " rgb(17, 25, 39)",
            fontWeight: "600",
            marginLeft: "-6px",
          }}
          onClick={() => {
            navigate(AppRoutes.DASHBOARD);
          }}
        >
          Dashboard
        </Link>
        <div className="separator"></div>
        <Typography
          sx={{
            fontSize: "12px",
            color: " rgb(108, 115, 127)",
            marginTop: "1px",
          }}
        >
          {props.subtitle}
        </Typography>
        <div className="separator"></div>
        <Typography
          sx={{
            fontSize: "12px",
            color: " rgb(108, 115, 127)",
            marginTop: "1px",
          }}
        >
          {props.subtitle1}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
