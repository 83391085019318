import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  TableContainer,
  TablePagination,
  Box,
  Divider,
  Tooltip,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Search } from "@mui/icons-material";
import useSupabase from "../../utils/useSupabase";
import SelectedFilter from ".././SelectedFilter";
import CommonFilter from ".././CommonFilter";
import { TableName, emailtemplate } from "../../constant";
import Header from ".././Header";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
const Refund = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [Refund, setRefund] = useState([]);
  const [open, setopen] = useState(false);
  const [RefundData, setRefundData] = useState([]);
  const [statusheader, setstatusheader] = useState();
  const [refundstatus, setrefundstatus] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [sizeList, setSizeList] = useState({});
  const supabase = useSupabase();
  const getListData = async () => {
    const { data } = await supabase
      .from("list")
      .select("listItem, listDescription")
      .eq("listName", "Size")
      .eq("IsActive", "Yes");
    if (data) {
      const sizeData = {};
      data.forEach((item) => {
        sizeData[item.listItem] = item.listDescription;
      });
      setSizeList(sizeData);
    }
  };

  useEffect(() => {
    getListData();
  }, []);
  useEffect(
    () => {
      fetchRefundData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  async function fetchRefundData(newPage, searchKey) {
    try {
      let query = supabase
        .from(TableName.RETURN)
        .select("*", { count: "exact" })
        .order("id", { ascending: false });
      if (searchKey.trim() !== "") {
        query = query.eq("id", `${searchKey}`);
      }
      query = query.order("id");
      if (filter.length > 0) {
        const filterCodes = filter.map((item) => item.code);
        query = query.in("returnStatus", filterCodes);
      }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);

      setRefund(data || []);
      setTotalItems(count || 0);

      if (error) {
        console.error("Error fetching categories:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }
  console.log("s", Refund);

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm === "") {
      setPage(0);
    }
    setTimeout(() => {
      fetchRefundData(0, event.target.value);
    }, 500);
  };
  const handleChangePage = (event, newPage) => {
    fetchRefundData(newPage, searchTerm);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };

  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };

  const openrefund = async (Refund) => {
    setisloading(false);
    //   navigate(AppRoutes.RefundDetials, {
    //     state: Refund,
    //   });
    setrefundstatus(false);
    const { data } = await supabase
      .from(TableName.ORDERLINE)
      .select("*")
      .eq("orderLineID", Refund.orderLineID);
    const { data: order } = await supabase
      .from(TableName.ORDER)
      .select("*")
      .eq("orderId", data[0].orderId);
    const { data: transaction } = await supabase
      .from(TableName.TRANSACTION)
      .select("*")
      .eq("orderId", order[0].orderId);
    if (transaction.length !== 0) {
      if (transaction[0].paymentId) {
        if (
          Refund.returnStatus === "refund initiated" ||
          Refund.returnStatus === "refunded"
        ) {
          setstatusheader(" Refund already processed");
          setrefundstatus(true);
        } else {
          setRefundData({
            paymentId: transaction[0].paymentId,
            amount: data[0].productPrice * data[0].quantity,
            orderLineID: data[0].orderLineID,
            created_at: data[0].created_at,
            orderId: Date.now(),
            userUID: transaction[0].userUID,
            quantity: data[0].quantity,
            returnid: Refund.id,
          });
        }
      } else {
        setRefundData({
          paymentId: transaction[0].paymentId,
          amount: data[0].productPrice * data[0].quantity,
          orderLineID: data[0].orderLineID,
          created_at: data[0].created_at,
          orderId: Date.now(),
          userUID: transaction[0].userUID,
          quantity: data[0].quantity,
          returnid: Refund.id,
          paymentMethod: transaction[0].paymentMethod,
        });
        // setstatusheader("No refund available");
        // setrefundstatus(true);
      }
    } else {
      setstatusheader("No refund available");
      setrefundstatus(true);
    }
    setopen(true);
  };

  const sendemail = async (emaildata) => {
    const email = await fetch(
      `${process.env.REACT_APP_CB_URL}api/email`, //
      {
        //
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emaildata), // body data type must match "Content-Type" header
      }
    );
  };
  const refunds = async (data) => {
    setisloading(true);
    const { data: userdata, error: userdataerror } = await supabase
      .from(TableName.USERPROFILE)
      .select("*")
      .eq("userUID", data.userUID);
    if (data.paymentMethod === "cod") {
      const transaction = {
        created_at: new Date(), // Assuming you want to store the current date and time
        type: "refund",
        amount: data.amount,
        description: "",
        transactionOrigin: "online",
        userUID: data.userUID,
        paymentMethod: "api",
        quantity: data.quantity,
        status: "completed",
        orderId: data.orderLineID,
        paymentId: data.returnid,

        // Additional information can be added here as needed
      };
      const { data: Transactiondata, error: transactionerror } = await supabase
        .from(TableName.TRANSACTION)
        .insert(transaction);
      const { data: returndata, error: returnerror } = await supabase
        .from(TableName.RETURN)
        .update({ returnStatus: "refunded" })
        .eq("id", data.returnid);
      const { data: orderline, error: orderlineerror } = await supabase
        .from(TableName.ORDERLINE)
        .update({ orderStatus: "refunded" })
        .eq("orderLineID", data.orderLineID)
        .select("*");
      const emaildata = {
        email: userdata[0].email,
        message: "Refund Initiated",
        content: {
          template: emailtemplate.refundemail,
          emailVariables: {
            records: orderline,
            shipping: 0,
            name: userdata[0].name,
            total: orderline[0].productPrice,
            quantiy: orderline[0].quantity,
            orderid: orderline[0].orderId,
            sizeList: sizeList,
            productPrice: orderline[0].productPrice,
          },
        },
      };
      sendemail(emaildata);
      fetchRefundData(page, searchTerm);
      setrefundstatus(true);
      setstatusheader("Refunded successfully");
      setisloading(false);
    } else {
      const value = await fetch(`${process.env.REACT_APP_CB_URL}api/refund`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentId: data.paymentId,
          taxAmt: data.amount * 100,
        }),
      }).then(async (data) => {
        return await data.json();
      });
      const transaction = {
        created_at: new Date(), // Assuming you want to store the current date and time
        type: "refund",
        amount: data.amount,
        description: "",
        transactionOrigin: "online",
        userUID: data.userUID,
        paymentMethod: "api",
        quantity: data.quantity,
        status: "completed",
        orderId: data.orderLineID,
        paymentId: value.id,

        // Additional information can be added here as needed
      };
      if (value.statusCode !== 400) {
        const { data: Transactiondata, error: transactionerror } =
          await supabase.from(TableName.TRANSACTION).insert(transaction);

        const { data: returndata, error: returnerror } = await supabase
          .from(TableName.RETURN)
          .update({ returnStatus: "refunded " })
          .eq("id", data.returnid);
        const { data: orderline, error: orderlineerror } = await supabase
          .from(TableName.ORDERLINE)
          .update({ orderStatus: "refunded" })
          .eq("orderLineID", data.orderLineID)
          .select("*");
        fetchRefundData(page, searchTerm);
        setrefundstatus(true);
        setstatusheader("Refunded successfully");
        setisloading(false);
        const emaildata = {
          email: userdata[0].email,
          message: "Refund Initiated",
          content: {
            template: emailtemplate.refundemail,
            emailVariables: {
              records: orderline,
              shipping: 0,
              name: userdata[0].name,
              total: orderline[0].productPrice,
              quantiy: orderline[0].quantity,
              orderid: orderline[0].orderId,
              sizeList: sizeList,
              productPrice: orderline[0].productPrice,
            },
          },
        };
        sendemail(emaildata);
      } else {
        setrefundstatus(true);
        setstatusheader("Refund failed");
        setisloading(false);
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Header
          title="Refund"
          subtitle="Refund"
          subtitle1="List"
          showAddButton={false}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ p: "7px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by Id"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <CommonFilter
            id="status"
            title="Status"
            options={[
              { code: "Return Accepted", value: "Return Accepted" },
              { code: "Return Rejected", value: "Return Rejected" },
              { code: "Return Initiated", value: "Return Initiated" },
              { code: "refunded", value: "Refunded" },
              { code: "Cancelled", value: "Cancelled" },
              { code: "Rejected", value: "Rejected" },
            ]}
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
        </div>

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Id
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Order ID
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Reason
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Return Status
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {Refund.filter((Refund) => { 
                     const categoryName = category.name.toLowerCase();
                     const searchTermLower = searchTerm.toLowerCase();
                     return categoryName.includes(searchTermLower);
                }) */}
                {/* .slice(page * rowsPerPage, (page + 1) * rowsPerPage) */}
                {Refund.map((Refund, id) => (
                  <TableRow key={id}>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {Refund.id}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {Refund.orderId}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",

                        wordWrap: "break-word",
                        maxWidth: "100px",
                      }}
                    >
                      <Tooltip title={Refund.reason}>{Refund.reason}</Tooltip>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {Refund.returnStatus}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      <Button
                        disabled={
                          Refund.returnStatus === "Cancelled" ||
                          Refund.returnStatus === "Return Accepted" ||
                          Refund.returnStatus === "Rejected"
                            ? false
                            : true
                        }
                        onClick={async () => {
                          openrefund(Refund);
                        }}
                        variant="outlined"
                      >
                        Refund
                      </Button>
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        <Link
                          to={{
                            pathname: `${AppRoutes.CATEGORY}/${category.id}`,
                            state: { category: category },
                          }}
                        >
                          <IconButton variant="contained">
                            <EditOutlinedIcon />
                          </IconButton>
                        </Link>
                      </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
      <Dialog
        open={open}
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {refundstatus ? (
          <>
            <DialogTitle>{statusheader}</DialogTitle>

            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setopen(false);
                }}
              >
                ok
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            {isloading ? (
              <DialogContent>
                <CircularProgress
                  size={100}
                  style={{
                    color: "#1565c0",
                  }}
                />
              </DialogContent>
            ) : (
              <>
                <DialogTitle>Are you sure want to refund ?</DialogTitle>
                <DialogContent>
                  {RefundData && (
                    <>
                      {/* <Typography variant="body1">
                <strong>Order ID:</strong> {RefundData.orderLineID}
              </Typography>
              <Typography variant="body1">
                <strong>Date:</strong> {RefundData.created_at}
              </Typography> */}
                      <Typography variant="body1">
                        <strong>Amount:</strong> {RefundData.amount}
                      </Typography>
                      {/* <Typography variant="body1">
                <strong>Description:</strong> {RefundData.description}
              </Typography> */}
                    </>
                  )}
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setopen(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      refunds(RefundData);
                    }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Refund;
