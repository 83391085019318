
import React from "react";
import "./Dashboard.css";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PendingIcon from "@mui/icons-material/Pending";
import { useState, useEffect } from "react";
import useSupabase from "./utils/useSupabase";
import { TableName } from "./constant";
import Button from "@mui/material/Button";

function Dashboard() {
  const supabase = useSupabase();
  const [orders, setOrders] = useState(null);
  const [delivered, setDelivered] = useState(null);
  const [Pending, setPending] = useState(null);
  const [popular, setPopular] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchVendorProducts();
    fetchpopular();
    fetchOrders();
  }, []);


  const fetchOrders = async () => {
    try {
      const totalQuery = supabase
        .from(TableName.ORDER)
        .select("orderId", { count: 'exact', head: true });

      const deliveredQuery = supabase
        .from(TableName.ORDER)
        .select("orderId", { count: "exact" , head: true})
        .eq("orderStatus", "Delivered");

      const pendingQuery = supabase
        .from(TableName.ORDER)
        .select("orderId", { count: "exact", head: true })
        .eq("orderStatus", "Pending");

      const { count: totalCount, error: totalError } = await totalQuery;
      const { count: deliveredCount, error: deliveredError } =
        await deliveredQuery;
      const { count: pendingCount, error: pendingError } = await pendingQuery;

      setOrders(totalCount || 0);
      setDelivered(deliveredCount || 0);
      setPending(pendingCount || 0);

      if (totalError) {
        console.error("Error fetching total order count:", totalError.message);
      }

      if (deliveredError) {
        console.error(
          "Error fetching delivered order count:",
          deliveredError.message
        );
      }

      if (pendingError) {
        console.error(
          "Error fetching pending order count:",
          pendingError.message
        );
      }
    } catch (error) {
      console.error("Error fetching order counts:", error.message);
    }
  };

  const fetchVendorProducts = async (currentPage) => {
    try {
      let query = supabase
        .from(TableName.VENDOR)
        .select("*", { count: "exact" })
        .not("totalOrder", "is", null)
        .order("totalOrder", { ascending: false });

      const { data, count } = await query
        .range(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      // Calculate total rating, rating count, review count, and total products for each vendor
      const vendorsWithRatingInfo = await Promise.all(
        data.map(async (vendor) => {
          const { data: productsData, error: productsError } = await supabase
            .from("product")
            .select("*")
            .eq("vendorId", vendor.id);

          if (productsError) {
            throw productsError;
          }

          // Calculate the total number of products for the vendor
          const totalProducts = productsData.length;

          // Sum up the ratings of all products for the vendor
          const totalRating = productsData.reduce(
            (acc, product) => acc + (product.totalRating || 0), // Handle potential NaN values
            0
          );

          // Calculate the total rating count and review count for the vendor
          const ratingCount = productsData.reduce(
            (acc, product) => acc + (product.ratingCount || 0), // Handle potential NaN values
            0
          );

          const reviewCount = productsData.reduce(
            (acc, product) => acc + (product.reviewCount || 0), // Handle potential NaN values
            0
          );

          // Update the vendor's totalRating, ratingCount, reviewCount, and totalProducts in the vendor table
          await supabase
            .from(TableName.VENDOR)
            .update({ totalRating, ratingCount, reviewCount })
            .eq("id", vendor.id);

          // Return the updated vendor object with rating info
          return {
            ...vendor,
            totalRating,
            ratingCount,
            reviewCount,
            totalProducts,
          };
        })
      );
      setTotalCount(count || 0);
      // Set the vendors state with rating info
      setVendors(vendorsWithRatingInfo);
    } catch (error) {
      console.error("Error fetching vendor products:", error.message);
    }
  };


  const fetchpopular = async () => {
    try {
      const { data: popproduct } = await supabase.rpc("list_product", {
        brand: null,
        cat: null,
        colors: null,
        pagenumber: null,
        pagesize: 5,
        prices: null,
        sizes: null,
        subcat: null,
        tag: null,
        isfeatured: null,
        locations: null,
      });

      setPopular(popproduct);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  const handlePrevPage = async () => {
    if (page > 0) {
      setPage(page - 1);
      await fetchVendorProducts(page - 1);
    }
  };

  const handleNextPage = async () => {
    if ((page + 1) * rowsPerPage < totalCount) {
      setPage(page + 1);
      await fetchVendorProducts(page + 1); 
    }
  };

  return (
    <>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <Card sx={{ width: 300, height: 150 }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "40px", color: "#851841", margin: 0 }}>
                  {orders}
                </p>
                <LocalMallIcon style={{ color: "#851841" }} />
              </div>
              <div style={{ marginTop: "30px" }}>
                <p style={{ margin: 0, fontSize: "20px" }}>
                  <b>Total Orders</b>
                </p>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ width: 300, height: 150 }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "40px", color: "#851841", margin: 0 }}>
                  {delivered}
                </p>
                <LocalShippingIcon style={{ color: "#851841" }} />
              </div>
              <div style={{ marginTop: "30px" }}>
                <p style={{ margin: 0, fontSize: "20px" }}>
                  <b>Delivered Orders</b>
                </p>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ width: 300, height: 150 }}>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "40px", color: "#851841", margin: 0 }}>
                  {Pending}
                </p>
                <PendingIcon style={{ color: "#851841" }} />
              </div>
              <div style={{ marginTop: "30px" }}>
                <p style={{ margin: 0, fontSize: "20px" }}>
                  <b>Pending Orders</b>
                </p>
              </div>
            </div>
          </Card>
        </Grid>

        <>
          <Grid item xs={12} sm={6}>
            <Card>
              <div style={{ padding: "20px", height: "380px" }}>
                <b>
                  <p style={{ color: "#808080", fontSize: "20px" }}>
                    Top Vendors
                  </p>
                </b>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#808080",
                  }}
                >
                  <b style={{ flex: "1" }}>Shop</b>
                  <b style={{ flex: "1", textAlign: "center" }}>Products</b>
                  <b style={{ flex: "1", textAlign: "end" }}>Ratings</b>
                </div>

                {vendors &&
                  vendors.map((vendor) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#808080",
                        marginBottom: "8px",
                        width: "100%",
                      }}
                    >
                      <p style={{ color: "#808080", width: "40%" }}>
                        {vendor.FirmName}
                      </p>

                      <p style={{ flex: "1", width: "40%" }}>
                        {vendor.totalProducts} Products
                      </p>

                      {vendor.ratingCount > 0 ? (
                        <div
                          style={{
                            flex: "1",
                            textAlign: "end",
                            display: "flex",
                            justifyContent: "end",
                            width: "20%",
                          }}
                        >
                          <p className="flex gap-1 bg-tertiary px-2 py-0.5 text-sm rounded-[50px] text-white">
                            {Number(
                              vendor.totalRating / vendor.ratingCount
                            ).toFixed(1)}
                          </p>
                          <p className="text-gray-500">
                            ({vendor.ratingCount})
                          </p>
                        </div>
                      ) : (
                        <p className="pt-8">No ratings yet</p>
                      )}
                    </div>
                  ))}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  disabled={page === 0}
                  onClick={handlePrevPage}
                  sx={{
                    color: "#851841",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Prev
                </Button>
                <span
                  style={{
                    color: "#851841",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {page + 1}
                </span>
                <Button
                  disabled={(page + 1) * rowsPerPage >= totalCount}
                  onClick={handleNextPage}
                  sx={{
                    color: "#851841",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Next
                </Button>
              </div>
            </Card>
          </Grid>
        </>

        <Grid item xs={6}>
          <Card>
            <div style={{ padding: "20px" }}>
              <b>
                <p style={{ color: "#808080", fontSize: "20px" }}>
                  Top Products
                </p>
              </b>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#808080",
                }}
              >
                <b>
                  {" "}
                  <p
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Products
                  </p>
                </b>
                <b>
                  {" "}
                  <p
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Categories
                  </p>
                </b>
                <b>
                  <p
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    Ratings
                  </p>
                </b>
              </div>
              {popular.map((popularproduct) => (
                <div
                  style={{
                    display: "flex",
                    color: "#808080",
                  }}
                >
                  <img
                  alt=""
                    src={JSON.parse(popularproduct.productimage)[0]}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                      marginTop: "20px",
                    }}
                  />
                  <p style={{ width: "50%", color: "#808080" }}>
                    {popularproduct.productname}{" "}
                    <p> {popularproduct.vendorname}</p>
                    <p> {popularproduct.ispopular} Purchase</p>
                  </p>
                  <p
                    style={{
                      width: "40%",
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "20px",
                    }}
                  >
                    <b> {popularproduct.categorycode}</b>
                  </p>
                  <p
                    style={{
                      width: "40%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    {popularproduct.totalrating &&
                    popularproduct.ratingcount ? (
                      <div
                        style={{
                          flex: "1",
                          textAlign: "end",
                          display: "flex",
                          justifyContent: "end",
                          width: "20%",
                        }}
                        className="flex gap-1 p-1"
                      >
                        <p className="flex gap-1 bg-tertiary px-2 py-0.5 text-sm rounded-[50px] text-white">
                          {Number(
                            popularproduct.totalrating /
                              popularproduct.ratingcount
                          ).toFixed(1)}
                        </p>
                        <p className=" text-gray-500 ">
                          ({popularproduct.ratingcount})
                        </p>
                      </div>
                    ) : (
                      <p className="pt-8">No ratings yet</p>
                    )}
                  </p>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
