import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import useSupabase from "../../utils/useSupabase";
import { useLocation } from "react-router";
import { AppRoutes, TableName } from "../../constant";
import Header from "../Header";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Search } from "@mui/icons-material";
import CommonFilter from "../CommonFilter";
import SelectedFilter from "../SelectedFilter";
import { getListData } from "../../utils/helperFunction";

function TransactionDetailsPage() {
  const [transactionData, setTransactionData] = useState(null);
  const location = useLocation();
  const supabase = useSupabase();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [sizeList, setSizeList] = useState({});

  const [isrejectDialogOpen, setrejectDialogOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [filter, setFilter] = useState([]);
  const [orderline, setOrderline] = useState([]);
  const [page, setPage] = useState(0);
  const [disabledActions, setDisabledActions] = useState({});
  const [status, setStatus] = useState("");
  const openDialog = (orderId) => {
    setSelectedOrderId(orderId);
    setDialogOpen(true);
  };
  const rowsPerPage = 50;
  const Id = location.state.orderId;
  const fetchOrders = async (Id) => {
    try {
      const { data: orderLines, error: orderLinesError } = await supabase
        .from(TableName.ORDERLINE)
        .select()
        .eq("orderId", Id);

      if (orderLinesError) {
        console.error("Error fetching OrderLines:", orderLinesError);
        return;
      }

      if (orderLines.length === 0) {
        console.error("No OrderLines found for the specified Order Id.");
        return;
      }

      const uniqueStatuses = Array.from(
        new Set(orderLines.map((line) => line.orderStatus))
      );

      let updatedOrderStatus;
      if (uniqueStatuses.length === 1) {
        updatedOrderStatus = uniqueStatuses[0];
      } else {
        updatedOrderStatus = "Confirmed";
      }

      const { error: orderError } = await supabase
        .from(TableName.ORDER)
        .update({ orderStatus: updatedOrderStatus })
        .eq("orderId", Id);
      if (orderError) {
        console.error("Error updating Order status:", orderError);
        return;
      }

      setOrderline(orderLines);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const Refund = async (data) => {
    // axios.post("http://localhost:3000/api/refund", {
    //   paymentId: data.paymentId,
    //   taxAmt: data.amount,
    // });
    const value = await fetch("http://localhost:3000/api/refund", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Content-Length": contentLength.toString(),
        //Origin: "http://localhost:3001", // Replace this with the actual origin of your request
      },
      body: JSON.stringify({
        paymentId: data.paymentId,
        taxAmt: data.amount,
      }),
    });
    console.log(value.status);
  };
  const fetchOrders1 = async (Id) => {
    try {
      const { data: orderLines, error: orderLinesError } = await supabase
        .from(TableName.ORDERLINE)
        .select()
        .eq("orderLineID", Id);

      if (orderLinesError) {
        console.error("Error fetching OrderLines:", orderLinesError);
        return;
      }

      if (orderLines.length === 0) {
        console.error("No OrderLines found for the specified Order Id.");
        return;
      }

      const uniqueStatuses = Array.from(
        new Set(orderLines.map((line) => line.orderStatus))
      );

      setOrderline(orderLines);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  useEffect(() => {
    // Fetch data from API
    setTransactionData(location.state);
    if (location.state.paymentMethod === "api") {
      fetchOrders1(location.state.orderId);
    } else {
      fetchOrders(location.state.orderId);
    }
  }, []);
  useEffect(() => {
    if (location.state.paymentMethod === "api") {
      fetchOrders1(location.state.orderId);
    } else {
      fetchOrders(location.state.orderId);
    }
  }, [filter, selectedOrderId]);
  const getSizeList = async () => {
    const sizes = await getListData("Size");
    if (sizes) {
      const sizeData = {};
      sizes.forEach((item) => {
        sizeData[item.listItem] = item.listDescription;
      });
      setSizeList(sizeData);
    }
  };
  useEffect(
    () => {
      getSizeList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Link to={AppRoutes.Transaction}>
          <IconButton>
            <ArrowBackSharpIcon />
          </IconButton>
        </Link>
        <Typography
          variant="h6"
          component="span"
          color="textPrimary"
          fontWeight="inherit"
        >
          Transaction
        </Typography>

        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
          spacing={70}
        >
          <Header
            title="Details"
            subtitle="Details"
            subtitle1="List"
            showAddButton={false}
          />
          <div>
            {transactionData && (
              <>
                <Stack direction="row" spacing={1}>
                  <Typography style={{ fontWeight: 700 }}>
                    Transaction ID
                  </Typography>{" "}
                  <Typography> : {transactionData.transactionId}</Typography>
                </Stack>
                <Stack direction="row" spacing={10}>
                  <Typography style={{ fontWeight: 700 }}>Date </Typography>{" "}
                  <Typography>
                    : {dayjs(transactionData.created_at).format("YYYY/MM/DD ")}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={9.5}>
                  <Typography style={{ fontWeight: 700 }}>Time </Typography>{" "}
                  <Typography>
                    : {dayjs(transactionData.created_at).format("HH:mm ")}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={7}>
                  <Typography style={{ fontWeight: 700 }}>Amount </Typography>{" "}
                  <Typography> : {transactionData.amount}</Typography>
                </Stack>
              </>
            )}
          </div>
        </Stack>
        <br />
        <br />
        <div className="topContent">
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>

          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Product
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Color
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Size
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Current status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderline &&
                  orderline
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell
                          align="center"
                          className="cell"
                          sx={{ width: "350px" }}
                        >
                          {item.productName}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {item.color}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {sizeList[item.size] || item.size}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {item.quantity}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {item.productPrice * item.quantity}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {item.orderStatus}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </div>
  );
}

export default TransactionDetailsPage;
