import * as React from "react";
import * as Yup from "yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { TextField, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useContext, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./SignIn.css";
import ChangePassword from "./component/ChangePassword";
import { Cartcontext } from "./Products/Context";
import { useFormik } from "formik";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useSupabase from "./utils/useSupabase";
import { AppRoutes } from "./constant";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { TableName } from "./constant";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";

const defaultTheme = createTheme();

export default function SignIn() {
  const GlobalState = useContext(Cartcontext);
  const message = GlobalState.message;
  const [Password, setPassword] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [isLoading, setisLoading] = useState(false);
  const handleClickPassword = () => setPassword((show) => !show);
  const supabase = useSupabase();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .test(
          "starts-with-number",
          "Email cannot start with a number",
          (value) => {
            return !/^\d/.test(value);
          }
        )
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setisLoading(true);
      const { email, password } = values;
      const { data: userProfile, error: userError } = await supabase
        .from(TableName.USERPROFILE)
        .select()
        .eq("email", email)
        .single();

      if (
        userError ||
        (userProfile.userType !== "admin" && userProfile.userType !== "Vendor")
      ) {
        showToast("error", "Invalid email or password.");
        setisLoading(false);
      } else {
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        });

        if (signInError) {
          console.error("Sign-in error:", signInError);
          showToast("error", signInError.message);
          setisLoading(false);
        } else {
          const { data } = await supabase
            .from(TableName.VENDOR)
            .select()
            .eq("Email", email)
            .single();

          if (userProfile.userType === "admin") {
            GlobalState.setUser(email ?? null);
            localStorage.setItem("adminAccessToken", supabase.supabaseKey);
            sessionStorage.setItem("userType", true);
            GlobalState.setUserRole(true);
            setisLoading(false);
            navigate(AppRoutes.DASHBOARD, {
              state: email,
            });
            localStorage.setItem("adminEmail", email);
          } else if (userProfile.userType === "Vendor") {
            if (data && data.countryCode) {
              // Update user profile with the country code
              const { error: updateProfileError } = await supabase
                .from(TableName.USERPROFILE)
                .update({ countryCode: data.countryCode })
                .eq("email", email);

              if (updateProfileError) {
                showToast(
                  "error",
                  "Error updating user profile with country code."
                );
              }
            }
            if (data.isProfileComplete && !data.isActive) {
              showToast("error", "Please reach out to your administrator");
              setisLoading(false);
              return;
            }

            GlobalState.setUserRole(false);
            sessionStorage.setItem("userType", false);
            localStorage.setItem("ids", data.id);
            localStorage.setItem("accessToken", supabase.supabaseKey);

            if (data.defaultPass) {
              openChangePasswordModal();
              return;
            } else {
              GlobalState.setUser(email ?? null);
              setisLoading(false);
              localStorage.setItem("vendorEmail", email);
              if (data.isProfileComplete && data.isActive) {
                navigate(AppRoutes.VENDORDASHBOARD);
              } else {
                navigate(AppRoutes.UPDATEPROFILE);
              }
            }
          } else {
            showToast("error", "Please reach out to your administrator");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("adminAccessToken");
            setisLoading(false);
            return;
          }
        }
      }
    },
  });
  const openChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const updatePassword = async (newPassword, email) => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) {
        showToast("error", "Error updating password.");
      } else {
        showToast("success", "Password updated successfully!");
        setChangePasswordModalOpen(false);

        const { error: updateError } = await supabase
          .from(TableName.VENDOR)
          .update({ defaultPass: false })
          .eq("Email", email);

        if (updateError) {
          showToast("error", "Error updating defaultPass in vendor.");
        } else {
          console.log("defaultPass updated to false");
          GlobalState.setUser(email ?? null);
          GlobalState.setUserRole(false);
          navigate(AppRoutes.UPDATEPROFILE);
        }
      }
    } catch (error) {
      showToast("error", "An error occurred while updating the password.");
    }
  };
  const showToast = (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      default:
        toast(message);
        break;
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ pb: 3 }}>
            Sign in
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    variant="outlined"
                    label="Email *"
                    fullWidth
                    placeholder="your email "
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    sx={{ "& .MuiFormHelperText-root": { ml: 0 } }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="txtup"
                    name="password"
                    fullWidth
                    label="Password *"
                    placeholder="your password "
                    type={Password ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <span>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickPassword}
                              sx={{
                                color:
                                  formik.touched.password &&
                                  formik.errors.password
                                    ? "red"
                                    : "#424242",
                              }}
                            >
                              {Password ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ "& .MuiFormHelperText-root": { ml: 0 } }}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    value={formik.values.password}
                  />
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel control={<Checkbox />} label="Remember Me" />
                <p
                  style={{
                    fontSize: "0.875rem", // Equivalent to text-sm in Tailwind CSS
                    color: "#3b82f6", // Equivalent to text-blue-500 in Tailwind CSS
                    cursor: "pointer",
                  }}
                  className=" "
                  onClick={() => {
                    navigate(AppRoutes.Forgotpassword);
                  }}
                >
                  Forgot password?
                </p>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="primary" className="loader" />
                ) : (
                  "Sign in"
                )}
              </Button>
              {message && <p className="signinp">{message}</p>}
              <Grid container justifyContent="flex-end">
                <Grid item></Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
      <ChangePassword
        open={isChangePasswordModalOpen}
        handleModalClose={() => setChangePasswordModalOpen(false)}
        updatePassword={updatePassword}
        email={formik.values.email}
      />
    </ThemeProvider>
  );
}
