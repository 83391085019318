import { Container } from "@mui/material";
import React from "react";

import { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Divider,
} from "@mui/material";
import SelectedFilter from "../SelectedFilter";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CommonFilter from "../CommonFilter";
import useSupabase from "../../utils/useSupabase";
import { useLocation } from "react-router";
import {
  TableName,
  emailcontent,
  emailtemplate,
  watiTemplateName,
} from "../../constant";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import VendorSubHeader from "../../VendorSubHeader";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { sendTemplateMessages } from "../../Services/wati";
import Select from "@mui/material/Select";
import { getListData } from "../../utils/helperFunction";
import Tooltip from "@mui/material/Tooltip";

const Orderline = () => {
  const supabase = useSupabase();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [sizeList, setSizeList] = useState({});

  const [isrejectDialogOpen, setrejectDialogOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [filter, setFilter] = useState([]);
  const [orderline, setOrderline] = useState([]);
  const [filterorderline, setFilterOrderline] = useState([]);
  const [disabledActions, setDisabledActions] = useState({});
  const [status, setStatus] = useState("");
  const [orderLineStatus, setOrderLineStatus] = useState("");

  const location = useLocation();
  const Id = location.state.orderId;
  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    // Adding leading zeros if needed
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
  }
  const sendemail = async (emaildata) => {
    await fetch(
      `${process.env.REACT_APP_CB_URL}api/email`, //
      {
        //
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emaildata), // body data type must match "Content-Type" header
      }
    );
  };
  const getCurrentDate = () => {
    var currentDate = new Date();

    // Get the current date
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1; // Months are zero indexed, so we add 1
    var year = currentDate.getFullYear();
    console.log("Current Date: " + day + "/" + month + "/" + year);
    return day + "/" + month + "/" + year;
    // Display the current date
  };
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const openDialog = async (orderId) => {
    try {
      const { data, error } = await supabase
        .from(TableName.ORDERLINE)
        .select("orderStatus")
        .eq("orderLineID", orderId);

      if (error) {
        console.error("Error fetching order status:", error.message);
        return;
      }

      if (data && data.length > 0) {
        const orderStatus = data[0].orderStatus;
        setSelectedOrderId(orderId);
        setDialogOpen(true);
        setOrderLineStatus(data[0].orderStatus);
      } else {
        console.error("No data found for order ID:", orderId);
      }
    } catch (error) {
      console.error("Error fetching order status:", error.message);
    }
  };

  const closeDialog = () => {
    setStatus("");
    setDialogOpen(false);
    setrejectDialogOpen(false);
  };

  const fetchOrders = async (Id) => {
    try {
      const vendorid = localStorage.getItem("ids");
      const { data: orderLines, error: orderLinesError } = await supabase
        .from(TableName.ORDERLINE)
        .select()
        .eq("orderId", Id)
        .eq("vendorId", vendorid);

      if (orderLinesError) {
        console.error("Error fetching OrderLines:", orderLinesError);
        return;
      }

      if (orderLines.length === 0) {
        console.error("No OrderLines found for the specified Order Id.");
        return;
      }

      const uniqueStatuses = Array.from(
        new Set(orderLines.map((line) => line.orderStatus))
      );

      let updatedOrderStatus;
      if (uniqueStatuses.length === 1) {
        updatedOrderStatus = uniqueStatuses[0];
      } else {
        updatedOrderStatus = "Confirmed";
      }

      const { data: orderData, error: orderError } = await supabase
        .from(TableName.ORDER)
        .update({ orderStatus: updatedOrderStatus })
        .eq("orderId", Id);
      if (orderError) {
        console.error("Error updating Order status:", orderError);
        return;
      }
      setOrderline(orderLines);
      if (filter[0].value === "All") {
        setFilterOrderline(orderLines);
      } else {
        handleFilterChange(filter);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleFilterChange = (selectedItems) => {
    const getfill = selectedItems.map((d) => {
      return d.value;
    });
    const datafill = getfill.join(",");
    if (selectedItems.length === 0) {
      setFilterOrderline(orderline);
      setFilter([{ code: "All", id: "order", title: "Status", value: "All" }]);
      //setFilter([])
    } else {
      setFilter(selectedItems);
      if (datafill.includes("All")) {
        setFilterOrderline(orderline);
      } else {
        let dataFilter = [];
        selectedItems.map((item) => {
          orderline.filter((data) => {
            if (data.orderStatus === item.value) {
              dataFilter.push(data);
            }
          });
        });
        setFilterOrderline(dataFilter);
      }
    }
  };

  useEffect(
    () => {
      if (filter.length > 0) {
        fetchOrders(Id);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, selectedOrderId]
  );

  const handleConfirmAction = async () => {
    if (!status) {
      closeDialog();
      return;
    }
    const { data } = await supabase
      .from(TableName.ORDERLINE)
      .update({ orderStatus: status })
      .select("*")
      .eq("orderLineID", selectedOrderId);
    const { data: orderdata } = await supabase
      .from(TableName.ORDER)
      .select("*")
      .eq("orderId", data[0].orderId);
    const { data: userProfile } = await supabase
      .from(TableName.USERPROFILE)
      .select("*")
      .eq("userUID", orderdata[0].userUID);
    if (status === "Rejected") {
      try {
        const returnData = orderline.filter(
          (item) => item.orderLineID === selectedOrderId
        );

        // Check if returnData exists and is not empty
        if (returnData && returnData.length > 0) {
          const { data: transactionData, error: fetchError } = await supabase
            .from(TableName.TRANSACTION)
            .select("paymentMethod")
            .eq("orderId", returnData[0].orderId)
            .single();

          if (fetchError) {
            throw fetchError;
          }

          // Check if paymentMethod is upi or api
          if (
            transactionData &&
            !["cod"].includes(transactionData.paymentMethod)
          ) {
            const all = {
              orderId: returnData[0].orderId,
              orderLineID: returnData[0].orderLineID,
              productId: returnData[0].productId,
              productImage: returnData[0].productImage,
              productName: returnData[0].productName,
              returnStatus: "Rejected",
              reason: ["Vendor Rejected"],
              userUID: userProfile[0].userUID,
              vendorId: returnData[0].vendorId,
              returnType: "Rejected",
            };
            const { data: returnOrderData, error: insertError } = await supabase
              .from(TableName.RETURN)
              .insert(all);

            if (insertError) {
              throw insertError;
            }

            console.log(
              "Return order data inserted successfully:",
              returnOrderData
            );
          } else {
            console.log(
              "Payment method is not upi or api, skipping insert data."
            );
          }
        } else {
          console.error(
            "No return data found for orderLineID:",
            selectedOrderId
          );
        }
      } catch (error) {
        console.error("Error processing return order:", error.message);
      }
    }
    const wmessage = {
      broadcast_name: watiTemplateName.order_status_1.broadcast_name,
      template_name: watiTemplateName.order_status_1.template_name,
      receivers: [
        {
          whatsappNumber:
            String(userProfile[0].countryCode) + String(userProfile[0].phone),
          customParams: [
            {
              name: "customer_name",
              value: userProfile[0].name,
            },
            {
              name: "order_number",
              value: orderdata[0].orderId,
            },
            {
              name: "order_line_number",
              value: data[0].orderLineID,
            },
            {
              name: "current_status",
              value: data[0].orderStatus,
            },
            {
              name: "date_time",
              value: getCurrentDate() + " " + getCurrentTime(),
            },
            {
              name: "productname",
              value: data[0].productName,
            },
            {
              name: "1",
              value: "/",
            },
            {
              name: "product_image_url",
              value: `${process.env.REACT_APP_CB_URL}_next/image?url=%2Fimages%2Flogo.png&w=256&q=75`,
            },
            {
              name: "product_name",
              value: data[0].productName,
            },
            {
              name: "message",
              value:
                data[0].orderStatus === "Rejected" ||
                data[0].orderStatus === "Returned"
                  ? "Please find the below details on"
                  : "We're excited to share an",
            },
          ],
        },
      ],
    };
    if (
      data[0].orderStatus === "Confirmed" ||
      data[0].orderStatus === "Shipped"
    ) {
      const emaildata = {
        email: userProfile[0].email,
        message:
          data[0].orderStatus === "Confirmed"
            ? emailcontent.confirmsubject
            : emailcontent.shippedsubject,
        content: {
          template: emailtemplate.confirm,
          emailVariables: {
            records: data,
            name: userProfile[0].name,
            sizeList: sizeList,
            shipping: 0,
            color: "#2ea7ff",
            ordertype:
              data[0].orderStatus === "Confirmed"
                ? emailcontent.confirmtype
                : emailcontent.shippedtype,
            message:
              data[0].orderStatus === "Confirmed"
                ? emailcontent.confirmmessage
                : emailcontent.shippedmessage,
            total: orderdata[0].orderAmount,
            orderid: data[0].orderId,
            deliveryAddress: orderdata[0].address,
            billingAdress: orderdata[0].address,
            feedback: false,
          },
        },
      };
      sendemail(emaildata);
    } else if (data[0].orderStatus === "Rejected") {
      const emaildata = {
        email: userProfile[0].email,
        message: emailcontent.rejectsubject,
        content: {
          template: emailtemplate.confirm,
          emailVariables: {
            records: data,
            name: userProfile[0].name,
            sizeList: sizeList,
            shipping: 0,
            color: "#ff5959",
            ordertype: emailcontent.rejecttype,
            message: emailcontent.rejectmessage,
            total: orderdata[0].orderAmount,
            orderid: data[0].orderId,
            deliveryAddress: orderdata[0].address,
            billingAdress: orderdata[0].address,
            feedback: false,
          },
        },
      };
      sendemail(emaildata);
    } else if (data[0].orderStatus === "Delivered") {
      const emaildata = {
        email: userProfile[0].email,
        message: emailcontent.deliveredsubject,
        content: {
          template: emailtemplate.confirm,
          emailVariables: {
            records: data,
            name: userProfile[0].name,
            sizeList: sizeList,
            shipping: 0,
            color: "#6c9636",
            ordertype: emailcontent.deliveredtype,
            message: emailcontent.deliveredmessage,
            total: orderdata[0].orderAmount,
            orderid: data[0].orderId,
            deliveryAddress: orderdata[0].address,
            billingAdress: orderdata[0].address,
            feedback: true,
          },
        },
      };
      sendemail(emaildata);
    }
    sendTemplateMessages(wmessage);
    setOrderline((prevOrderline) =>
      prevOrderline.map((item) =>
        item.orderLineID === selectedOrderId
          ? { ...item, orderStatus: status }
          : item
      )
    );
    setFilterOrderline((prevOrderline) =>
      prevOrderline.map((item) =>
        item.orderLineID === selectedOrderId
          ? { ...item, orderStatus: status }
          : item
      )
    );
    setStatus("");
    closeDialog();

    fetchOrders(Id);
  };
  useEffect(
    () => {
      if (filter.length === 0) {
        setFilter([
          { code: "All", id: "order", title: "Status", value: "All" },
        ]);
      } else {
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    const storedDisabledActions = localStorage.getItem("disabledActions");
    if (storedDisabledActions) {
      setDisabledActions(JSON.parse(storedDisabledActions));
    }
  }, []);

  const getSizeList = async () => {
    const sizes = await getListData("Size");
    if (sizes) {
      const sizeData = {};
      sizes.forEach((item) => {
        sizeData[item.listItem] = item.listDescription;
      });
      setSizeList(sizeData);
    }
  };
  useEffect(
    () => {
      getSizeList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <VendorSubHeader
        title="Order Line"
        subtitle="Order"
        back="Order"
        linkProps={{
          to: "/order",
        }}
      />
      <Container>
        <div className="topContent">
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <CommonFilter
            id="order"
            title="Status"
            key={filter.value}
            options={[
              { code: "All", value: "All" },
              { code: "Pending", value: "Pending" },
              { code: "Cancel", value: "Cancelled" },
              { code: "Shipped", value: "Shipped" },
              { code: "Delivered", value: "Delivered" },
              { code: "Confirmed", value: "Confirmed" },
              { code: "Rejected", value: "Rejected" },
            ]}
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer className="tableContainer">
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Product
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Color
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Size
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Current status
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Edit status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterorderline &&
                  filterorderline.slice().map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell
                        align="center"
                        className="cell"
                        sx={{ width: "350px" }}
                      >
                        {item.productName}
                      </TableCell>
                      <TableCell align="center" className="cell">
                        {item.color}
                      </TableCell>
                      <TableCell align="center" className="cell">
                        {sizeList[item.size] || item.size}
                      </TableCell>
                      <TableCell align="center" className="cell">
                        {item.quantity}
                      </TableCell>
                      <TableCell align="center" className="cell">
                        {item.productPrice * item.quantity}
                      </TableCell>
                      <TableCell align="center" className="cell">
                        {item.orderStatus}
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title="Edit" placement="left">
                          <IconButton
                            onClick={() => openDialog(item.orderLineID)}
                            disabled={
                              item.orderStatus === "Returned" ||
                              item.orderStatus === "refunded" ||
                              item.orderStatus === "Cancelled" ||
                              item.orderStatus === "Rejected" ||
                              item.orderStatus === "Delivered"
                                ? true
                                : false
                            }
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={isDialogOpen} sx={{ height: 700, margin: "auto" }}>
            <DialogTitle>Select status</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <FormControl fullWidth>
                  <InputLabel sx={{ mt: "5px" }}>Status</InputLabel>
                  <Select
                    value={status}
                    label="Status"
                    onChange={handleChange}
                    sx={{ width: 300 }}
                  >
                    {orderLineStatus === "Pending"
                      ? [
                          <MenuItem key="confirmed" value={"Confirmed"}>
                            Confirmed
                          </MenuItem>,
                          <MenuItem key="rejected" value={"Rejected"}>
                            Rejected
                          </MenuItem>,
                        ]
                      : orderLineStatus === "Confirmed"
                      ? [
                          <MenuItem key="Shipped" value={"Shipped"}>
                            Shipped
                          </MenuItem>,
                        ]
                      : orderLineStatus === "Shipped"
                      ? [
                          <MenuItem key="delivered" value={"Delivered"}>
                            Delivered
                          </MenuItem>,
                        ]
                      : []}
                  </Select>
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={closeDialog}>
                No
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirmAction}
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* <Dialog open={isrejectDialogOpen} onClose={closeDialog}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to reject this Order ID {selectedOrderId}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={closeDialog}>
                No
              </Button>
              <Button
                variant="contained"
                onClick={handleRejectAction}
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog> */}
        </Grid>
      </Container>
    </div>
  );
};

export default Orderline;
