import * as React from "react";
import { IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function TopBar() {
  return (
    <div style={{ position: "fixed", top: 0, right: 25 }}>
      <IconButton size="large" aria-label="display more actions" edge="end">
        <NotificationsActiveIcon fontSize="large" />
      </IconButton>
      <IconButton size="large" aria-label="display more actions" edge="end">
        <AccountCircleIcon fontSize="large" />
      </IconButton>
    </div>
  );
}
