import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firmName: Yup.string().required("Firm/Company is required"),
  firmType: Yup.string().nullable().required("Type of Firm is required"),
  country: Yup.string()
    .required("Country is required")
    .nullable()
    .matches(/^[^0-9]*$/, "Numbers are not allowed"),
  gstNumber: Yup.string()
    .test(
      "valid-gst-format",
      "Invalid GST No. format",
      (value) =>
        !value ||
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value)
    )
    .nullable()
    .max(15, "GST No. must not exceed 15 characters"),
  panNumber: Yup.string()
    .required("Pan No. is required")
    .nullable()
    .test("valid-pan-format", "Invalid PAN No. format", (value) =>
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)
    ),
  companyStatus: Yup.string().required("Status of Company is required"),
  address: Yup.string().required("Address is required").nullable(),
  // stdcode: Yup.string().required("Std Code is required"),
  nameofperson: Yup.string().required("Name of person is required").nullable(),
  city: Yup.string().required("City is required").nullable(),
  // fax: Yup.string().required("Fax is required"),
  contactperson: Yup.string().required("contactperson is required").nullable(),
  state: Yup.string().required("State is required").nullable(),
  website: Yup.string()
    .required("Website is required")
    .nullable()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]+)?(\/.*)?$/,
      "Invalid website format"
    ),
  msme: Yup.string().required("msme is required"),
  mobile: Yup.string()
    .matches(/^[0-9]+$/, "Only numbers are allowed")
    .required("Mobile is required"),
  description: Yup.string().required("Description is required").nullable(),
  pin: Yup.string()
    .required("Pin is required")
    .nullable()
    .max(6, "Pin must be exactly 6 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  supply: Yup.string().required("supply is required"),
  holdername: Yup.string()
    .required("Account holder name is required")
    .nullable(),
  accountnumber: Yup.string()
    .required("Account number is required")
    .nullable()
    .matches(/^\d{9,18}$/, "Invalid account number format"),
  reaccountnumber: Yup.string()
    .required("Re-entered Account number is required")
    .nullable()
    .oneOf([Yup.ref("accountnumber")], "Account numbers do not match"),
  ifsccode: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format")
    .required("IFSC code is required")
    .nullable(),
});

export default validationSchema;
