import React from "react";
import childbazaarLogo from "./Image/childbazaarLogo.jpg";
import { useNavigate } from "react-router";
import { AppRoutes } from "./constant";
export default function Emailverfiy() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className=" "
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "28rem",
          textAlign: "center",
        }}
      >
        {" "}
        <div
          style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            padding: "25px",
            borderRadius: "5px",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2px",
            }}
          >
            <img
              src={childbazaarLogo}
              alt="Childbazaar Logo"
              edge="start"
              style={{}}
              width={49}
              height={39}
            />
            <p>
              <b style={{ color: "#e41161" }}>Child</b>
              <b style={{ color: "#097a9f" }}>bazaar.com</b>
            </p>
          </div>
          <p>Your email has been verified successfully!</p>
          <p>Click Login to continue.</p>
          <div className="" style={{ margin: "auto", textAlign: "center" }}>
            <a className="text-blue-500">
              <button
                className=""
                onClick={() => {
                  navigate(AppRoutes.HOME);
                }}
                style={{
                  backgroundColor: "#e41161 ", // Orange-500
                  color: "#ffff", // White text
                  paddingLeft: "1.25rem", // Pl-5
                  paddingRight: "1.25rem", // Pr-5
                  paddingTop: "0.5rem", // Pt-2
                  paddingBottom: "0.5rem", // Pb-2
                  marginTop: "0.75rem", // Mt-3
                  borderRadius: "0.375rem", // Rounded-lg
                  cursor: "pointer",
                }}
              >
                <b>Log In</b>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
