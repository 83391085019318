import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, TextField, InputAdornment, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Container } from "@mui/system";
import { useNavigate } from "react-router";
import "../Discount/Discount.css";
import useSupabase from "../../utils/useSupabase";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import TablePagination from "@mui/material/TablePagination";
import CommonDelete from "../../CommonDelete";
import { TableName } from "../../constant";
import { AppRoutes } from "../../constant";
import SelectedFilter from "../SelectedFilter";
import CommonFilter from "../CommonFilter";
import VendorHeader from "../../VendorHeader";
import Tooltip from "@mui/material/Tooltip";

function Discount() {
  const supabase = useSupabase();

  const navigate = useNavigate();
  const [List, setFullList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [filter, setFilter] = useState([]);
  const handleDeleteOpen = (itemId) => {
    setSelectedItemId(itemId);
    setIsDeleteOpen(true);
  };

  const getDiscount = async (newPage, searchKey) => {
    let query = supabase
      .from(TableName.DISCOUNT)
      .select("*", { count: "exact" });

    const id = localStorage.getItem("ids");

    if (id) {
      query = query.eq("vendor_id", id);
    }

    if (searchKey.trim() !== "") {
      query = query.ilike("name", `%${searchKey}%`);
    }

    if (filter.length > 0) {
      query = query.in(
        "isActive",
        filter.map((item) => item.code === "yes")
      );
    }

    try {
      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      setFullList(data || []);
      setTotalItems(count || 0);
      setPage(newPage);
      if (error) {
        console.error("Error fetching Discount:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  };

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm === "") {
      setPage(0);
    }
    setTimeout(() => {
      getDiscount(0, event.target.value);
    }, 500);
  };
  const handleChangePage = (event, newPage) => {
    getDiscount(newPage, searchTerm);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };
  useEffect(
    () => {
      getDiscount(0, searchTerm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  const handleClickOpen = (item, index) => {
    navigate(`${AppRoutes.DISCOUNTEDIT.replace(":id", item.id)}`, {
      state: {
        editItem: item,
        editItemIndex: index,
        List: List,
      },
    });
  };
  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Container className="ProductContainer">
          <VendorHeader
            title=" Discount"
            subtitle=" Discount"
            subtitle1="List"
            showAddButton={true}
            linkProps={{
              to: AppRoutes.EDITPAGE,
            }}
          />
          <div className="topContent">
            <Grid item lg={12} xs={12} sm={6} md={12}>
              <TextField
                sx={{ p: "7px" }}
                id="standard-search"
                type="search"
                variant="standard"
                fullWidth
                placeholder="Search by Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={handleSearch}
              />
              <Divider className="divider" />
            </Grid>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Divider className="divider" />
            </Grid>
            <SelectedFilter
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Divider className="divider" />
            </Grid>
            <CommonFilter
              id="isactive"
              title="Active"
              options={[
                { code: "yes", value: "Yes" },
                { code: "no", value: "No" },
              ]}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
          </div>

          <TableContainer
            sx={{
              width: "100%",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
            }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Percentage
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {List
                  // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.code}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.amount}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.isActive ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            textAlign: "center",
                            gap: "7",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title="Edit" placement="left">
                            <EditOutlinedIcon
                              onClick={() =>
                                handleClickOpen(
                                  item,
                                  index + page * rowsPerPage
                                )
                              }
                              sx={{
                                color: "gray",
                                pr: "7px",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Delete" placement="right">
                            <DeleteOutlineOutlined
                              sx={{ color: "gray" }}
                              onClick={() => handleDeleteOpen(item.id)}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[]}
            sx={{
              "& .MuiPaginationItem-root": {
                fontSize: "12px",
              },
              "& .Mui-selected": {
                backgroundColor: "#1e88e5",
                color: "rgb(108, 115, 127)",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "13px",
                color: "rgb(108, 115, 127)",
              },
            }}
          />
        </Container>
      </div>
      <CommonDelete
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
        full={Discount}
        table={TableName.DISCOUNT}
        deleteId={selectedItemId}
        getFunction={() => getDiscount(0, searchTerm)}
        columnName="id"
      />
    </>
  );
}
export default Discount;
