import React from "react";
import SubHeader from "./SubHeader";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import ImageUpload from "./ImageUpload";
import { useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import { AppRoutes, StorageBucket, TableName } from "../constant";
import { removeImage, uploadImage } from "../utils/helperFunction";
import useSupabase from "../utils/useSupabase";
import { useContext } from "react";
import { Cartcontext } from "../Products/Context";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

const AddBrand = () => {
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const supabase = useSupabase();
  const GlobalState = useContext(Cartcontext);

  const showToast = GlobalState.showToast;
  const validationSchema = yup.object({
    brandCode: yup
      .string()
      .required("Brand Code is required")
      .max(20, "Maximum 20 characters")
      .matches(/^\S*$/, "Spaces are not allowed")
      .test("code-exists", "Brand code already exists", async function (value) {
        const brandId = params.id;
        if (brandId) {
          return true;
        }

        try {
          const { data: existingData, error: existingError } = await supabase
            .from(TableName.BRAND)
            .select("id")
            .eq("code", value);

          if (existingError) {
            console.error(
              "Error checking existing code:",
              existingError.message
            );
            return false;
          }

          return !(existingData && existingData.length > 0);
        } catch (error) {
          console.error("Error:", error.message);
          return false;
        }
      }),

    brandName: yup
      .string()
      .required("Brand Name is required")
      .max(20, "Maximum 20 characters")
      .matches(/^[^\d]+$/, "Numbers are not allowed")
      .test("name-exists", "Brand name already exists", async function (value) {
        const brandId = params.id;
        if (brandId) {
          return true;
        }

        try {
          const { data: existingData, error: existingError } = await supabase
            .from(TableName.BRAND)
            .select("id")
            .eq("name", value);

          if (existingError) {
            console.error(
              "Error checking existing name:",
              existingError.message
            );
            return false;
          }

          return !(existingData && existingData.length > 0);
        } catch (error) {
          console.error("Error:", error.message);
          return false;
        }
      }),
  });
  useEffect(
    () => {
      const fetchBrandData = async () => {
        const brandId = params.id;
        const { data, error } = await supabase
          .from(TableName.BRAND)
          .select()
          .eq("id", brandId);

        if (error) {
          console.error("Error fetching brand data:", error.message);
        } else if (data && data.length > 0) {
          const brand = data[0];
          formik.setValues({
            brandCode: brand.code,
            brandName: brand.name,
            commission: brand.commission,
            isActive: brand.isActive,
          });
          if (brand.imageUrl) {
            setImagesPreview([{ imageUrl: brand.imageUrl }]);
          }
          setOriginalImage(brand.imageUrl);
        }
      };

      if (params.id) {
        fetchBrandData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: {
      brandCode: "",
      brandName: "",
      commission: "",
      isActive: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (imagesPreview.length === 0) {
        // If no image is uploaded, set the imageError state to true
        setImageError(true);
        return; // Prevent form submission
      }
      setisLoading(true);
      const commissionValue =
        values.commission === "" ? "0" : values.commission;
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.BRAND, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.BRAND,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.BRAND)
          .update([
            {
              name: values.brandName,
              code: values.brandCode,
              commission: commissionValue,
              isActive: values.isActive,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("brand data update", data);
          setisLoading(false);
          navigate(AppRoutes.BRANDNAME);
          showToast("update", "Brand");
        }
      } else {
        try {
          let publicUrl = await uploadImage(
            StorageBucket.BRAND,
            imagesPreview[0].imageFile
          );
          const { error: insertError } = await supabase
            .from(TableName.BRAND)
            .insert([
              {
                name: values.brandName,
                code: values.brandCode,
                commission: commissionValue,
                isActive: values.isActive,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }
          setisLoading(false);
          navigate(AppRoutes.BRANDNAME);
          showToast("success", "Brand");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setImageError(false);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
  };

  return (
    <div>
      <SubHeader
        title={params.id ? "Edit a Brand" : "Add a new Brand"}
        subtitle="Brand"
        back="Brand"
        linkProps={{
          to: "/brandname",
        }}
      />
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{
                    fontWeight: "400",
                    padding: "20px",
                  }}
                >
                  Brand Details
                  <Typography className="subhead">
                    Please provide brand details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} mt={3}>
                <ImageUpload
                  isMultiImages={false}
                  handleImageUpload={handleImageUpload}
                  handleCancelImage={handleCancelImage}
                  uploadedImages={imagesPreview}
                  title="Click to upload brand Image"
                />
                {imageError && (
                  <Typography
                    sx={{ pl: "75px", color: "#d32f2f", fontSize: "0.75rem" }}
                  >
                    Please upload a brand image.
                  </Typography>
                )}
                <br />
                <InputLabel>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    Brand Code
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="brandCode"
                  value={formik.values.brandCode}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 10) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.brandCode && Boolean(formik.errors.brandCode)
                  }
                  inputProps={{ maxLength: 20 }}
                  disabled={!!params.id}
                />
                {formik.touched.brandCode && formik.errors.brandCode && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      mt: "-10px",
                    }}
                  >
                    {formik.errors.brandCode}
                  </Typography>
                )}
                <InputLabel>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    Brand Name
                    <Typography sx={{ color: "red" }} component="span">
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <CustomTextField
                  name="brandName"
                  value={formik.values.brandName}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const { value } = e.target;
                    const capitalizedValue =
                      value.charAt(0).toUpperCase() + value.slice(1);
                    if (!/\d/.test(capitalizedValue)) {
                      formik.handleChange({
                        target: {
                          name: "brandName",
                          value: capitalizedValue,
                        },
                      });
                    }
                  }}
                  error={
                    formik.touched.brandName && Boolean(formik.errors.brandName)
                  }
                  inputProps={{ maxLength: 20 }}
                />

                {formik.touched.brandName && formik.errors.brandName && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      mt: "-10px",
                    }}
                  >
                    {formik.errors.brandName}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Commission Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide childbazaar Commission details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Commission Percentage
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="commission"
                      value={formik.values.commission}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        let { value } = e.target;
                        value = value.replace(/\D/, "");
                        const numericValue = parseInt(value);
                        if (numericValue <= 100 || value === "") {
                          formik.setFieldValue("commission", value);
                        }
                      }}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "400px",
              height: "100px",
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography
                  className="head"
                  variant="h6"
                  sx={{
                    fontWeight: "400",
                    mb: 0.5,
                    padding: "20px",
                  }}
                >
                  Brand Status
                  <Typography className="subhead">
                    Please provide your brand status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} mt={3}>
                <FormControl fullWidth>
                  <Stack direction="row">
                    <Typography>Active</Typography>
                    <Switch
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  </Stack>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to="/brandname">
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="primary" className="load" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddBrand;
