import React from "react";
import childbazaarLogo from "./Image/childbazaarLogo.jpg";
import { styled, useTheme } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { AppRoutes } from "./constant";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
// import TextField from "@mui/material/TextField";
// import SearchIcon from "@mui/icons-material/Search";
// import InputAdornment from "@mui/material/InputAdornment";
import "./Sidebar.css";
import { useState, useContext, useEffect } from "react";
import Profile from "./component/Profile";
import DiscountIcon from "@mui/icons-material/Discount";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Cartcontext } from "./Products/Context";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CachedSharpIcon from "@mui/icons-material/CachedSharp";
import useSupabase from "./utils/useSupabase";
import LogoutIcon from "@mui/icons-material/Logout";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const hardcodedValues = {
  "/VendorDashboard": "Dashboard",
  "/productAddList": "Products",
  "/productadd": "Add Product",
  "/discount": "Discount",
  "/order": "Order",
  "/returnOrder": "Return Order",
  "/registerVendor": "Register Vendor",
  "/addcategory": "Add Category",
  "/subcategory": "SubCategory",
  "/addsubcategory": "Add SubCategory",
  "/brandname": "Brand",
  "/addbrand": "Add Brand",
  "/add": "Add Discount",
  "/orderline": "Order Line",
  "/detials": "Transaction Details",
};
const Sidebar = () => {
  const [menuItems, setmenuItems] = useState([]);
  const GlobalState = useContext(Cartcontext);
  const user = GlobalState.user;
  const [role, setRole] = useState();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [value, setValue] = useState("Dashboard");
  const location = useLocation();
  const supabase = useSupabase();
  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const vendorId = localStorage.getItem("ids");
        const { data } = await supabase
          .from("vendor")
          .select("*")
          .eq("id", vendorId)
          .single();

        const isProfileComplete = data.isProfileComplete;
        const isActive = data.isActive;
        if (!isActive && isProfileComplete) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("supabaseToken");
          localStorage.removeItem("vendorEmail");
          localStorage.removeItem("ids");
          localStorage.removeItem("adminAccessToken");
          localStorage.removeItem("supabaseToken");
          localStorage.removeItem("adminEmail");
          GlobalState.setUser(null);
          await supabase.auth.signOut();
        }
        if (isActive && isProfileComplete) {
          const menuItems = [
            {
              text: "Dashboard",
              icon: <DashboardCustomizeRoundedIcon />,
              path: AppRoutes.VENDORDASHBOARD,
            },
            {
              text: "Products",
              icon: <StoreRoundedIcon />,
              path: AppRoutes.PRODUCTADDLIST,
            },
            {
              text: "Discount",
              icon: <DiscountIcon />,
              path: AppRoutes.DISCOUNT,
            },
            {
              text: "Orders",
              icon: <ShoppingCartIcon />,
              path: AppRoutes.ORDER,
            },
            {
              text: "Returns",
              icon: <AssignmentReturnIcon />,
              path: AppRoutes.RETURNORDERLIST,
            },
          ];
          setmenuItems(menuItems);
        } else {
          setmenuItems([
            {
              text: "PROFILE",
              icon: <PersonOutlineIcon />,
              path: AppRoutes.UPDATEPROFILE,
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error.message);
      }
    };

    fetchVendorData();
  }, []);
  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const lastPathPart = pathParts[pathParts.length - 1] || "dashboard";

    if (pathParts.length >= 3 && pathParts[1] === "addInventory") {
      setValue("Add Inventory");
    } else if (pathParts.length >= 3 && pathParts[1] === "inventoryList") {
      setValue("Inventory");
    } else if (pathParts.length >= 4 && pathParts[1] === "productVariant") {
      setValue("Edit Product Variant");
    } else if (pathParts.length >= 4 && pathParts[1] === "inventory") {
      setValue("Edit Inventory");
    } else if (
      pathParts.length >= 4 &&
      pathParts[1] === "discount" &&
      pathParts[2] === "edit"
    ) {
      setValue("Edit Discount");
    } else if (pathParts.length >= 3) {
      if (pathParts[1] === "category") {
        setValue("Edit Category");
      } else if (pathParts[1] === "Vendor") {
        setValue("Edit Vendor");
      } else if (pathParts[1] === "subcategory") {
        setValue("Edit SubCategory");
      } else if (pathParts[1] === "brand") {
        setValue("Edit Brand");
      } else if (pathParts[1] === "product") {
        setValue("Edit Product");
      } else if (pathParts[1] === "productVariantList") {
        setValue("Product Variant");
      } else if (pathParts[1] === "addProductVariant") {
        setValue("Add Product Variant");
      } else if (pathParts[1] === "updateProfile") {
        setValue("Profile");
      } else {
        setValue(hardcodedValues[`/${lastPathPart}`] || lastPathPart);
      }
    } else {
      setValue(hardcodedValues[`/${lastPathPart}`] || lastPathPart);
    }
  }, [location.pathname]);

  const handleItemClick = (index, item) => {
    setSelectedNavItem(index);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [showProfile, setShowProfile] = useState(false);
  const handleProfileClick = () => {
    setShowProfile(!showProfile);
  };
  const admins = [
    {
      text: "Dashboard",
      icon: <DashboardCustomizeRoundedIcon />,
      path: AppRoutes.DASHBOARD,
    },
    {
      text: "Vendor ",
      icon: <FormatListBulletedRoundedIcon />,
      path: AppRoutes.VENDORLIST,
    },
    {
      text: "Category",
      icon: <CategoryRoundedIcon />,
      path: AppRoutes.CATEGORY,
    },

    {
      text: "Subcategory",
      icon: <GridViewRoundedIcon />,
      path: AppRoutes.SUBCATEGORY,
    },

    // { text: "Products", icon: <StoreRoundedIcon />, path: AppRoutes.PRODUCT },
    {
      text: "Banner",
      icon: <ViewCarouselOutlinedIcon />,
      path: AppRoutes.BANNER,
    },
    {
      text: "Brand",
      icon: <LocalOfferRoundedIcon />,
      path: AppRoutes.BRANDNAME,
    },
    {
      text: "List",
      icon: <ListAltRoundedIcon />,
      path: AppRoutes.LIST,
    },
    { text: "Discount", icon: <DiscountIcon />, path: AppRoutes.DISCOUNT },
    {
      text: "Transaction",
      icon: <AccountBalanceOutlinedIcon />,
      path: AppRoutes.Transaction,
    },
    {
      text: "Refund",
      icon: <CachedSharpIcon />,
      path: AppRoutes.Refund,
    },
  ];
  const vendors = [
    {
      text: "Dashboard",
      icon: <DashboardCustomizeRoundedIcon />,
      path: AppRoutes.VENDORDASHBOARD,
    },
    {
      text: "Products",
      icon: <StoreRoundedIcon />,
      path: AppRoutes.PRODUCTADDLIST,
    },
    { text: "Discount", icon: <DiscountIcon />, path: AppRoutes.DISCOUNT },
    { text: "Orders", icon: <ShoppingCartIcon />, path: AppRoutes.ORDER },
    {
      text: "Returns",
      icon: <AssignmentReturnIcon />,
      path: AppRoutes.RETURNORDERLIST,
    },
  ];
  useEffect(() => {
    const userType = sessionStorage.getItem("userType");
    const menuItem = userType === "true" ? admins : vendors;
    setmenuItems([...menuItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GlobalState.userRole]);
  const handleMouseLeave = () => {
    setShowProfile(false);
  };
  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("supabaseToken");
    localStorage.removeItem("vendorEmail");
    localStorage.removeItem("ids");
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("supabaseToken");
    localStorage.removeItem("adminEmail");
    GlobalState.setUser(null);
    await supabase.auth.signOut();
    navigate(AppRoutes.HOME);
  };
  const userType = sessionStorage.getItem("userType") === "true";
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: "#FFF4F8  !important" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2px",
              }}
            >
              <img
                src={childbazaarLogo}
                alt="Childbazaar Logo"
                onClick={handleDrawerOpen}
                edge="start"
                style={{
                  marginLeft: -14,
                  marginRight: 22,
                  cursor: "pointer",
                  ...(open && { display: "none" }),
                }}
                width={49}
                height={39}
              />
            </div>
            {!open && (
              <IconButton
                onClick={handleDrawerOpen}
                sx={{ color: "black", marginLeft: "-30PX", marginTop: "-10PX" }}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>

          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              color: "black",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {value}
          </Typography>
          <div>
            {/* <TextField
              autoComplete="off"
              variant="outlined"
              placeholder="Search..."
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField> */}
          </div>
          {/* <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              sx={{ mt: "6px" }}
            >
              <NotificationsActiveOutlinedIcon
                sx={{ fontSize: "32px", color: "black" }}
              />
            </IconButton>
          </div> */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onMouseEnter={handleProfileClick}
              onMouseLeave={handleMouseLeave}
              sx={{ mt: "6px" }}
              onClick={() => {
                if (!userType) {
                  navigate(AppRoutes.UPDATEPROFILE);
                }
              }}
            >
              <AccountCircleOutlinedIcon
                sx={{ fontSize: "32px", color: "black" }}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {showProfile && (
        <Profile
          showProfile={showProfile}
          email={user}
          role={role}
          setShowProfile={setShowProfile}
        />
      )}
      <Drawer variant="permanent" open={open} className="dra">
        <DrawerHeader
          sx={{
            backgroundColor: "white",
            borderBottom: "1px solid #fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={childbazaarLogo}
                alt="Childbazaar Logo"
                style={{
                  width: "80%",
                  height: "70%",
                  borderRadius: "50%",
                }}
              />
            </div>
            <Typography
              variant="h6"
              sx={{
                color: "#851841",
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
            >
              ChildBazaar
            </Typography>
          </div>

          <IconButton onClick={handleDrawerClose} sx={{ color: "#851841" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            height: "100vh",
            backgroundColor: "white",
            scrollbarWidth: "thin",
          }}
        >
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              onClick={async () => {
                navigate(item.path);
                const pathParts = item.path.split("/");
                const value = pathParts[pathParts.length - 1];
                sessionStorage.setItem(`headerchange`, value);
                handleItemClick(index, item);
                if (item.text === "Logout") {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("supabaseToken");
                  localStorage.removeItem("vendorEmail");
                  localStorage.removeItem("ids");
                  localStorage.removeItem("adminAccessToken");
                  localStorage.removeItem("supabaseToken");
                  localStorage.removeItem("adminEmail");
                  GlobalState.setUser(null);
                  await supabase.auth.signOut();
                }
              }}
              disablePadding
              sx={{
                display: "block",
                mt: 0.5,
                color: location.pathname === item.path ? "white" : "#9DA4AE",
                "&:hover": {
                  color: location.pathname === index ? "white" : "#9DA4AE",
                  backgroundColor: "#c3d0db",
                  borderRadius: "15px",
                  mt: 0.5,
                },
                backgroundColor: location.pathname === item.path && "#c3d0db",
                borderRadius: location.pathname === index ? "15px" : "0",
              }}
            >
              <ListItemButton
                className="sidebar-content"
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  fontSize: "3rem",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color:
                      location.pathname === item.path ? "#851841" : "#851841",
                    fill: "#851841",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className="sidebarcontent"
                  sx={{
                    opacity: open ? 1 : 0,
                    fontSize: 24,
                    color:
                      location.pathname === item.path ? "#851841" : "#851841",
                    fill: "#851841",
                  }}
                >
                  {item.text}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
          <br />
          <Divider />
          <br />
          {open ? (
            <Button
              sx={{
                display: "block",
                ml: "10px",
                mt: 0.25,
                color: "#9DA4AE",
                border: "1px solid #9DA4AE",
                bgcolor: "#851841",
                marginRight: "10px",
                width: "200px",
                padding: "0px 16px",
                fontSize: "14px",
                "&:hover": {
                  color: "#851841",
                  backgroundColor: "#851841",
                  mt: 0.25,
                },
              }}
              onClick={handleLogout}
            >
              <ListItemButton
                className="sidebar-content"
                sx={{
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  fontSize: "3rem",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                    fill: "white",
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  className="sidebarcontent"
                  sx={{
                    opacity: open ? 1 : 0,
                    fontSize: 24,
                    color: "white",
                    fill: "white",
                  }}
                >
                  Log out
                </ListItemText>
              </ListItemButton>
            </Button>
          ) : (
            <ListItemButton
              onClick={handleLogout}
              sx={{
                backgroundColor: "#851841",
                mx: 1,
                borderRadius: 1,
                "&:hover": {
                  color: "#851841",
                  backgroundColor: "#851841",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  color: "white",
                  fill: "white",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
            </ListItemButton>
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
