import React, { useState } from "react";
import useSupabase from "../utils/useSupabase";
import childbazaarLogo from "../Image/childbazaarLogo.jpg";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { useNavigate } from "react-router";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [form, setForm] = useState(true);
  const [error, setError] = useState("");
  const supabase = useSupabase();

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data: users, error } = await supabase
      .from("userProfile")
      .select("*")
      .eq("email", email);

    if (error) {
      console.error("Error fetching user profile:", error.message);
      return;
    }

    if (users.length === 0) {
      setError("Email not found.");
      return;
    }

    const user = users[0];

    if (user.userType !== "Vendor") {
      setError("You are not authorized to reset password.");
      return;
    }

    const { data, error: resetError } =
      await supabase.auth.resetPasswordForEmail(email, {
        redirectTo:`${process.env.REACT_APP_CB_ADMIN_URL}resetpassword`,
      });

    if (resetError) {
      setError("Error resetting password");
      return;
    }

    if (data) {
      setForm(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "35rem",
        textAlign: "center",
      }}
    >
      {form ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            padding: "25px",
            borderRadius: "5px",
            maxWidth: "md",
            width: "30%",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={childbazaarLogo}
              alt="Childbazaar Logo"
              edge="start"
              style={{}}
              width={49}
              height={39}
            />
            <p>
              <b style={{ color: "#e41161" }}>Child</b>
              <b style={{ color: "#097a9f" }}>bazaar.com</b>
            </p>
          </div>
          <h2
            style={{
              fontSize: "2xl",
              fontWeight: "semibold",
            }}
          >
            Forgot Password
          </h2>
          <div>
            <TextField
              name="email"
              variant="outlined"
              label="Email *"
              fullWidth
              placeholder="Your email "
              error={error && Boolean(error)}
              helperText={error && error}
              value={email}
              onChange={onChange}
            />
            {/* {error && (
                <p
                  style={{
                    color: "#EF4444",
                    marginTop: "1px",
                    fontSize: "sm",
                  }}
                >
                  {error}
                </p>
              )} */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ marginTop: 3, marginBottom: 4 }}
            >
              Reset Password
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            flexDirection: "column",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            padding: "25px",
            borderRadius: "5px",
            maxWidth: "md",
            width: "30%",
            height: "70%",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 7,
            }}
          >
            <img
              src={childbazaarLogo}
              alt="Childbazaar Logo"
              edge="start"
              style={{}}
              width={49}
              height={39}
            />
            <p>
              <b style={{ color: "#e41161" }}>Child</b>
              <b style={{ color: "#097a9f" }}>bazaar.com</b>
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <MarkEmailUnreadIcon sx={{ fontSize: "500%" }} color="primary" />
          </div>
          <b style={{ flexGrow: 7 }}> Reset password link sent to the email</b>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
