import React, { useEffect, useState } from "react";
import VendorHeader from "./VendorHeader";
import {
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  FormControl,
  Box,
  TablePagination,
} from "@mui/material";
import useSupabase from "./utils/useSupabase";
import SearchIcon from "@mui/icons-material/Search";
import CommonFilter from "./component/CommonFilter";
import SelectedFilter from "./component/SelectedFilter";
import Tooltip from "@mui/material/Tooltip";
import {
  TableName,
  emailcontent,
  emailtemplate,
  watiTemplateName,
  waticontent,
  websiteDetials,
} from "./constant";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getListData } from "./utils/helperFunction";
import { sendTemplateMessages } from "./Services/wati";

const ReturnOrder = () => {
  const supabase = useSupabase();
  const [returnOrders, setReturnOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [error, setError] = useState("");
  const [filter, setFilter] = useState([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [totalItems, setTotalItems] = useState(0);
  const [sizeList, setSizeList] = useState({});
  const getSizeList = async () => {
    const sizes = await getListData("Size");
    if (sizes) {
      const sizeData = {};
      sizes.forEach((item) => {
        sizeData[item.listItem] = item.listDescription;
      });
      setSizeList(sizeData);
    }
  };
  useEffect(
    () => {
      getSizeList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(
    () => {
      fetchData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  const sendemail = async (emaildata) => {
    await fetch(
      `${process.env.REACT_APP_CB_URL}api/email`, //
      {
        //
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emaildata), // body data type must match "Content-Type" header
      }
    );
  };
  async function fetchData(newPage, searchKey) {
    try {
      const vendorid = localStorage.getItem("ids");
      let query = supabase
        .from(TableName.RETURN)
        .select("*", { count: "exact" })
        .eq("vendorId", vendorid)
        .not("reason", "cs", '{"Vendor Rejected"}');
      if (searchKey.trim() !== "") {
        query = query.ilike("productName", `%${searchKey}%`);
      }
      query = query.order("id", { ascending: false });
      if (filter.length > 0) {
        const filterCodes = filter.map((item) => item.code);
        query = query.in("returnStatus", filterCodes);
      }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);
      console.log("first", data);

      setReturnOrders(data || []);
      setTotalItems(count || 0);

      if (error) {
        console.error("Error fetching categories:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }
  console.log("return", returnOrders);
  const handleEditClick = (order) => {
    setSelectedOrder(order);
    setOpenDialog(true);
  };

  const handleStatusChange = async () => {
    if (!updatedStatus) {
      setError("Please select a status");
      return;
    }
    try {
      const { data: returndata } = await supabase
        .from(TableName.RETURN)
        .update({ returnStatus: updatedStatus })
        .select("*")
        .eq("id", selectedOrder.id);
      const { data } = await supabase
        .from(TableName.ORDERLINE)
        .select("*")
        .eq("orderLineID", returndata[0].orderLineID);
      const { data: orderdata } = await supabase
        .from(TableName.ORDER)
        .select("*")
        .eq("orderId", data[0].orderId);
      const { data: userProfile } = await supabase
        .from(TableName.USERPROFILE)
        .select("*")
        .eq("userUID", orderdata[0].userUID);
      const wmessage = {
        broadcast_name: watiTemplateName.return_status.broadcast_name,
        template_name: watiTemplateName.return_status.template_name,
        receivers: [
          {
            whatsappNumber:
              String(userProfile[0].countryCode) + String(userProfile[0].phone),
            customParams: [
              {
                name: "customer_name",
                value: userProfile[0].name,
              },
              {
                name: "product_name",
                value: data[0].productName,
              },
              {
                name: "status",
                value:
                  updatedStatus === "Return Accepted" ? "Accepted" : "Rejected",
              },
              {
                name: "email",
                value: websiteDetials.contact_email,
              },
              {
                name: "brand_name",
                value: websiteDetials.website_name,
              },
              {
                name: "message",
                value:
                  updatedStatus === "Return Accepted"
                    ? waticontent.returnA
                    : waticontent.returnR,
              },
            ],
          },
        ],
      };
      if (updatedStatus === "Return Accepted") {
        const emaildata = {
          email: userProfile[0].email,
          message: emailcontent.returnsubject,
          content: {
            template: emailtemplate.confirm,
            emailVariables: {
              records: data,
              name: userProfile[0].name,
              sizeList: sizeList,
              shipping: 0,
              color: "#2ea7ff",
              ordertype: emailcontent.returntype,
              message: emailcontent.returnmessage.replace(
                "#[OrderNumber]",
                data[0].orderId
              ),
              feedback: true,
              total: orderdata[0].orderAmount,
              orderid: data[0].orderId,
              deliveryAddress: orderdata[0].address,
              billingAdress: orderdata[0].address,
            },
          },
        };
        sendemail(emaildata);
      } else if (updatedStatus === "Return Rejected") {
        const emaildata = {
          email: userProfile[0].email,
          message: emailcontent.returnRsubject,
          content: {
            template: emailtemplate.confirm,
            emailVariables: {
              records: data,
              name: userProfile[0].name,
              sizeList: sizeList,
              shipping: 0,
              color: "#ff5959",
              ordertype: emailcontent.returnRtype,
              message: emailcontent.returnRmessage.replace(
                "#[OrderNumber]",
                data[0].orderId
              ),
              total: orderdata[0].orderAmount,
              orderid: data[0].orderId,
              deliveryAddress: orderdata[0].address,
              billingAdress: orderdata[0].address,
              feedback: true,
            },
          },
        };
        sendemail(emaildata);
      }
      sendTemplateMessages(wmessage);
      // Update the local state
      const updatedOrders = returnOrders.map((order) =>
        order.id === selectedOrder.id
          ? { ...order, returnStatus: updatedStatus }
          : order
      );
      setReturnOrders(updatedOrders);
      setOpenDialog(false);
      setError("");
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };
  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };
  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm === "") {
      setPage(0);
    }
    setTimeout(() => {
      fetchData(0, event.target.value);
    }, 500);
  };
  const handleChangePage = (event, newPage) => {
    fetchData(newPage, searchTerm);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Container className="ProductContainer">
          <VendorHeader
            title="Return Order"
            subtitle="Return Order"
            subtitle1="List"
          />
          <div className="topContent">
            <Grid item lg={12} xs={12} sm={6} md={12}>
              <TextField
                sx={{ p: "7px" }}
                id="standard-search"
                type="search"
                variant="standard"
                fullWidth
                placeholder="Search by name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={handleSearch}
              />
              <Divider className="divider" />
            </Grid>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Divider className="divider" />
            </Grid>
            <SelectedFilter
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Divider className="divider" />
            </Grid>
            <CommonFilter
              id="status"
              title="Status"
              options={[
                { code: "Return Accepted", value: "Accepted" },
                { code: "Return Rejected", value: "Rejected" },
              ]}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
          </div>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <TableContainer className="tableContainer">
              <Table sx={{ minWidth: 650, borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Return Id
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Order Id
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Reason
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Edit Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returnOrders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell align="center">{order.id}</TableCell>
                      <TableCell align="center">{order.orderId}</TableCell>
                      <TableCell align="center">{order.productName}</TableCell>
                      <TableCell align="center">{order.returnStatus}</TableCell>
                      <TableCell align="center">{order.reason}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Edit" placement="right">
                          <IconButton
                            onClick={() => handleEditClick(order)}
                            disabled={
                              order.returnStatus === "Return Initiated"
                                ? false
                                : true
                            }
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="page">
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[]}
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: "12px",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#1e88e5",
                    color: "rgb(108, 115, 127)",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontSize: "13px",
                    color: "rgb(108, 115, 127)",
                  },
                }}
              />
            </Box>
          </Grid>
        </Container>
      </div>
      <Dialog sx={{ height: 700, margin: "auto" }} open={openDialog}>
        <DialogTitle>Select Status</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel sx={{ mt: "5px" }}>
              Status<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Select
              label="Status*"
              sx={{ width: 300 }}
              fullWidth
              value={updatedStatus}
              onChange={(e) => setUpdatedStatus(e.target.value)}
            >
              <MenuItem value="Return Accepted">Return Accepted</MenuItem>
              <MenuItem value="Return Rejected">Return Rejected</MenuItem>
            </Select>
          </FormControl>
          {error && (
            <Typography sx={{ fontSize: "15px" }} color="red">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStatusChange}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReturnOrder;
