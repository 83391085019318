import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppRoutes, TableName } from "../constant";
import { Search } from "@mui/icons-material";
import SelectedFilter from "./SelectedFilter";
import CommonFilter from "./CommonFilter";
import useSupabase from "../utils/useSupabase";
import { Link, useParams } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { getListData } from "../utils/helperFunction";
import VendorHeader from "../VendorHeader";
import Tooltip from "@mui/material/Tooltip";

const InventoryList = () => {
  const [inventory, setInventory] = useState();
  const supabase = useSupabase();
  const [filter, setFilter] = useState([]);
  const [sizeList, setSizeList] = useState({});
  const rowsPerPage = 4;
  const [page, setPage] = useState(0);
  const { variantId } = useParams();
  const { productId } = useParams();
  useEffect(
    () => {
      fetchInventoryData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  const getSizeList = async () => {
    const sizes = await getListData("Size");
    if (sizes) {
      const sizeData = {};
      sizes.forEach((item) => {
        sizeData[item.listItem] = item.listDescription;
      });
      setSizeList(sizeData);
    }
  };
  useEffect(
    () => {
      getSizeList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  async function fetchInventoryData() {
    if (filter && filter?.length > 0) {
      const { data } = await supabase
        .from(TableName.ADDINVENTORY)
        .select()
        .eq("variantId", variantId)
        .in(
          "isActive",
          filter.map((_fil) => _fil.value)
        );
      setInventory(data);
    } else {
      const { data, error } = await supabase
        .from(TableName.ADDINVENTORY)
        .select()
        .eq("variantId", variantId);
      setInventory(data);
      if (error) {
        console.log(error);
      }
    }
  }
  const handleSearch = async (event) => {
    const searchTerm = event.target.value;

    if (searchTerm.trim() !== "") {
      try {
        const { data, error } = await supabase
          .from(TableName.ADDINVENTORY)
          .select()
          .textSearch("quantity", `'${searchTerm}'`, {
            config: "english",
          });

        if (error) {
          console.error("Error fetching categories:", error);
        } else {
          setInventory(data);
        }
      } catch (error) {
        console.error("Error performing search:", error);
      }
    } else {
      fetchInventoryData();
    }
  };
  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Link
          to={`${AppRoutes.PRODUCTVARIANTLIST}/${productId}`}
          style={{ textDecoration: "none" }}
        >
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ArrowBackSharpIcon />
            <Typography
              sx={{ color: "rgb(17, 25, 39)", fontWeight: "inherit" }}
            >
              Product Variant
            </Typography>
          </IconButton>
        </Link>
        <VendorHeader
          title="Inventory"
          subtitle="Inventory"
          subtitle1="List"
          showAddButton={true}
          linkProps={{
            to: `${AppRoutes.ADDINVENTORY_WITHOUT_ID}/${productId}/${variantId}`,
          }}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ mt: "5px", p: "7px", ml: "-5px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by inventory name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <CommonFilter
            id="isactive"
            title="Active"
            options={[
              { code: "yes", value: "Yes" },
              { code: "no", value: "No" },
            ]}
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Added Quantity
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Remaining Quantity
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Size
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventory &&
                  inventory.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">
                        {item.remainingQuantity}
                      </TableCell>
                      <TableCell align="center">{item.price}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {sizeList[item.size] || item.size}
                      </TableCell>
                      <TableCell align="center">
                        {item.isActive ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={{
                            pathname: `${AppRoutes.ADDINVENTORY}/${item.productId}/${item.variantId}/${item.inventoryId}`,
                            state: { inventory: item },
                          }}
                        >
                          <Tooltip title="Edit" placement="right">
                            <IconButton>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={inventory ? inventory.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default InventoryList;
