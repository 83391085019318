import {
  Container,
  Grid,
  Typography,
  Fab,
  Card,
  CardMedia,
  CardContent,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Switch,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import "../Style/Banner.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import useSupabase from "../utils/useSupabase";
import { Cartcontext } from "../Products/Context";
import ImageUpload from "../component/ImageUpload";
import { uploadImage, removeImage } from "../utils/helperFunction";
import { StorageBucket, TableName } from "../constant";
import Header from "../component/Header";

function Banner() {
  const supabase = useSupabase();
  const inputRef = useRef(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);
  const [ids, setids] = useState();

  const GlobalState = useContext(Cartcontext);
  const showToast = GlobalState.showToast;
  const [DeleteOpen, setDeleteOpen] = useState(false);
  const [isNewImageUp, setIsNewImageUp] = useState(false);

  const handleSwitchChange = async (index) => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = !updatedSwitchStates[index];
    setSwitchStates(updatedSwitchStates);
    try {
      const imageUrlToUpdate = isNewImageUp[index];
      const { error } = await supabase
        .from(TableName.BANNER)
        .update({ isActive: updatedSwitchStates[index] })
        .eq("imageUrl", imageUrlToUpdate);

      if (error) {
        console.error("Error updating status:", error.message);
      }
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const Fetchimageurl = async () => {
    const { data } = await supabase.from("banner").select();
    Fetchimages(data);
  };

  const Fetchimages = async (data) => {
    const filteredUrls = data.map((item) => {
      if (item && item.imageUrl) {
        const imageUrl = Array.isArray(item.imageUrl)
          ? item.imageUrl[0]
          : item.imageUrl;
        return imageUrl;
      }
      return null;
    });

    const filteredStatus = data.map((item) => {
      if (item) {
        return item.isActive;
      }
      return null;
    });

    setSwitchStates(filteredStatus);
    setIsNewImageUp(filteredUrls);
  };

  useEffect(
    () => {
      Fetchimageurl();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleImageUpload = async (event) => {
    const imageFiles = event.target.files;

    const newImages = Array.from(imageFiles);

    setUploadedImages([...newImages, ...uploadedImages]);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleDeleteImage = async (imageUrl) => {
    try {
      await removeImage(StorageBucket.BANNER, imageUrl);

      const { error: deleteError } = await supabase
        .from(TableName.BANNER)
        .delete()
        .eq("imageUrl", imageUrl);

      Fetchimageurl();
      if (deleteError) {
        console.error("Error deleting image:", deleteError.message);
      }
    } catch (error) {
      console.error("Error deleting image:", error.message);
    }
    showToast("delete", "Image");
  };

  const handleclose = () => {
    setDeleteOpen(false);
  };

  const handleRemoveImage = async (index) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(updatedImages);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleSubmit = async (item, index) => {
    if (Array.isArray(uploadedImages) && uploadedImages.length > 0) {
      let publicUrl = await uploadImage(StorageBucket.BANNER, item, false);
      console.log("publicurl", publicUrl, item);

      const { data, error: insertError } = await supabase
        .from("banner")
        .insert({ imageUrl: publicUrl });
      if (!insertError) {
        setUploadedImages((prevImages) =>
          prevImages.filter((image, i) => i !== index)
        );
        setIsNewImageUp(data);
        Fetchimageurl();
      } else {
        console.error("Error inserting image:", insertError.message);
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Header title="Banner" subtitle="Banner" subtitle1="Images" />
        <Grid className="bannerContainer" container columnSpacing={6}>
          <Grid item lg={4}>
            <Box className="bannerTxt">
              <Typography
                sx={{ fontWeight: "bold", color: "rgb(34, 39, 49)" }}
                variant="h6"
              >
                Banner Images
              </Typography>
              <Typography
                sx={{ color: "rgb(108, 115, 127)", fontSize: "13px" }}
              >
                Make your banner look more attractive with 3:4 size photos
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Box className="bannerBox">
              <ImageUpload
                handleImageUpload={handleImageUpload}
                inputRef={inputRef}
              />
              {uploadedImages.length === 0 && (
                <Typography sx={{ paddingLeft: "55px" }}>
                  Click to upload an image or photos
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid className="bannerContainer" container columnSpacing={6}>
          <Grid item lg={4} xs={12}>
            <Box className="bannerTxt">
              <Typography
                sx={{ fontWeight: "bold", color: "rgb(34, 39, 49)" }}
                variant="h6"
              >
                Uploaded Images
              </Typography>
              <Typography
                sx={{ color: "rgb(108, 115, 127)", fontSize: "13px" }}
              >
                Preview your banner look more attractive with 3:4 size photos
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Box className="bannerBox">
              {uploadedImages.map((item, index) => {
                return (
                  <div className="cartContent" key={item.id}>
                    <Card className="cardImg">
                      <CardMedia
                        className="imgs"
                        sx={{ height: 220, width: 620 }}
                        component="img"
                        src={
                          item.name
                            ? URL.createObjectURL(item)
                            : JSON.parse(item)
                        }
                        alt={`Image ${index}`}
                      />
                      <CardContent className="contentCard">
                        <Fab size="small">
                          <FileUploadIcon
                            onClick={() => handleSubmit(item, index)}
                          />
                        </Fab>

                        <Button
                          variant="outlined"
                          className="delBtn"
                          onClick={() => handleRemoveImage(index)}
                        >
                          Remove
                        </Button>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid className="bannerContainer" container columnSpacing={6}>
          <Grid item lg={4}>
            <Box className="bannerTxt">
              <Typography
                sx={{ fontWeight: "bold", color: "rgb(34, 39, 49)" }}
                variant="h6"
              >
                Banner Images
              </Typography>
              <Typography
                sx={{ color: "rgb(108, 115, 127)", fontSize: "13px" }}
              >
                Digital image advertisements that are placed on websites
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box className="bannerBox">
              {Array.isArray(isNewImageUp) &&
                isNewImageUp.map((item, index) => {
                  console.log("Item:", item);

                  return (
                    <div className="cartContent" key={index}>
                      <Card className="cardImg">
                        <CardMedia
                          className="imgs"
                          sx={{ height: 220, width: 620 }}
                          component="img"
                          src={item}
                          alt={`Image ${index}`}
                        />
                        <CardContent className="contentCard">
                          <Switch
                            classes={{
                              track: switchStates[index]
                                ? "switchTrackTrue"
                                : "switchTrackFalse",
                              thumb: switchStates[index]
                                ? "switchTrackTrue"
                                : "switchTrackFalse",
                            }}
                            checked={switchStates[index]}
                            onChange={() => handleSwitchChange(index)}
                            inputProps={{ "aria-label": "controlled" }}
                          />

                          <Button
                            variant="outlined"
                            className="delBtn"
                            onClick={() => {
                              setDeleteOpen(true);
                              setids(item);
                            }}
                          >
                            Delete
                          </Button>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        className="dialogop"
        open={DeleteOpen}
        maxWidth="md"
        aria-label="dialog-title"
        aria-describedby="dialog-description"
        BackdropProps={{
          sx: { backgroundColor: "rgba(0,0,0,0.2)" },
        }}
        PaperProps={{
          sx: { boxShadow: "none", padding: "1rem 1rem" },
        }}
      >
        <DialogTitle id="dialog-title">
          <Typography className="confirm">Delete Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            Are you sure you want to delete this banner?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          <Button
            className="del-cancelbtn"
            onClick={() => {
              handleclose();
            }}
          >
            cancel
          </Button>
          <Button
            className="del-deletebtn"
            onClick={() => {
              handleDeleteImage(ids);
              handleclose();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Banner;
