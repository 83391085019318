import {
  Box,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AppRoutes, TableName } from "../constant";
import { Search } from "@mui/icons-material";
import useSupabase from "../utils/useSupabase";
import { Link } from "react-router-dom";
import CommonFilter from "./CommonFilter";
import SelectedFilter from "./SelectedFilter";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CommonDelete from "../CommonDelete";
import VendorHeader from "../VendorHeader";
import Tooltip from "@mui/material/Tooltip";

const ProductAddList = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [product, setProduct] = useState([]);
  const [filter, setFilter] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [page, setPage] = useState(0);
  // const rowsPerPage = 4;
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const supabase = useSupabase();
  const openDeleteDialog = (productid) => {
    setSelectedProductId(productid);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setSelectedProductId(null);
    setIsDeleteDialogOpen(false);
  };
  useEffect(
    () => {
      fetchProducts(0, searchTerm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  const fetchProductsData = async () => {
    try {
      // Call the getDiscount function to fetch discounts from Supabase
      await fetchProducts(page, searchTerm);
    } catch (error) {
      console.error("Error fetching discounts:", error);
    }
  };
  const getFilters = (id) => {
    return filter
      ?.filter((_data) => _data.id === id)
      ?.map((_data) => _data.code);
  };
  const getCategoryName = (categoryCode) => {
    const category = categories.find((cat) => cat.code === categoryCode);
    return category ? category.name : "Category Not Found";
  };

  const getSubCategoryName = (subcategoryCode) => {
    const subcategory = subcategories.find(
      (subcat) => subcat.code === subcategoryCode
    );
    return subcategory ? subcategory.name : "Subcategory Not Found";
  };

  const getBrandName = (brandCode) => {
    const brand = brands.find((br) => br.code === brandCode);
    return brand ? brand.name : "Brand Not Found";
  };

  const fetchProducts = async (newPage, searchKey) => {
    const id = localStorage.getItem("ids");
    const categoryCode = getFilters("category");
    const subcategoryCode = getFilters("subcategory");
    const brandCode = getFilters("brand");
    const isActive = getFilters("status");

    let query = supabase
      .from(TableName.PRODUCTADD)
      .select("*", { count: "exact" })
      .order("productName")
      .eq("vendorId", id);

    if (categoryCode && categoryCode.length > 0) {
      query = query.in("categoryCode", categoryCode);
    }

    if (subcategoryCode && subcategoryCode.length > 0) {
      query = query.in("subcategoryCode", subcategoryCode);
    }

    if (brandCode && brandCode.length > 0) {
      query = query.in("brandCode", brandCode);
    }

    if (isActive && isActive.length > 0) {
      query = query.in("isActive", isActive);
    }
    if (searchKey.trim() !== "") {
      query = query.ilike("productName", `%${searchKey}%`);
    }
    const { data, count, error } = await query
      .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
      .limit(rowsPerPage);
    setProduct(data || []);
    setTotalCount(count || 0);

    if (error) {
      console.error("Error fetching filtered data:", error);
    } else {
      console.log("Total Count:", totalCount || 0);
    }
  };
  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm === "") {
      // If search term is empty, reset page to 0
      setPage(0);
    }
    setTimeout(() => {
      fetchProducts(0, event.target.value);
    }, 500);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from(TableName.CATEGORY)
        .select()
        .eq("isActive", true);

      if (error) {
        console.error("Error fetching categories:", error);
      } else {
        setCategories(data);
      }
    };

    fetchCategories();
    const fetchSubcategories = async () => {
      const { data, error } = await supabase
        .from(TableName.SUBCATEGORY)
        .select()
        .eq("isActive", true);

      if (error) {
        console.error("Error fetching subcategories:", error);
      } else {
        setSubcategories(data);
      }
    };

    fetchSubcategories();
    const fetchBrands = async () => {
      const { data, error } = await supabase
        .from(TableName.BRAND)
        .select()
        .eq("isActive", true);

      if (error) {
        console.error("Error fetching brand name:", error);
      } else {
        setBrands(data);
      }
    };

    fetchBrands();
    fetchProducts(0, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabase]);

  useEffect(
    () => {
      //fetchTotalCount();
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, searchTerm]
  );

  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    fetchProducts(newPage, searchTerm);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };
  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <VendorHeader
          title="Product"
          subtitle="Product"
          subtitle1="List"
          showAddButton={true}
          linkProps={{
            to: AppRoutes.PRODUCTADD_WITHOUT_ID,
          }}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ mt: "5px", p: "7px", ml: "-5px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by product name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Box sx={{ p: 1 }}>
              <SelectedFilter
                selectedOptions={filter}
                onFilterChange={handleFilterChange}
              />
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Divider className="divider" />
              </Grid>
              <Stack direction="row" spacing={2}>
                <CommonFilter
                  id="category"
                  title="Category"
                  options={categories.map((category) => ({
                    code: category.code,
                    value: category.name,
                  }))}
                  selectedOptions={filter}
                  onFilterChange={handleFilterChange}
                />
                <CommonFilter
                  id="subcategory"
                  title="Sub Category"
                  options={subcategories.map((subcategory) => ({
                    code: subcategory.code,
                    value: subcategory.name,
                  }))}
                  selectedOptions={filter}
                  onFilterChange={handleFilterChange}
                />
                <CommonFilter
                  id="brand"
                  title="Brand"
                  options={brands.map((brand) => ({
                    code: brand.code,
                    value: brand.name,
                  }))}
                  selectedOptions={filter}
                  onFilterChange={handleFilterChange}
                />
                <CommonFilter
                  id="status"
                  title="Status"
                  options={[
                    { code: "true", value: "Published" },
                    { code: "false", value: "Draft" },
                  ]}
                  selectedOptions={filter}
                  onFilterChange={handleFilterChange}
                />
              </Stack>
            </Box>
          </Grid>
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell align="center" className="cell"></TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    SubCategory
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Brand
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product
                  // ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  ?.map((row, index) => (
                    <>
                      <TableRow key={row.id}>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row.productImage && (
                            <>
                              {JSON.parse(row.productImage)
                                .slice(0, 1)
                                .map((productImage, index) => (
                                  <CardMedia
                                    key={index}
                                    component="img"
                                    image={productImage}
                                    alt={`Product ${index}`}
                                    style={{ width: 90, height: 90 }}
                                  />
                                ))}
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            wordWrap: "break-word",
                            maxWidth: "200px",
                          }}
                        >
                          {row.productName}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {getCategoryName(row.categoryCode)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {getSubCategoryName(row.subcategoryCode)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {getBrandName(row.brandCode)}
                        </TableCell>
                        <TableCell>
                          <Box className="status">
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                backgroundColor:
                                  row.isActive === "true"
                                    ? "rgb(16 185 129 / 12%)"
                                    : "rgb(6 174 212 / 12%)",
                                width: row.isActive === true ? "90%" : "70%",
                                borderRadius: "12px",

                                p: 0.4,
                                color:
                                  row.isActive === true
                                    ? "rgb(11, 129, 90)"
                                    : "rgb(14, 112, 144)",
                                textAlign: "center",
                              }}
                            >
                              {row.isActive === true ? "PUBLISHED" : "DRAFT"}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Stack direction="row">
                            <Link
                              to={{
                                pathname: `/product/${row.productId}`,
                                state: { product: row },
                              }}
                            >
                              <Tooltip title="Edit" placement="left">
                                <IconButton variant="contained">
                                  <EditOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                            <Link
                              to={{
                                pathname: `${AppRoutes.PRODUCTVARIANTLIST}/${row.productId}`,
                                state: { product: row },
                              }}
                            >
                              <Tooltip
                                title="Add Product Variant"
                                placement="bottom"
                              >
                                <IconButton>
                                  <VisibilityOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>

                            {row.isActive === false && (
                              <Tooltip title="Delete" placement="right">
                                <IconButton sx={{ mr: "-18px" }}>
                                  <DeleteIcon
                                    onClick={() =>
                                      openDeleteDialog(row.productId)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
      <CommonDelete
        isDeleteOpen={isDeleteDialogOpen}
        setIsDeleteOpen={closeDeleteDialog}
        full={ProductAddList}
        table="product"
        deleteId={selectedProductId}
        getFunction={fetchProductsData}
        columnName="productId"
      />
    </div>
  );
};

export default ProductAddList;
