export const AppRoutes = {
  HOME: "/",
  VENDOR: "/Vendor/:id",
  DASHBOARD: "/dashboard",
  VENDORDASHBOARD: "/VendorDashboard",
  CATEGORY: "/category",
  BANNER: "/banner",
  ADDPRODUCT: "/productsList/addProduct",
  SUBCATEGORY: "/subcategory",
  PRODUCT: "/productsList",
  DISCOUNT: "/discount",
  EDITPAGE: "/discount/add",
  DISCOUNTEDIT: "/discount/edit/:id",
  VENDORLIST: "/vendor",
  BRANDNAME: "/brandname",
  VENDOR_WITHOUT_ID: "/registerVendor",
  ADDCATEGORY_WITHOUT_ID: "/addcategory",
  ADDCATEGORY: "/Category/:id",
  ADDSUBCATEGORY_WITHOUT_ID: "/addsubcategory",
  ADDSUBCATEGORY: "/subcategory/:id",
  ADDBRAND: "/brand/:id",
  ADDBRAND_WITHOUT_ID: "/addbrand",
  LIST: "/list",
  // INVENTORY: "/inventory/:id",
  PRODUCTADDLIST: "/productAddList",
  PRODUCTADD_WITHOUT_ID: "/productadd",
  PRODUCTADD: "/product/:id",
  PRODUCTVARIANTLIST: "/productVariantList",
  ADDPRODUCTVARIANT_WITHOUT_ID: "/addProductVariant",
  ADDPRODUCTVARIANT: "/productVariant",
  INVENTORYLIST: "/inventoryList",
  ADDINVENTORY_WITHOUT_ID: "/addInventory",
  ADDINVENTORY: "/inventory",
  ORDER: "/order",
  ORDERLINE: "/order/orderline",
  Emailverfiy: "/Confirm",
  Forgotpassword: "/forgotpassword",
  Resetpassword: "/resetpassword",
  RETURNORDERLIST: "/returnOrder",
  Transaction: "/transaction",
  TransactionDetials: "/transaction/detials",
  Refund: "/refund",
  UPDATEPROFILE: "/updateProfile/:id",
};

export const StorageBucket = {
  CATEGORY: "category",
  SUBCATEGORY: "subCategory",
  BRAND: "brand",
  PRODUCT: "product",
  BANNER: "banner",
  PRODUCTVARIANT: "productVarint",
};

export const TableName = {
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  PRODUCT: "product",
  BRAND: "brand",
  ADDRESS: "address",
  BANNER: "banner",
  DISCOUNT: "discount",
  INVENTORY: "inventory",
  LIST: "list",
  VENDOR: "vendor",
  PRODUCTADD: "product",
  PRODUCTVARIANT: "productvariant",
  ADDINVENTORY: "inventory",
  ORDER: "order",
  ORDERLINE: "order_line",
  USERPROFILE: "userProfile",
  RETURN: "returnOrder",
  TRANSACTION: "transaction",
};

export const razorpaydata = {
  RAZORPAY_KEY_ID: "rzp_test_VAWW2XRdQEoBJY",
  RAZORPAY_KEY_SECRET: "Z0LAvDKo2iDbunNrOi3PslZW",
};
const adminname = "Sachithanantham S";
const Admincontact = "cb@usglobalsolutions.com";
export const websiteDetials = {
  website_name: "childbazaar",
  contact_email: "Cb@usglobalsolutions",
  login_url: "/",
};
export const waticontent = {
  returnA:
    "Our team is now processing your return and will keep you updated on the progress.",
  returnR:
    "We are sorry to inform you that this product has no return options. If you have any queries please contact us on Cb@usglobalsolutions",
};
export const watiTemplateName = {
  onboard_vendor: {
    broadcast_name: "onboard_vendors",
    template_name: "onboard_vendor_2",
  },
  return_status: {
    broadcast_name: "order",
    template_name: "return_status",
  },
  order_status_1: {
    broadcast_name: "order",
    template_name: "order_status_2",
  },
};
export const emailcontent = {
  confirmsubject: ` Order Confirmed!`,
  confirmmessage: `Thank you for your purchase. We will send you a confirmation
                   when your order is shipped. Please find below the receipt of
                   your purchase`,
  confirmtype: `Your Order is Confirm`,
  //shipped
  shippedsubject: `Order Shipped!`,
  shippedmessage: `Thank you for your purchase. This is your confirmation mail regarding the shipment of your order. You will receive your order shortly!
  `,
  shippedtype: `Your Order is shipped`,
  // reject
  rejectsubject: ` Order Rejected`,
  rejecttype: `Your Order is Rejected`,
  rejectmessage: `Thank you for your order. Unfortunately, we encountered a technical issue that prevented us 
                  from processing your order. Our team is actively working to resolve this issue, and we assure you that your 
                  satisfaction is our top priority. Please rest assured that we are doing everything possible to rectify the 
                  situation promptly.
                  If you have any further questions or concerns, please contact us on  Cb@usglobalsolutions
                  Thank you for your understanding.`,
  // Delivered
  deliveredsubject: "Order Delivered!",
  deliveredtype: "Your Order is Delivered",
  deliveredmessage: `We are delighted to inform you that your recent order has been
  successfully processed and delivered. Your satisfaction is our utmost
  priority, and we hope that the products meet your expectations.`,
  /// return A
  returnsubject: "Return Accepted!",
  returntype: "Your Return Order is Accepted!!",
  returnmessage: `We're pleased to inform you that your return request for order #[OrderNumber]
   has been accepted. Your refund or exchange will be processed 
    promptly. Thank you for choosing Childbazaar.`,
  // return R
  returnRsubject: "Return Rejected!",
  returnRtype: "Your Return is Rejected!",
  returnRmessage: `We regret to inform you that your return request for order #[OrderNumber] has been declined . If you have any 
    questions or need further assistance, please don't hesitate to contact our 
    customer support team for further assistance.`,
  activestatusheader: `Your account is active`,
  activestatusmessage: `Your account has been active. please click below button to login your account 
`,
  activestatusbuttonlable: "Sign Up",
  activestatusbuttonlink: "https://vendor.childbazaar.in",
  activestatuscolor: "#58d268",
  inactivestatusheader: `Your account is deactivate `,
  inactivestatusmessage: `Your account has been deactivate by admin. please contact admin
  for further action`,
  inactivestatusbuttonlable: "Contact Us",
  inactivestatusbuttonlink: "https://www.childbazaar.in/contactus",
  inactivestatuscolor: "#e44545",
};
export const emailtemplate = {
  invitemail: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>
    </head>
    <body>
      <table style="display: flex; justify-content: center">
        <tr>
          <td>
            <div
              style="
                text-align: center;
                /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
                width: 75%;
              "
            >
              <div style="height: 70px; width: 100%; background-color: #ffd7e6">
                <img
                  src="https://res.cloudinary.com/dav7a8bxf/image/upload/f_auto,q_auto/cvli2yhk8e2kstdg655a"
                  alt="img"
                />
              </div>
              <div style="margin: 5%; text-align: left">
                <h1>Hi, <span style="color: #7c163d"><%= name %></span></h1>
                <p style="color: #6b6b6b; font-size: 18px; font-family: 'Inter'">
                  We are delighted to welcome you as a vendor on our e-commerce
                  platform! Below are your login credentials and guidelines to get
                  started
                </p>
              </div>
              <div
                style="
                  height: 45px;
                  width: 100%;
                  background-color: #ecc358;
                  color: white;
                  text-align: center;
                "
              >
                <h1 style="margin: 0">E-Commerce Vendor Registration</h1>
              </div>
              <table>
                <tr>
                  <div style="text-align: justify; margin: 5%">
                    <h4
                      style="color: black; font-size: 18px; font-family: 'Inter'"
                    >
                      Login Credentials
                    </h4>
                    <p
                      style="color: black; font-size: 18px; font-family: 'Inter'"
                    >
                      Username: <%= username %>
                    </p>
  
                    <p
                      style="color: black; font-size: 18px; font-family: 'Inter'"
                    >
                      Password: <%= Password %>
                    </p>
                    <br />
                    <h3>Guidelines:</h3>
  
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                      >
                        Uploads:</span
                      >
                      Ensure high-quality images and accurate product
                      descriptions.
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                        >Inventory Management:</span
                      >Regularly update stock availability to prevent overselling.
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                        >Order Fulfillment:</span
                      >
                      Process orders promptly to maintain customer satisfaction.
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                        >Customer Support:</span
                      >Address customer queries and concerns in a timely manner.
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                        >Promotions:</span
                      >
                      promotional tools to boost sales and visibility.
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                        >Payment Processing:</span
                      >
                      Familiarize yourself with our payment system for smooth
                      transactions.
                    </p>
  
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      For any queries or assistance, please contact ${adminname}
                      at ${Admincontact} .
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      We look forward to a successful partnership with you!
                    </p>
                    <p
                      style="
                        color: #6b6b6b;
                        font-size: 18px;
                        font-family: 'Inter';
                      "
                    >
                      If you have any queries
                      <span
                        style="font-weight: bold; font-size: 17px; color: black"
                        ><%= supportemail %></span
                      >
                    </p>
                  </div>
                </tr>
              </table>
              <div
                style="
                  height: 67px;
                  width: 100%;
                  background-color: #e9f6ff;
                  display: flex;
                  justify-content: center;
                "
              >
                <h3>Thank you for Ordering Us!!</h3>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </body>
  </html>
  `,
  refundemail: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>
    </head>
    <body>
      <table style="display: flex; justify-content: center">
        <tr>
          <td>
            <div
              style="
                text-align: center;
                /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
                width: 75%;
              "
            >
              <div style="height: 70px; width: 100%; background-color: #ffd7e6">
                <img
                  src="https://res.cloudinary.com/dav7a8bxf/image/upload/f_auto,q_auto/cvli2yhk8e2kstdg655a"
                  alt="img"
                />
              </div>
              <div style="margin: 5%; text-align: left">
                <h1>Hi, <span style="color: #7c163d"> <%= name %> </span></h1>
                <p style="color: #6b6b6b; font-size: 18px; font-weight: bold">
                  We want to confirm that your refund for order <%= orderid %>  has 
                  been successfully processed, and will be refunded to your 
                  original payment within 1 business week
                </p>
              </div>
              <div
                style="
                  height: 45px;
                  width: 100%;
                  background-color:#d25886;
                  color: white;
                  text-align: center;
                "
              >
                <h1 style="margin: 0">Your refund has been processed!!</h1>
              </div>
              <h4>Order ID : <%= orderid %></h4>
              <table style="width: 100%">
                <tr style="color: #7c163d">
                  <th style="width: 35%">ORDER DETAILS</th>
                  <th>QTY</th>
                  <th>PRICE</th>
                </tr>
                <tbody>
                <% records.map(function(record,index) { %>
                  <tr>
                    <td style="display: flex; gap: 10px; justify-content: center">
                      <img
                        width="50"
                        height="50"
                        src= <%= JSON.parse(record.productImage)[0] %>
                      alt="" />
                      <div style="display: inline-block; flex-direction: row">
                        <span
                          style="
                            color: #616161;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: left;
                          "
                        >
                          <a
                            href= <%= "https://www.childbazaar.in/ProductDetails?id="+record.productId %>
                            > <%= record.productName %>
                          </a>
                        </span>
                        <span
                          style="
                            color: #616161;
                            font-size: 15px;
                            font-weight: bold;
                            text-align: left;
                          "
                        >
                        size: <%= sizeList[record.size] %>
                        </span>
                        <span
                          style="
                            color: #616161;
                            font-size: 14px;
                            text-align: left;
                          "
                        >
                         color: <%= record.color %>
                        </span>
                      </div>
                    </td>
                    <td style="text-align: center">
                      <%= record.quantity %>
                    </td>
                    <td style="text-align: center">
                      <%= parseFloat(record.productPrice).toFixed(2) %>
                    </td>
                  </tr>
                  <% }); %>
                </tbody>
              </table>
              <table>
                <tr>
                  <hr
                    style="display: flex; justify-content: center; width: 80%"
                  />
                </tr>
              </table>
              <br />
              <div
                style="
                  height: 67px;
                  width: 100%;
                  background-color: #e9f6ff;
                  display: flex;
                  justify-content: center;
                "
              >
                <h3>Thank you for shopping with us</h3>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </body>
  </html>`,
  confirm: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>
    </head>
    <body>
      <table style="display: flex; justify-content: center">
        <tr>
          <td>
            <div
              style="
                text-align: center;
                /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
                width: 75%;
              "
            >
              <div style="height: 70px; width: 100%; background-color: #ffd7e6">
                <img
                  src="https://res.cloudinary.com/dav7a8bxf/image/upload/f_auto,q_auto/cvli2yhk8e2kstdg655a"
                  alt="img"
                  style=""
                />
              </div>
              <div style="margin: 5%; text-align: left">
                <h1>Hi  <%= name %>, <span style="color: #7c163d"></span></h1>
                <p style="color: #6b6b6b; font-size: 18px; font-weight: bold">
                <%= message %>
                </p>
              </div>
              <div
                style="
                  height: 50px;
                  width: 100%;
                  background-color:<%= color %>;
                  color: white;
                  text-align: center;
                "
              >
                <h1 style="margin: 0"> <%= ordertype %> !!</h1>
              </div>
              <h4>Order ID : <%= orderid %></h4>
              <table id="myTable" style="width: 100%">
                <tr style="color: #7c163d">
                  <th style="width: 35%">ORDER DETAILS</th>
                  <th>QTY</th>
                  <th>PRICE</th>
                </tr>
                <tbody>
                <% records.map(function(record,index) { %>
                <tr>
                  <td style="display: flex; flex-direction: row; gap: 10px; justify-content: center">
                    <img
                      width="100"
                      height="100"
                      src=<%= JSON.parse(record.productImage)[0] %>
                    alt="" />
                    <div style="display: inline-block; flex-direction: row">
                      <span
                        style="
                          color: #616161;
                          font-size: 18px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <a
                          href= <%= "https://www.childbazaar.in/ProductDetails?id="
                          +
                          record.productId
                          %>
                          > <%= record.productName %>
                        </a>
                      </span>
                      <span
                        style="
                          color: #616161;
                          font-size: 15px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                      size: <%=sizeList[record.size] %>
                      </span>
                      <span
                        style="
                          color: #616161;
                          font-size: 14px;
                          text-align: left;
                        "
                      >
                       color: <%= record.color %>
                      </span>
                    </div>
                  </td>
                  <td style="text-align: center">
                    <%= record.quantity %>
                  </td>
                  <td style="text-align: center">
                    <%= parseFloat(record.productPrice).toFixed(2) %>
                  </td>
                </tr>
                <% }); %>
              </tbody>
              </table>
              <table>
              <% if (feedback) { %>
                <tr>
                <td >
                  <a
                    href= <%= "https://www.childbazaar.in/account/orderdetails?orderId="+ orderid  %>
                    style="
    display: inline-block;
    width: 100%;
    padding: 12px 0px 12px 0px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
    background-color: #7c163d;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
">
                      Give Feedback
                    </button>
                  </a>
                </td>
              </tr>
            <% } else { %>
              <tr>
              <hr
                style="display: flex; justify-content: center; width: 80%"
              />
            </tr>
          </table>

          <table style="width: 84%; text-align: right">
            <tr>
              <td style="color: #6b6b6b; font-weight: bold">
                Total :<span style="color: #7c163d"
                  ><%= parseFloat(total).toFixed(2) %></span
                >
              </td>
            </tr>

            <tr>
              <td style="color: #6b6b6b; font-weight: bold">
                Shipping Charge :<span style="color: #7c163d"
                  ><%= parseFloat(shipping).toFixed(2) %></span
                >
              </td>
            </tr>

            <tr>
              <td style="font-weight: bold; font-size: 20px">
                Grand Total :
                <span style="color: #7c163d"
                  ><%= parseFloat(shipping+total).toFixed(2) %></span
                >
              </td>
            </tr>
          </table>
          <table style="width: 100%" cellspacing="20">
            <tr>
              <td style="width: 10%"></td>
              <td
                style="
                  width: 30%;
                  text-align: center;
                  border: 2px solid #d9d9d9;
                  border-radius: 10px;
                  background-color: #f2f2f2;
                  padding: 10px;
                "
              >
                <h3 style="color: #7c163d">DELIVERY ADDRESS</h3>
                <p style="color: #6b6b6b"><%= deliveryAddress %>,</p>
              </td>
              <td
                style="
                  width: 30%;
                  text-align: center;
                  border: 2px solid #d9d9d9;
                  border-radius: 10px;
                  background-color: #f2f2f2;
                  padding: 10px;
                "
              >
                <h3 style="color: #7c163d">BILLING ADDRESS</h3>
                <p style="color: #6b6b6b"><%= billingAdress %></p>
              </td>
              <td style="width: 10%"></td>
            </tr>
            <% } %>
            
              </table>
  
              <div
                style="
                  height: 50px;
                  width: 100%;
                  background-color: #e9f6ff;
                  display: flex;
                  justify-content: center;
                  text-align: center;
                "
              >
          Thank you for shopping with us
              </div>
            </div>
          </td>
        </tr>
      </table>
    </body>
  </html>
  `,
  statusemail: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>
    </head>
    <body>
      <table style="display: flex; justify-content: center">
        <tr>
          <td>
            <div
              style="
                text-align: center;
                /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
                width: 75%;
              "
            >
              <div
                style="
                  height: 70px;
                  width: 100%;
                  background-color: #ffd7e6;
                  display: flex;
                  justify-content: center;
                "
              >
                <img
                  src="https://res.cloudinary.com/dav7a8bxf/image/upload/f_auto,q_auto/cvli2yhk8e2kstdg655a"
                  alt="img"
                />
              </div>
              <div
                style="
                  margin: 5%;
                  text-align: left;
                  color: #6b6b6b;
                  font-size: 18px;
                  font-family: 'Inter';
                "
              >
                <h1>Hi, <span style="color: #7c163d"><%= name %></span></h1>
                <p>
                <%= statusmessage %>
                </p>
                <div style="display: flex; justify-content: center ;">
                  <a
                    style="
                      background-color: #7c163d;
                      color: white;
                      border: 1px solid #7c163d;
                      border-radius: 4px; 
                      text-decoration-line: none
                      
                    "
                    href= <%= buttonlink %>
                  >
                  <%= buttonlable %>
                  </a>
                </div>
              </div>
              <div
                style="
                  height: 45px;
                  width: 100%;
                  background-color: <%= statuscolor %>;
                  color: rgb(255, 255, 255);
                  text-align: center;
                "
              >
              <p></p>
                <h1 style="margin: 0"><%= statusheader %></h1>
              </div>
              <table>
                <tr>
                 
  
                  <p
                    style="
                      color: #6b6b6b;
                      font-size: 18px;
                      font-family: 'Inter';
                      text-align: left;
                      margin: 5%;
                    "
                  >
                    Thanks, for choosing Childbazaar
                  </p>
                </tr>
              </table>
              <div
                style="
                  height: 67px;
                  width: 100%;
                  background-color: #e9f6ff;
                  display: flex;
                  justify-content: center;
                "
              >
                <h3>Thanks, for choosing Childbazaar!</h3>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </body>
  </html>
  `,
};
