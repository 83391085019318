import React, { useEffect, useState } from "react";
import useSupabase from "../utils/useSupabase";
import childbazaarLogo from "../Image/childbazaarLogo.jpg";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import RestoreIcon from "@mui/icons-material/Restore";
import { AppRoutes } from "../../src/constant";
export default function Resetpassword() {
  const [conform_password, setConformPassword] = useState("");
  const [new_password, setNewPassword] = useState("");

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [pathvalue, setpathvalue] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const existingParams = Object.fromEntries(url.searchParams.entries());
    const { token, error } = existingParams;
    setpathvalue(url.hash);
  }, []);

  const [form, setForm] = useState(true);

  const handleSubmit = async () => {
    const url = new URL(window.location.href);
    const existingParams = Object.fromEntries(url.searchParams.entries());
    const { token } = existingParams;
    setpathvalue(url);

    if (!new_password || !conform_password) {
      setError("Please enter both passwords.");
      return;
    }

    if (new_password !== conform_password) {
      setError("Passwords do not match.");
      return;
    }

    if (new_password.length < 8 || conform_password.length < 8) {
      setError("Passwords must be at least 8 characters long.");
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: new_password,
    });

    if (data) {
      setForm(false);
    } else {
      setError("New password and confirm password do not match.");
    }
  };

  const updateConformPassword = (e) => {
    setConformPassword(e.target.value);
    setError("");
  };

  const updateNewPassword = (e) => {
    setNewPassword(e.target.value);
    setError("");
  };
  if (
    pathvalue ===
    "#error=unauthorized_client&error_code=401&error_description=Email+link+is+invalid+or+has+expired"
  ) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            padding: "30px 50px 50px 50px",
            borderRadius: "5px",
            maxWidth: "md",
            width: "30%",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40%",
              paddingBottom: "10px",
              paddingRight: "30px",
            }}
          >
            <img
              src={childbazaarLogo}
              alt="Childbazaar Logo"
              edge="start"
              width={49}
              height={39}
            />
            <p>
              <b style={{ color: "#e41161" }}>Child</b>
              <b style={{ color: "#097a9f" }}>bazaar.com</b>
            </p>
          </div>
          <span>
            <RestoreIcon sx={{ fontSize: 60 }} color="primary" />
          </span>
          <h2 style={{ margin: "15px 0px 5px 5px" }}>This link has expired</h2>
          <h4 style={{ margin: "5px 0px 25px 0px" }}>
            Please request a new one
          </h4>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                navigate(AppRoutes.Forgotpassword);
              }}
              size="medium"
              variant="contained"
              color="primary"
              sx={{ width: "100px" }}
            >
              ok
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (pathvalue) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        {form ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              padding: "30px 40px 40px 40px",
              borderRadius: "5px",
              maxWidth: "md",
              width: "30%",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={childbazaarLogo}
                alt="Childbazaar Logo"
                edge="start"
                style={{}}
                width={49}
                height={39}
              />
              <p>
                <b style={{ color: "#e41161" }}>Child</b>
                <b style={{ color: "#097a9f" }}>bazaar.com</b>
              </p>
            </div>
            <h3
              style={{
                fontSize: "2xl",
                fontWeight: "semibold",
              }}
            >
              Reset Password
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <TextField
                sx={{ marginY: 2 }}
                required
                fullWidth
                type="password"
                name="password"
                label="New Password"
                placeholder="New Password"
                onChange={updateConformPassword}
                style={{
                  marginTop: "1px",
                  padding: "2px",
                  width: "full",
                  border: "rounded-md",
                }}
              />
            </div>
            <div>
              <TextField
                sx={{ marginBottom: 2 }}
                required
                type="password"
                name="password"
                fullWidth
                label="Confirm Password"
                placeholder="Confirm Password"
                onChange={updateNewPassword}
                style={{
                  padding: "2px",
                  width: "full",
                  border: "rounded-md",
                }}
                error={error && Boolean(error)}
                helperText={error && error}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 4,
              }}
            >
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "10px",
                }}
              >
                Reset Password
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              padding: "25px",
              borderRadius: "5px",
              maxWidth: "md",
              width: "30%",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "20%",
              }}
            >
              <img
                src={childbazaarLogo}
                alt="Childbazaar Logo"
                edge="start"
                style={{}}
                width={49}
                height={39}
              />
              <p>
                <b style={{ color: "#e41161" }}>Child</b>
                <b style={{ color: "#097a9f" }}>bazaar.com</b>
              </p>
            </div>
            <h3
              style={{
                fontSize: "2xl",
                fontWeight: "semibold",
                flexGrow: 1,
              }}
            >
              Password changed successfully
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                flexGrow: 8,
              }}
            >
              <div style={{ flexGrow: 8 }}>
                <LockResetIcon sx={{ fontSize: "500%" }} color="success" />
              </div>

              <Button
                sx={{ flexGrow: 2, marginY: 3, width: "150px" }}
                onClick={() => {
                  navigate("/");
                }}
                fullWidth
                variant="contained"
              >
                Login
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}
