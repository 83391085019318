import {
  Box,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppRoutes, TableName } from "../constant";
import { Search } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SelectedFilter from "./SelectedFilter";
import CommonFilter from "./CommonFilter";
import useSupabase from "../utils/useSupabase";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { Link, useParams } from "react-router-dom";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { getListData } from "../utils/helperFunction";
import VendorHeader from "../VendorHeader";
import Tooltip from "@mui/material/Tooltip";

const ProductVariantList = () => {
  const rowsPerPage = 4;
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [colorList, setColorList] = useState({});
  const [varint, setVarint] = useState([]);
  const supabase = useSupabase();
  const [filter, setFilter] = useState([]);
  const { productId } = useParams();
  useEffect(
    () => {
      fetchCategoryData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  const getColorList = async () => {
    const colors = await getListData("Color");
    if (colors) {
      const colorData = {};
      colors.forEach((item) => {
        colorData[item.listItem] = item.listDescription;
      });
      setColorList(colorData);
    }
  };
  useEffect(
    () => {
      getColorList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function fetchCategoryData() {
    if (filter && filter?.length > 0) {
      const { data } = await supabase
        .from(TableName.PRODUCTVARIANT)
        .select()
        .eq("productId", productId)
        .in(
          "isActive",
          filter.map((_fil) => _fil.value)
        );
      setVarint(data);
    } else {
      const { data, error } = await supabase
        .from(TableName.PRODUCTVARIANT)
        .select()
        .eq("productId", productId);

      setVarint(data);
      if (error) {
        console.log(error);
      }
    }
  }
  const handleSearch = async (event) => {
    const searchTerm = event.target.value;

    if (searchTerm.trim() !== "") {
      try {
        const { data, error } = await supabase
          .from(TableName.PRODUCTVARIANT)
          .select()
          .eq("productId", productId)
          .textSearch("variantName", `'${searchTerm}'`, {
            config: "english",
          });

        if (error) {
          console.error("Error fetching variant:", error);
        } else {
          setVarint(data);
        }
      } catch (error) {
        console.error("Error performing search:", error);
      }
    } else {
      fetchCategoryData();
    }
  };
  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
  };
  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Link to={AppRoutes.PRODUCTADDLIST} style={{ textDecoration: "none" }}>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ArrowBackSharpIcon />
            <Typography
              sx={{ color: "rgb(17, 25, 39)", fontWeight: "inherit" }}
            >
              Product
            </Typography>
          </IconButton>
        </Link>
        <VendorHeader
          title="Product Variant"
          subtitle="Product Variant"
          subtitle1="List"
          showAddButton={true}
          linkProps={{
            to: `${AppRoutes.ADDPRODUCTVARIANT_WITHOUT_ID}/${productId}`,
          }}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ mt: "5px", p: "7px", ml: "-5px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by Variant name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <CommonFilter
            id="isactive"
            title="Active"
            options={[
              { code: "yes", value: "Yes" },
              { code: "no", value: "No" },
            ]}
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell align="center" className="cell"></TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Color
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {varint?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.variantImage && (
                        <>
                          {JSON.parse(item.variantImage)
                            .slice(0, 1)
                            .map((variantImage, index) => (
                              <CardMedia
                                key={index}
                                component="img"
                                image={variantImage}
                                alt={`Product ${index}`}
                                style={{ width: 90, height: 90 }}
                              />
                            ))}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {item.variantName}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {colorList[item.variantColor] || item.variantColor}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      {item.isActive ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="center" className="cell">
                      <Link
                        to={{
                          pathname: `${AppRoutes.ADDPRODUCTVARIANT}/${item.productId}/${item.variantId}`,
                          state: { product: item },
                        }}
                      >
                        <Tooltip title="Edit" placement="left">
                          <IconButton variant="contained">
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Link
                        to={{
                          pathname: `${AppRoutes.INVENTORYLIST}/${item.productId}/${item.variantId}`,
                        }}
                      >
                        <Tooltip title="Add Inventory" placement="bottom">
                          <IconButton>
                            <Inventory2OutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={varint?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default ProductVariantList;
