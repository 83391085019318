import React, { useState, useEffect, useRef } from "react";
import { AppRoutes, TableName } from "../../constant";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Box,
  TablePagination,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";

import "../Discount/Discount.css";
import useSupabase from "../../utils/useSupabase";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import VendorHeader from "../../VendorHeader";
import Tooltip from "@mui/material/Tooltip";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Popover,
  Paper,
  Stack,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Cancel } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";

function Order() {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [printData, setPrintData] = useState(null);
  const [order, setOrder] = useState([]);
  const [page, setPage] = useState(0);
  const [firmName, setFirmName] = useState("");
  const [address, setAddress] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const vendorid = localStorage.getItem("ids");
        const { data, error } = await supabase
          .from(TableName.VENDOR)
          .select("FirmName,Address")
          .eq("id", vendorid)
          .single();

        if (error) {
          console.error(error);
        } else {
          if (data) {
            setFirmName(data.FirmName);
            setAddress(data.Address);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [supabase]);
  const handlePrint = async (orderId) => {
    try {
      const { data: orderDetails, error } = await supabase
        .from(TableName.ORDER)
        .select("address,orderId,orderDate,customer_name")
        .eq("orderId", orderId)
        .single();

      if (error) {
        console.error(error);
        return;
      }

      if (orderDetails) {
        if (orderDetails.orderId === orderId) {
          setIsPrinting(true);
          setPrintData(orderDetails);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(
    () => {
      if (isPrinting && printData) {
        handlePrintAction();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [isPrinting, printData]
  );
  const handlePrintAction = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 style={{ marginTop: "10px", paddingLeft: "10px" }}>Ship To :</h5>
        <h4
          style={{
            marginTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          www.childbazaar.in
        </h4>
      </div>

      {printData && (
        <div>
          <p
            style={{
              paddingLeft: "10px",
              marginTop: "-12px",
              fontSize: "13px",
              width: "200px",
              whiteSpace: "pre-wrap",
            }}
          >
            {printData.customer_name} , {printData.address}
          </p>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
          >
            <h5 style={{ margin: 0 }}>Order Id : </h5>
            <p
              style={{
                margin: 0,
                marginLeft: "3px",
                fontSize: "13px",
                whiteSpace: "pre-wrap",
              }}
            >
              {printData.orderId}
            </p>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
          >
            <h5 style={{ margin: 0 }}>Order Date : </h5>
            <p
              style={{
                margin: 0,
                marginLeft: "3px",
                fontSize: "13px",
                whiteSpace: "pre-wrap",
              }}
            >
              {printData.orderDate}
            </p>
          </div>
          <br />
          <br />
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
          >
            <h5 style={{ margin: 0 }}>Sold By : </h5>
            <p
              style={{
                margin: 0,
                marginLeft: "3px",
                fontSize: "13px",
                whiteSpace: "pre-wrap",
              }}
            >
              {firmName}
            </p>
          </div>
          <div style={{ marginLeft: "8px" }}>
            <h5 style={{ margin: 0 }}>Address : </h5>
            <p
              style={{
                margin: 0,
                paddingTop: "4px",
                marginLeft: "3px",
                fontSize: "13px",
                whiteSpace: "pre-wrap",
                width: "200px",
              }}
            >
              {address}
            </p>
          </div>
        </div>
      )}
    </div>
  ));
  const listorder = async (pageNumber, pageSize, statusFilter) => {
    try {
      const vendorid = localStorage.getItem("ids");
      const { data, error } = await supabase.rpc("list_order", {
        pagenumber: pageNumber,
        pagesize: pageSize,
        status: statusFilter ? statusFilter : null,
        vendorid: vendorid,
      });

      if (error) {
        console.error(error);
      } else {
        if (data.length > 0) {
          setOrder(data);
          setTotalCount(data[0].totalcount);
          setPage(pageNumber);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(
    () => {
      // Initial load with null status
      listorder(0, rowsPerPage, null);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      // Update based on selectedStatus
      listorder(0, rowsPerPage, selectedStatus);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStatus]
  );

  useEffect(() => {}, [order, totalCount, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const fetchData = async () => {
      if (selectedStatus.length > 0) {
        await listorder(newPage, rowsPerPage, selectedStatus);
      } else {
        await listorder(newPage, rowsPerPage, null);
      }
    };

    fetchData();
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);

    setRowsPerPage(newSize);
    setPage(0);

    listorder(0, newSize, null);
  };

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChange = async (item) => {
    const isSelected = selectedStatus.includes(item.value);

    if (isSelected) {
      // Unselecting the status
      const updatedStatus = selectedStatus.filter(
        (status) => status !== item.value
      );
      setSelectedStatus(updatedStatus);
    } else {
      // Selecting the status
      setSelectedStatus([...selectedStatus, item.value]);
    }

    // Make API call with the updated status
    const newStatusFilter = isSelected
      ? selectedStatus.filter((status) => status !== item.value)
      : [...selectedStatus, item.value];

    if (newStatusFilter.length > 0) {
      await listorder(0, rowsPerPage, newStatusFilter); // Always reset to page 0 when changing filters
    } else {
      await listorder(page, rowsPerPage, null); // Use the current page when no filter is selected
    }
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "popover" : undefined;

  const options = [
    { code: "Pending", value: "Pending" },
    { code: "Cancel", value: "Cancelled" },
    { code: "Shipped", value: "Shipped" },
    { code: "Delivered", value: "Delivered" },
    { code: "Returned", value: "Returned" },
    { code: "Confirmed", value: "Confirmed" },
    { code: "Rejected", value: "Rejected" },
  ];

  const handleChipCancel = (canceledItem) => {
    const updatedStatus = selectedStatus.filter(
      (status) => status !== canceledItem
    );
    setSelectedStatus(updatedStatus);

    const newStatusFilter = updatedStatus.length > 0 ? updatedStatus : null;

    const fetchData = async () => {
      await listorder(0, rowsPerPage, newStatusFilter);
    };

    fetchData();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <VendorHeader title="Order" subtitle="Order" subtitle1="Order line" />
        <div className="topContent">
          <div className="filterBox">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              {selectedStatus?.length > 0 ? (
                selectedStatus?.map((category, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "50px",
                      backgroundColor: "white",
                      padding: "2px 5px",
                      marginRight: "10px",
                      marginBottom: "15px",
                      border: "1px solid gray",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(47, 55, 70)",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      status:
                    </Typography>

                    <Typography
                      sx={{ marginLeft: "5px", fontSize: "12px" }}
                      variant="body2"
                    >
                      {category}
                    </Typography>
                    <IconButton
                      onClick={() => handleChipCancel(category)}
                      size="small"
                      sx={{ color: "gray" }}
                    >
                      <Cancel />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "rgb(133 136 141)",
                    fontSize: "13px",
                    letterSpacing: "0.5px",
                    marginBottom: "15px",
                    marginLeft: "13px",
                  }}
                >
                  {`No filters applied`}
                </Typography>
              )}
            </Box>
          </div>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>

          <div className="filterBox">
            <Box className="filter" onClick={handleDropdownClick}>
              <Stack direction="row">
                <Typography
                  className="filterText"
                  sx={{
                    color: "rgb(47, 55, 70)",
                    fontWeight: "600",
                    fontSize: "12.5px",
                    mt: "9px",
                    cursor: "pointer",
                    marginLeft: "13px",
                  }}
                >
                  Status
                </Typography>
                <KeyboardArrowDownOutlinedIcon
                  sx={{ marginTop: "7PX", cursor: "pointer" }}
                />
              </Stack>
            </Box>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Paper>
                <div style={{ padding: "16px" }}>
                  {options ? (
                    options.map((option) => (
                      <div key={option.code}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              type="checkbox"
                              checked={selectedStatus.includes(option.value)}
                              onChange={() => handleChange(option)}
                            />
                          }
                          label={option.value}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No options available</p>
                  )}
                </div>
              </Paper>
            </Popover>
          </div>
        </div>
        <div style={{ display: isPrinting ? "none" : "none" }}>
          <ComponentToPrint ref={componentRef} />
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer className="tableContainer">
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Order Id
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Ordered Date
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Expected Delivery Date
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order
                  //.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.orderid}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.orderdate}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.orderstatus}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {item.deliverydate}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              gap: "7",
                              cursor: "pointer",
                            }}
                          >
                            <Tooltip
                              title="Orderline Details"
                              placement="right"
                            >
                              <IconButton>
                                <ArrowForwardIosIcon
                                  sx={{ color: "gray", fontSize: "20px" }}
                                  onClick={() =>
                                    navigate(`${AppRoutes.ORDERLINE}`, {
                                      state: { orderId: item.orderid },
                                    })
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Print Order Details">
                              <IconButton
                                onClick={() => handlePrint(item.orderid)}
                              >
                                <PrintOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
    </div>
  );
}

export default Order;
