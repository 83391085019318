import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomTextField from "../CustomTextField";
import useSupabase from "../utils/useSupabase";
import { AppRoutes, TableName } from "../constant";
import { Link, useNavigate } from "react-router-dom";
import { Cartcontext } from "../Products/Context";
import { useContext } from "react";
import { getListData } from "../utils/helperFunction";
import VendorSubHeader from "../VendorSubHeader";

const AddInventory = () => {
  const params = useParams();
  const GlobalState = useContext(Cartcontext);
  const navigate = useNavigate();
  const showToast = GlobalState.showToast;
  const supabase = useSupabase();
  const [sizeList, setSizeList] = useState([]);
  const { variantId } = useParams();
  const { productId } = useParams();
  const { inventoryId } = useParams();
  const validationSchema = yup.object({
    quantity: yup.string().required("Quantity is required"),

    price: yup.string().required("Price is required"),
    size: yup.string().required("Size is required"),
  });
  useEffect(
    () => {
      const fetchInventoryData = async () => {
        if (inventoryId) {
          const { data, error } = await supabase
            .from(TableName.ADDINVENTORY)
            .select()
            .eq("inventoryId", inventoryId);

          if (error) {
            console.error("Error fetching inventory data:", error.message);
          } else if (data && data.length > 0) {
            const inventory = data[0];
            formik.setValues({
              quantity: inventory.quantity,
              price: inventory.price,
              size: inventory.size,
              isActive: inventory.isActive,
            });
          }
        }
      };
      fetchInventoryData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: {
      quantity: "",
      price: "",
      size: "",
      isActive: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (inventoryId) {


       
  
    
  
        
        const { data } = await supabase
          .from(TableName.ADDINVENTORY)
          .select("*")
          .neq("inventoryId", inventoryId)
          .eq("size", values.size)
          .eq("variantId", variantId);
        if (data.length !== 0) {
          alert("This size already exists");
        } else {
          const { data, error } = await supabase
            .from(TableName.ADDINVENTORY)
            .update([
              {
                productId: productId,
                variantId: variantId,
                quantity: values.quantity,
                price: values.price,
                size: values.size,
                remainingQuantity: values.quantity,
                isActive: values.isActive,
              },
            ])
            .eq("inventoryId", inventoryId);
          if (!error) {
            console.log("category data update", data);

            navigate(`${AppRoutes.INVENTORYLIST}/${productId}/${variantId}`);
            showToast("update", "Inventory");
          }
        }
      } else {
        const { data } = await supabase
          .from(TableName.ADDINVENTORY)
          .select("*")
          .eq("size", values.size)
          .eq("variantId", variantId);
        if (data.length !== 0) {
          alert("This size already exists");
        } else {
          try {
            const { error: insertError } = await supabase
              .from(TableName.ADDINVENTORY)
              .insert([
                {
                  productId: productId,
                  variantId: variantId,
                  quantity: values.quantity,
                  price: values.price,
                  size: values.size,
                  remainingQuantity: values.quantity,
                  isActive: values.isActive,
                },
              ]);


              const { data: updateProductData, error: updateProductError } = await supabase
              .from(TableName.PRODUCT)
              .update({
                price: values.price,
              })
              .eq('productId', productId);
           

            if (insertError) {
              console.error("Error inserting data:", insertError.message);
              return;
            }

            navigate(`${AppRoutes.INVENTORYLIST}/${productId}/${variantId}`);
            showToast("success", "Inventory");
          } catch (error) {
            console.error("Error:", error.message);
          }
        }
      }
    },
  });

 
  useEffect(() => {
    const fetchSizeData = async () => {
      const sizes = await getListData("Size");
      if (sizes && sizes?.length > 0) {
        setSizeList(sizes);
      }
    };

    fetchSizeData();
  }, []);
  return (
    <div>
      <VendorSubHeader
        title={params.inventoryId ? "Edit a Inventory " : "Add a new Inventory"}
        subtitle="Inventory"
        back="Inventory"
        linkProps={{
          to: `${AppRoutes.INVENTORYLIST}/${productId}/${variantId}`,
        }}
      />
      <div style={{ padding: "27px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Inventory Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide inventory details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="quantity"
                      label="Quantity*"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.quantity &&
                        Boolean(formik.errors.quantity)
                      }
                    />
                    {formik.touched.quantity && formik.errors.quantity && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-10px",
                        }}
                      >
                        {formik.errors.quantity}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ pt: "10px" }}>
                    <CustomTextField
                      name="price"
                      label="Price*"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.price && Boolean(formik.errors.price)
                      }
                    />
                    {formik.touched.price && formik.errors.price && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-10px",
                        }}
                      >
                        {formik.errors.price}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ pt: "10px" }}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{ mt: "-7px" }}
                        error={
                          formik.touched.size && Boolean(formik.errors.size)
                        }
                      >
                        Size*
                      </InputLabel>
                      <Select
                        name="size"
                        label="Size*"
                        onChange={formik.handleChange}
                        value={formik.values.size}
                        error={
                          formik.touched.size && Boolean(formik.errors.size)
                        }
                        onBlur={formik.handleBlur}
                        sx={{
                          width: "80%",
                          height: "40px",
                        }}
                      >
                        {sizeList.map((size) => (
                          <MenuItem key={size.listItem} value={size.listItem}>
                            {size.listDescription}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.size && formik.errors.size && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "2px",
                          }}
                        >
                          {formik.errors.size}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Inventory Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your inventory status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={`${AppRoutes.INVENTORYLIST}/${productId}/${variantId}`}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddInventory;
