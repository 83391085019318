import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
  Divider,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  DialogContentText,
  Stack,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import useSupabase from "../utils/useSupabase";
import { AppRoutes, emailtemplate, watiTemplateName } from "../constant";
import SelectedFilter from "./SelectedFilter";
import CommonFilter from "./CommonFilter";
import { TableName } from "../constant";
import dayjs from "dayjs";
import { useFormik } from "formik";
import validationSchema from "./ValidationSchema";
import PhoneInput from "react-phone-input-2";
import { websiteDetials } from "../constant";
import { sendTemplateMessages } from "../Services/wati";
import { Cartcontext } from "../Products/Context";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

const VendorList = () => {
  const GlobalState = useContext(Cartcontext);
  const showToast = GlobalState.showToast;
  const navigate = useNavigate();
  const [vendorData, setVendorData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = React.useState(false);
  const supabase = useSupabase();
  const [openDialog, setOpenDialog] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  const [isLoading, setisLoading] = useState(false);

  const handleChange = (value) => {
    setCountryCode(value);
  };

  const [formData] = useState({
    firmName: "",
    mobile: "",
    email: "",
  });
  const formik = useFormik({
    initialValues: formData,
    firmName: "",
    mobile: "",
    email: "",
    validationSchema,
  });
  const sendemail = async (data) => {
    const edata = {
      message: "Onboarding Vendor",
      email: data.toemail,
      content: { template: data.template, emailVariables: data.emailVariables },
    };

    const email = await fetch(`${process.env.REACT_APP_CB_URL}api/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(edata),
    });
  };
  useEffect(
    () => {
      fetchVendorData(0, searchTerm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  const getFilters = (id) => {
    return filter
      ?.filter((_data) => _data.id === id)
      ?.map((_data) => _data.code);
  };
  async function fetchVendorData(newPage, searchKey) {
    try {
      let query = supabase
        .from(TableName.VENDOR)
        .select("*", { count: "exact" })
        .order("created_at", { ascending: false });

      if (searchKey.trim() !== "") {
        query = query.ilike("FirmName", `%${searchKey}%`);
      }

      const isActive = getFilters("isactive");
      const isProfileComplete = getFilters("profile");

      if (isActive && isActive.length > 0) {
        query = query.in("isActive", isActive);
      }

      if (isProfileComplete && isProfileComplete.length > 0) {
        query = query.in("isProfileComplete", isProfileComplete);
      }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);
      setVendorData(data || []);
      setTotalItems(count || 0);
      if (error) {
        console.error("Error fetching Vendor:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);

    if (searchTerm === "") {
      setPage(0);
    }
    setTimeout(() => {
      fetchVendorData(0, event.target.value);
    }, 500);
  };

  const handleChangePage = (event, newPage) => {
    fetchVendorData(newPage, searchTerm);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };
  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setisLoading(false);
    formik.resetForm();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const handleSubmit = async (event) => {
    //setOpen(false);
    event.preventDefault();
    Object.keys(formik.values).forEach((key) => {
      formik.setFieldTouched(key, true);
    });
    if (!formik.values.firmName.trim()) {
      return;
    }
    if (!formik.values.mobile.trim()) {
      return;
    }
    if (!formik.values.email.trim()) {
      return;
    }
    const isValid = await formik.validateForm();

    // If form is not valid, do not submit
    if (!isValid) {
      setisLoading(false);
      setOpen(true);
      return;
    } else {
      setisLoading(true);
    }

    const { data: emailCheckData } = await supabase
      .from(TableName.VENDOR)
      .select()
      .eq("Email", formik.values.email);

    if (emailCheckData.length > 0) {
      setOpenDialog(true);
      return;
    }

    const { data: signUpData, error: signUpError } = await supabase.auth.signUp(
      {
        email: formik.values.email,
        password: formik.values.mobile,

        options: {
          emailRedirectTo: `${process.env.REACT_APP_CB_ADMIN_URL}confirm`,
          data: {
            password: formik.values.mobile,
          },
        },
      }
    );

    const { error: profileError } = await supabase
      .from(TableName.USERPROFILE)
      .insert({
        userUID: signUpData.user?.id,
        email: formik.values.email,
        userType: "Vendor",
      });
    const commissionValue =
      formik.values.commission === "" ? "0" : formik.values.commission;
    const { error } = await supabase.from(TableName.VENDOR).insert([
      {
        FirmName: formik.values.firmName,
        MobileNo: formik.values.mobile,
        Email: formik.values.email,
        commission: commissionValue,
        isProfileComplete: false,
        defaultPass: true,
        isActive: false,
        userType: "Vendor",
        totalOrder: 0,
        reviewCount: 0,
        ratingCount: 0,
        totalRating: 0,
      },
    ]);
    if (!error) {
      setOpen(false);
    }
    const wmessage = {
      broadcast_name: watiTemplateName.onboard_vendor.broadcast_name,
      template_name: watiTemplateName.onboard_vendor.template_name,
      receivers: [
        {
          whatsappNumber:
            String(formik.values.country) + String(formik.values.mobile),
          customParams: [
            {
              name: "vendor_name",
              value: formik.values.firmName,
            },
            {
              name: "user_id",
              value: formik.values.email,
            },
            {
              name: "password",
              value: formik.values.mobile,
            },
            {
              name: "website_name",
              value: websiteDetials.website_name,
            },
            {
              name: "contact_email",
              value: websiteDetials.contact_email,
            },
            {
              name: "1",
              value: websiteDetials.login_url,
            },
          ],
        },
      ],
    };
    sendTemplateMessages(wmessage);
    const emailmessage = {
      toemail: formik.values.email,
      template: emailtemplate.invitemail,
      emailVariables: {
        supportemail: websiteDetials.contact_email,
        Password: formik.values.mobile,
        username: formik.values.email,
        name: formik.values.firmName,
      },
    };
    sendemail(emailmessage);
    if (!error) {
      showToast("success", "Vendor");
      navigate(AppRoutes.VENDORLIST);
    } else {
      showToast("error", "vendor");
    }
  };

  return (
    <div style={{ padding: "25px", marginTop: "-10px" }}>
      <Container className="ProductContainer">
        <Grid container maxWidth="lg">
          <Grid
            container
            maxWidth="lg"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item lg={7} xs={12} sm={6} md={6}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 0.5 }}>
                Vendor
              </Typography>
            </Grid>
            <Grid item lg={5} xs={12} sm={6} md={6} textAlign="right">
              <div>
                <Button
                  className="addBtn"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Register New Vendor
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className="links">
          <Link
            to={AppRoutes.DASHBOARD}
            className="crumbs"
            underline="hover"
            style={{
              fontSize: "12px",
              color: " rgb(17, 25, 39)",
              fontWeight: "600",
            }}
          >
            Dashboard
          </Link>
          <div className="separator"></div>
          <Typography
            sx={{
              fontSize: "12px",
              color: " rgb(108, 115, 127)",
              marginTop: "1px",
            }}
          >
            Vendor
          </Typography>
          <div className="separator"></div>
          <Typography
            sx={{
              fontSize: "12px",
              color: " rgb(108, 115, 127)",
              marginTop: "1px",
            }}
          >
            List
          </Typography>
        </div>
        <div className="topContent">
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <TextField
              sx={{ mt: "5px", p: "7px", ml: "-5px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by firm name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <Stack direction="row">
            <CommonFilter
              id="isactive"
              title="Active"
              options={[
                { code: "yes", value: "Yes" },
                { code: "no", value: "No" },
              ]}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
            <CommonFilter
              id="profile"
              title="Profile"
              options={[
                { code: "yes", value: "Completed" },
                { code: "no", value: "Not Completed" },
              ]}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
          </Stack>
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Firm Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Created On
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Mobile Number
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Profile Complete
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorData
                  .filter(
                    (vendor) =>
                      vendor.FirmName.toLowerCase().includes(searchTerm) ||
                      vendor.MobileNo.toLowerCase().includes(searchTerm) ||
                      vendor.Email.toLowerCase().includes(searchTerm)
                  )
                  // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((vendor, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {vendor.FirmName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {dayjs(vendor.created_at).format("MM/DD/YYYY ")}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {vendor.MobileNo}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {vendor.Email}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {vendor.commission ? `${vendor.commission}%` : "NA"}{" "}
                        {/* Render commission value with % symbol if it exists */}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {vendor.isProfileComplete ? "Yes" : "No"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        {vendor.isActive ? "Yes" : "No"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                        <Link
                          to={{
                            pathname: `/Vendor/${vendor.id}`,
                            state: { vendor: vendor },
                          }}
                        >
                          <Tooltip title="Edit" placement="right">
                            <IconButton variant="contained">
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[]}
            sx={{
              "& .MuiPaginationItem-root": {
                fontSize: "12px",
              },
              "& .Mui-selected": {
                backgroundColor: "#1e88e5",
                color: "rgb(108, 115, 127)",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "13px",
                color: "rgb(108, 115, 127)",
              },
            }}
          />
        </Grid>
      </Container>
      <Dialog fullWidth open={open}>
        <DialogTitle>Add New Vendor</DialogTitle>
        <DialogContent>
          <form>
            <Grid lg={8} xs={8} sm={8} md={8}>
              <InputLabel>
                <Typography
                  sx={{
                    mt: "10",
                    color: "rgb(108, 115, 127);",
                    fontWeight: "500",
                  }}
                >
                  Firm/Company
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                sx={{ borderRadius: "20px" }}
                inputProps={{ style: { height: "10px" } }}
                fullWidth
                name="firmName"
                value={formik.values.firmName}
                onChange={(e) => {
                  const { value } = e.target;
                  const capitalizedValue =
                    value.charAt(0).toUpperCase() + value.slice(1);
                  if (capitalizedValue.length <= 50) {
                    formik.handleChange({
                      target: {
                        name: "firmName",
                        value: capitalizedValue,
                      },
                    });
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firmName && Boolean(formik.errors.firmName)
                }
              />
              {formik.touched.firmName && formik.errors.firmName && (
                <span style={{ color: "#d32f2f" }}>
                  {formik.errors.firmName}
                </span>
              )}
              <br />
              <br />
              <InputLabel>
                <Typography
                  sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                >
                  Mobile
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "15%" }}>
                  <PhoneInput
                    country={"in"}
                    inputProps={{
                      required: true,
                      readOnly: true,
                    }}
                    inputStyle={{
                      height: "42px",
                      width: "115px",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                    value={countryCode}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ flexBasis: "79%" }}>
                  <TextField
                    fullWidth
                    name="mobile"
                    style={{ borderRadius: "0px 5px 5px 0px" }}
                    inputProps={{ style: { height: "10px" } }}
                    value={formik.values.mobile}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 10) {
                        formik.handleChange(e);
                      }
                    }}
                    onKeyPress={(e) => {
                      const allowedKeys = /[0-9\b]/;
                      if (!allowedKeys.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobile && Boolean(formik.errors.mobile)
                    }
                  />
                </div>
              </div>
              {formik.touched.mobile && formik.errors.mobile && (
                <span style={{ color: "#d32f2f" }}>{formik.errors.mobile}</span>
              )}
              {formik.touched.mobile &&
                formik.values.mobile &&
                formik.values.mobile.length < 10 && (
                  <span style={{ color: "#d32f2f" }}>
                    Mobile number must be at least 10 digits long
                  </span>
                )}
              <br />
              <InputLabel>
                <Typography
                  sx={{ color: "rgb(108, 115, 127);", fontWeight: "500" }}
                >
                  E-mail
                  <Typography sx={{ color: "red" }} component="span">
                    *
                  </Typography>
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                inputProps={{ style: { height: "10px" } }}
                name="email"
                value={formik.values.email}
                onChange={(e) => {
                  const { value } = e.target;

                  if (value.length <= 50) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <span style={{ color: "#d32f2f" }}>{formik.errors.email}</span>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ marginRight: "15px" }}>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            autoFocus
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="primary" className="loader" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} aria-labelledby="email-exists-dialog-title">
        <DialogTitle id="email-exists-dialog-title">
          Email Already Exists
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The email address you provided is already in use. Please use a
            different email address.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="addBtn"
            onClick={() => setOpenDialog(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VendorList;
