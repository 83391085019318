import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  TableContainer,
  IconButton,
  TablePagination,
  Switch,
  Stack,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../constant";
import { Link } from "react-router-dom";
import useSupabase from "../utils/useSupabase";
import { useContext } from "react";
import { Cartcontext } from "../Products/Context";
import CommonDelete from "../CommonDelete";
import { TableName } from "../constant";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

const List = () => {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [open, setOpen] = useState(false);
  const [listName, setListName] = useState("");
  const [listItem, setListItem] = useState("");
  const [listDescription, setListDescription] = useState("");
  const [IsActive, setIsActive] = useState("");
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedListName, setSelectedListName] = useState("");
  const [selectedListItems, setSelectedListItems] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const GlobalState = useContext(Cartcontext);
  const [disableListName, setDisableListName] = useState(false);
  const showToast = GlobalState.showToast;
  const uniqueListNames = Array.from(
    new Set(list.map((item) => item.listName))
  );
  useEffect(
    () => {
      fetchListData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const fetchListData = async () => {
    const { data, error } = await supabase.from(TableName.LIST).select();
    if (error) {
      console.error("Error fetching list data:", error.message);
    } else {
      setList(data);
    }
  };
  const [page, setPage] = useState(0);
  const rowsPerPage = 4;
  const handleOpen = (isNewList) => {
    setListName("");
    setListItem("");
    setListDescription("");
    setIsActive("");
    setAdditionalInfo("");
    setError({});
    setOpen(true);
    setError({});
    setSelectedItem(null);
    if (!isNewList && selectedListName) {
      setListName(selectedListName);
      setDisableListName(true);
    } else {
      setListName("");
      setDisableListName(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError({});
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setListName(item.listName);
    setListItem(item.listItem);
    setListDescription(item.listDescription);
    setAdditionalInfo(item.additionalInfo);
    setIsActive(item.IsActive === "Yes");
    setOpen(true);
    setError({});
  };
  const handleSave = async () => {
    setisLoading(true);
    const errors = {};

    if (!listName.trim()) {
      setisLoading(false);
      errors.listName = "List Name is required";
    }

    if (!listItem.trim()) {
      setisLoading(false);
      errors.listItem = "List Item is required";
    }

    if (!listDescription.trim()) {
      setisLoading(false);
      errors.listDescription = "List Description is required";
    }

    setError(errors);
    if (Object.keys(errors).length === 0) {
      const newItem = {
        listName,
        listItem,
        listDescription,
        additionalInfo,
        IsActive: IsActive ? "Yes" : "No",
      };
      if (selectedItem) {
        const { data, error } = await supabase
          .from(TableName.LIST)
          .update(newItem)
          .eq("id", selectedItem.id);

        if (error) {
          console.error("Error updating data:", error.message);
        } else {
          console.log("Data updated successfully:", data);
          setisLoading(false);
          showToast("update", "List");
          fetchListData();
        }
      } else {
        const { data, error } = await supabase
          .from(TableName.LIST)
          .insert([newItem]);

        if (error) {
          console.error("Error saving data:", error.message);
        } else {
          console.log("Data saved successfully:", data);
          setisLoading(false);
          showToast("success", "List");
          fetchListData();
        }
      }

      handleClose();
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    setSelectedListItems(
      list.filter(
        (item) => !selectedListName || item.listName === selectedListName
      )
    );
  }, [list, selectedListName]);
  const handleListNameChange = (e) => {
    setListName(e.target.value.replace(/\s/g, ""));
    setError({ ...error, listName: "" });
  };

  const handleListItemChange = (e) => {
    setListItem(e.target.value.replace(/\s/g, ""));
    setError({ ...error, listItem: "" });
  };

  const handleListDescriptionChange = (e) => {
    setListDescription(e.target.value);
    setError({ ...error, listDescription: "" });
  };
  const handleListNameBlur = () => {
    if (!listName.trim()) {
      setError({ ...error, listName: "List Name is required" });
    }
  };

  const handleListItemBlur = () => {
    if (!listItem.trim()) {
      setError({ ...error, listItem: "List Item is required" });
    }
  };

  const handleListDescriptionBlur = () => {
    if (!listDescription.trim()) {
      setError({ ...error, listDescription: "List Description is required" });
    }
  };
  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setIsDeleteOpen(true);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Grid container maxWidth="lg">
          <Grid
            container
            maxWidth="lg"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item lg={7} xs={12} sm={6} md={6}>
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 0.5 }}>
                List
              </Typography>
            </Grid>
            <Grid item lg={5} xs={12} sm={6} md={6} textAlign="right">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Stack sx={{ ml: "65px" }} direction="row" spacing={5}>
                  {" "}
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpen(true)}
                  >
                    Add a new list
                  </Button>
                  <Button
                    className="addBtn"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpen(false)}
                    disabled={!selectedListName}
                  >
                    Add a new item
                  </Button>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className="links">
          <Link
            to={AppRoutes.DASHBOARD}
            className="crumbs"
            underline="hover"
            style={{
              fontSize: "12px",
              color: " rgb(17, 25, 39)",
              fontWeight: "600",
            }}
            onClick={() => {
              navigate(AppRoutes.DASHBOARD);
            }}
          >
            Dashboard
          </Link>
          <div className="separator"></div>
          <Typography className="crumbs">List</Typography>
        </div>

        <div className="topContent">
          <TextField
            size="small"
            select
            fullWidth
            label="Select List Name"
            labelId="listName-dropdown-label"
            id="listName-dropdown"
            value={selectedListName}
            onChange={(e) => setSelectedListName(e.target.value)}
          >
            {uniqueListNames.map((name, index) => (
              <MenuItem key={index} value={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {selectedListName && (
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <br />
            <TableContainer
              sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
            >
              <Table sx={{ minWidth: 650, borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      List Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      List Item
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      List Description
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list
                    .filter(
                      (item) =>
                        !selectedListName || item.listName === selectedListName
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" className="cell">
                          {item.listName}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {item.listItem}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {item.listDescription}
                        </TableCell>
                        <TableCell align="center" className="cell">
                          {String(item.IsActive)}
                        </TableCell>

                        <TableCell
                          sx={{ textAlign: "center", lineHeight: "1" }}
                        >
                          <Tooltip title="Edit" placement="left">
                            <IconButton
                              variant="contained"
                              onClick={() => handleEdit(item)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" placement="right">
                            <IconButton
                              variant="contained"
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {selectedListName && (
          <TablePagination
            component="div"
            count={selectedListItems.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            sx={{
              "& .MuiPaginationItem-root": {
                fontSize: "12px",
              },
              "& .Mui-selected": {
                backgroundColor: "#1e88e5",
                color: "rgb(108, 115, 127)",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "13px",
                color: "rgb(108, 115, 127)",
              },
            }}
          />
        )}
      </Container>

      <Dialog open={open}>
        <DialogTitle>{selectedItem ? "Edit List" : "Add List"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="List Name*"
                fullWidth
                margin="normal"
                value={listName}
                onChange={handleListNameChange}
                onBlur={handleListNameBlur}
                error={Boolean(error.listName)}
                helperText={error.listName}
                disabled={disableListName || Boolean(selectedItem)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="List Item*"
                fullWidth
                margin="normal"
                value={listItem}
                onChange={handleListItemChange}
                onBlur={handleListItemBlur}
                error={Boolean(error.listItem)}
                helperText={error.listItem}
                disabled={Boolean(selectedItem)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="List Description*"
                fullWidth
                margin="normal"
                value={listDescription}
                onChange={handleListDescriptionChange}
                onBlur={handleListDescriptionBlur}
                error={Boolean(error.listDescription)}
                helperText={error.listDescription}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Additional Information"
                fullWidth
                margin="normal"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} mt="18px">
              <FormControl fullWidth>
                <Typography>Active</Typography>
                <Switch
                  checked={IsActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  color="primary"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress
                color="primary"
                className=""
                style={{ width: "35px", height: "25px" }}
              />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <CommonDelete
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
        full={list}
        table={TableName.LIST}
        deleteId={deleteId}
        getFunction={fetchListData}
        page={page}
        rowsPerPage={rowsPerPage}
        columnName="id"
      />
    </div>
  );
};

export default List;
